import React from 'react';
import { Provider } from 'react-redux';
import { notification } from 'antd';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';

import client from '../services/graphql.service';
import NavigationProvider from '../navigation/navigation';
import { store, persistor } from '../redux/store';

notification.config({
  maxCount: 1,
});

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationProvider />
      </PersistGate>
    </ApolloProvider>
  </Provider>
);

export default App;
