import PropTypes from 'prop-types';

const displayName = 'ModalConfirmation';
const propTypes = {
  visible: PropTypes.bool,
  config: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    btnConfirmText: PropTypes.string,
    extra: PropTypes.node,
    btnStyle: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
const defaultProps = {
  visible: false,
  config: {
    icon: '',
    title: '',
    description: '',
    btnConfirmText: '',
    extra: null,
    btnStyle: null,
  },
};

export default { displayName, propTypes, defaultProps };
