import { gql } from '@apollo/client';

export const getStatusAggregation = gql`
  query getStatusAggregation {
    aggregationStatusCompleted {
      shopee
      shopify
      tokopedia
    }
  }
`;

export const getTotalOrder = gql`
  query getTotalOrder(
    $interval: Interval!
    $channel: [MarketplaceChannel]!
    $category: [OrderStatus]!
  ) {
    marketplaceOrders(
      paramIn: { interval: $interval, channel: $channel, category: $category }
    ) {
      status
      totalNumber
      totalAmount
    }
  }
`;

export const getChartOrder = gql`
  query getChartOrder(
    $interval: Interval!
    $channel: [MarketplaceChannel]!
    $category: [OrderStatus]!
  ) {
    marketplaceOrders(
      paramIn: { interval: $interval, channel: $channel, category: $category }
    ) {
      status
      totalNumber
      totalAmount
      indexTime
    }
  }
`;

export const getListProduct = gql`
  query getListProduct(
    $interval: Interval!
    $channel: [MarketplaceChannel]!
    $sortBy: ProductSortBy!
  ) {
    marketplaceProductOrders(
      paramIn: {
        interval: $interval
        channel: $channel
        sortBy: $sortBy
        size: 10
      }
    ) {
      amount
      quantity
      productName
    }
  }
`;
