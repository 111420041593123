/* eslint-disable camelcase */
import { findIndex } from 'lodash';
import get from 'lodash/get';

import {
  FB_ADS_INSIGHT,
  FB_INSTAGRAM,
  GOOG_ADWORDS,
  GOOG_ANALYTICS,
  SHOPIFY,
  TOKOPEDIA,
} from '../../constants/variables';
import { notification } from '../../components';
import api from '../../services/api.service';

import {
  SET_BINDING_FB_ADS_INSIGHT,
  SET_BINDING_GOOG_ADWORDS,
  SET_BINDING_GOOG_ANALYTICS,
  SET_BINDING_FB_INSTAGRAM,
  SET_BINDING_MODAL_VISIBLE,
  SET_BINDING_SHOPEE,
  SET_BINDING_SHOPIFY,
  SET_BINDING_TOKOPEDIA,
  SET_ACCOUNTS_ALL,
  SET_ACCOUNTS,
} from '../types';
import { capitalizeFirstString } from '../../utils/string';
const { apiService } = api.getInstance();

const types = {
  FB_ADS_INSIGHT: SET_BINDING_FB_ADS_INSIGHT,
  GOOG_ADWORDS: SET_BINDING_GOOG_ADWORDS,
  GOOG_ANALYTICS: SET_BINDING_GOOG_ANALYTICS,
  FB_INSTAGRAM: SET_BINDING_FB_INSTAGRAM,
  TOKOPEDIA: SET_BINDING_TOKOPEDIA,
  SHOPEE: SET_BINDING_SHOPEE,
  SHOPIFY: SET_BINDING_SHOPIFY,
};

const FB_SCOPES = {
  ADS_INSIGHT:
    'email,read_insights,pages_show_list,ads_management,instagram_basic,instagram_manage_insights,public_profile',
  INSTAGRAM:
    'email,read_insights,pages_show_list,instagram_basic,instagram_manage_insights,public_profile',
};

const GOOGLE_SCOPES = {
  ANALYTICS: 'https://www.googleapis.com/auth/analytics.readonly',
  ADWORDS: 'https://www.googleapis.com/auth/adwords',
};

const defaultAccount = {
  loading: false,
  data: [],
  haveAccount: false,
};

export const getBindedAccounts = (accountType) => async (dispatch) => {
  const accountTypes = Object.keys(types);
  const toSetDefault = {};
  let result;
  if (accountType) {
    dispatch({
      type: SET_ACCOUNTS,
      accountType,
      payloadAsset: '',
      payload: {
        loading: true,
        haveAccount: false,
      },
    });
  } else {
    dispatch({ type: SET_ACCOUNTS_ALL });
  }

  try {
    const { data } = await apiService.get('assets');
    if (accountType) {
      const account =
        data[findIndex(data, (item) => item.credential_type === accountType)];
      const { data: accountList } = await apiService.get(
        `accounts/${account.id}`,
      );

      dispatch({
        type: SET_ACCOUNTS,
        accountType: account.credential_type,
        payloadAsset: account,
        payload: {
          loading: false,
          data: accountList,
          haveAccount: true,
        },
      });
    } else {
      data.forEach(async (account) => {
        try {
          const { data: accountList } = await apiService.get(
            `accounts/${account.id}`,
          );

          dispatch({
            type: SET_ACCOUNTS,
            accountType: account.credential_type,
            payloadAsset: account,
            payload: {
              loading: false,
              data: accountList,
              haveAccount: true,
            },
          });
        } catch (error) {
          dispatch({
            type: SET_ACCOUNTS,
            accountType: account.credential_type,
            payloadAsset: '',
            payload: {
              loading: false,
              haveAccount: false,
            },
          });
        }
      });

      accountTypes.forEach(async (account) => {
        if (findIndex(data, (item) => item.credential_type === account)) {
          toSetDefault[account] = defaultAccount;
        }
      });

      dispatch({ type: SET_ACCOUNTS_ALL, payload: toSetDefault });
    }
    result = Promise.resolve();
  } catch (error) {
    if (accountType) {
      dispatch({
        type: SET_ACCOUNTS,
        accountType,
        payloadAsset: '',
        payload: {
          loading: false,
          haveAccount: false,
        },
      });
    } else {
      accountTypes.forEach((account) => {
        toSetDefault[account] = defaultAccount;
      });

      dispatch({ type: SET_ACCOUNTS_ALL, payload: toSetDefault });
    }
    result = Promise.reject();
  }
  return result;
};

const bindFacebook = async ({ accountType, scope }, dispatch) => {
  const type = types[accountType];
  dispatch({ type, payload: { btnLoading: true } });
  try {
    const loginFacebook = await new Promise((resolve) => {
      window.FB.login((response) => resolve(response), {
        scope,
      });
    });
    const token = get(loginFacebook, 'authResponse.accessToken');
    const {
      data: { asset_id, accounts },
    } = await apiService.post('binding/init', {
      credential_type: accountType,
      metadata: { token },
    });
    dispatch({
      type,
      payload: {
        btnLoading: false,
        data: {
          asset_id,
          accounts,
        },
      },
    });
    dispatch({
      type: SET_BINDING_MODAL_VISIBLE,
      isVisible: true,
      accountType,
    });
  } catch (error) {
    dispatch({
      type,
      payload: { btnLoading: false },
    });
    dispatch({
      type: SET_BINDING_MODAL_VISIBLE,
      isVisible: false,
    });
  }
};

export const bindFbAdsAccount = () => (dispatch) => {
  bindFacebook(
    {
      accountType: FB_ADS_INSIGHT,
      scope: FB_SCOPES.ADS_INSIGHT,
    },
    dispatch,
  );
};

export const bindFbInstagram = () => (dispatch) => {
  bindFacebook(
    {
      accountType: FB_INSTAGRAM,
      scope: FB_SCOPES.INSTAGRAM,
    },
    dispatch,
  );
};

export const saveAccounts = (accountType, assetId, selectedAccounts) => async (
  dispatch,
) => {
  let result;
  const type = types[accountType];

  dispatch({ type, payload: { btnLoading: true } });
  let account = 'Facebook Ads';
  if (accountType === GOOG_ADWORDS) {
    account = 'Google Ads';
  } else if (accountType === GOOG_ANALYTICS) {
    account = 'Google Analytics';
  } else if (accountType === FB_INSTAGRAM) {
    account = 'Instagram';
  }

  try {
    const { status } = await apiService.post(
      `binding/save?asset_id=${assetId}`,
      selectedAccounts,
    );

    if (status === 201) {
      dispatch({
        type,
        payload: {
          btnLoading: false,
        },
      });
      dispatch({
        type: SET_BINDING_MODAL_VISIBLE,
        isVisible: false,
      });
      result = Promise.resolve();
      notification.success({
        message: 'Account Binding Berhasil',
        description: `Proses account binding ${account} kamu sudah berhasil.`,
      });
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    notification.error({
      message: 'Account Binding Gagal',
      description: `Proses account binding ${account} kamu gagal.`,
    });
    result = Promise.reject();
    dispatch({ type, payload: { btnLoading: false } });
  }

  return result;
};

const bindGoogle = async ({ type, scopes }, dispatch) => {
  dispatch({ type, payload: { btnLoading: true } });
  try {
    const {
      data: { url },
    } = await apiService.get('google/bindings', {
      params: { scopes },
    });
    dispatch({
      type,
      payload: {
        btnLoading: false,
      },
    });
    if (url) {
      window.location.href = url;
    }
  } catch (error) {
    dispatch({
      type,
      payload: { btnLoading: false },
    });
  }
};

export const bindGoogleAds = () => (dispatch) => {
  bindGoogle(
    { type: SET_BINDING_GOOG_ADWORDS, scopes: GOOGLE_SCOPES.ADWORDS },
    dispatch,
  );
};

export const bindGoogleAnalytics = () => (dispatch) => {
  bindGoogle(
    { type: SET_BINDING_GOOG_ANALYTICS, scopes: GOOGLE_SCOPES.ANALYTICS },
    dispatch,
  );
};

export const getGoogleAccounts = (accountType, payload) => async (dispatch) => {
  const type = types[accountType];
  dispatch({ type, payload: { btnLoading: true } });
  try {
    const {
      data: { asset_id, accounts },
    } = await apiService.post('binding/init', {
      credential_type: accountType,
      metadata: payload,
    });
    dispatch({
      type,
      payload: {
        btnLoading: false,
      },
    });
    dispatch({
      type,
      payload: {
        btnLoading: false,
        data: {
          asset_id,
          accounts,
        },
      },
    });
    dispatch({
      type: SET_BINDING_MODAL_VISIBLE,
      isVisible: true,
      accountType,
    });
  } catch (error) {
    dispatch({
      type,
      payload: { btnLoading: false },
    });
    dispatch({
      type: SET_BINDING_MODAL_VISIBLE,
      isVisible: false,
    });
  }
};

export const unbindAccount = (accountType, asset_id) => async (dispatch) => {
  const type = types[accountType];

  dispatch({ type, payload: { btnLoading: true } });
  try {
    const { status } = await apiService.delete(`assets/${asset_id}`);
    if (status === 204) {
      dispatch({
        type,
        payload: {
          btnLoading: false,
        },
      });
      dispatch({
        type: SET_ACCOUNTS,
        accountType,
        payloadAsset: undefined,
        payload: {
          loading: false,
          data: [],
          haveAccount: false,
        },
      });
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    dispatch({
      type,
      payload: { btnLoading: false },
    });
  }
};

export const bindShopee = () => async (dispatch) => {
  dispatch({ type: SET_BINDING_SHOPEE, payload: { btnLoading: true } });
  try {
    const {
      data: { url },
    } = await apiService.get('shopee/binding');
    dispatch({
      type: SET_BINDING_SHOPEE,
      payload: {
        btnLoading: false,
      },
    });
    if (url) {
      window.location.href = url;
    }
  } catch (error) {
    dispatch({
      type: SET_BINDING_SHOPEE,
      payload: { btnLoading: false },
    });
  }
};

const bindMarketplace = (accountType, dispatch) => {
  dispatch({
    type: SET_BINDING_MODAL_VISIBLE,
    isVisible: true,
    accountType,
  });
};

export const bindTokopedia = () => (dispatch) => {
  bindMarketplace(TOKOPEDIA, dispatch);
};

export const saveMarketplaceAccount = (accountType, payload) => async (
  dispatch,
) => {
  const type = types[accountType];
  const account = capitalizeFirstString(accountType.toLowerCase());

  let result;
  dispatch({ type, payload: { btnLoading: true } });
  try {
    const {
      data: { asset_id, accounts },
    } = await apiService.post('binding/init', {
      credential_type: accountType,
      metadata: payload,
    });

    result = saveAccounts(accountType, asset_id, accounts)(dispatch);
    notification.success({
      message: 'Account Binding Berhasil',
      description: `Proses account binding ${account} kamu sudah berhasil.`,
    });
  } catch (error) {
    dispatch({
      type,
      payload: { btnLoading: false },
    });
    notification.error({
      message: 'Account Binding Gagal',
      description: `Proses account binding ${account} kamu gagal.`,
    });
    result = Promise.reject();
  }
  return result;
};

export const bindShopify = () => (dispatch) => {
  bindMarketplace(SHOPIFY, dispatch);
};
