import React from 'react';
import { Divider, Typography, Button, Form, Input } from 'antd';
import { GoogleLogin } from 'react-google-login';

import { LandingLayout } from '../../components/Layout';
import { ICON_FACEBOOK, ICON_GOOGLE } from '../../assets/icons';

import config from './Login.component.config';
import './Login.component.styles.less';

const { Title, Text } = Typography;
class LoginPage extends React.PureComponent {
  loginForm = React.createRef();

  componentDidMount() {
    // const availableToken = getToken();
    // if (get(availableToken, 'token')) {
    //   this.props.history.replace('/');
    // }
  }

  _onLoginGoogleFailed = () => {
    this.props.notificationError('Google');
  };

  renderGoogleBtn = (renderProps) => (
    <Button
      onClick={() => {
        renderProps.onClick();
        this.props.loginGoogle();
      }}
      loading={this.props.loadingGoogle}
      disabled={renderProps.disabled}
    >
      <img src={ICON_GOOGLE} alt="btn-google" />
      Google
    </Button>
  );

  _onLoginSocmed = (type) => (isRegCompleted) => {
    if (!isRegCompleted) {
      window.gtag('event', 'begin_sign_up', {
        method: type,
      });
      this.props.history.push('register', { isFromLogin: true });
    } else {
      window.gtag('event', 'login', {
        method: type,
      });
      this.props.history.replace('/');
    }
  };

  _onLoginFacebook = () => {
    this.props.loginFacebook().then(this._onLoginSocmed('Facebook'));
  };

  _onLoginGoogle = (resultGoogle) => {
    this.props
      .loginGoogleSuccess(resultGoogle)
      .then(this._onLoginSocmed('Google'));
  };

  renderSocmedButton = () => (
    <div className="row-social-btn">
      <Button
        loading={this.props.loadingFacebook}
        onClick={this._onLoginFacebook}
      >
        <img src={ICON_FACEBOOK} alt="btn-facebook" />
        Facebook
      </Button>

      <GoogleLogin
        clientId={process.env.GOOGLE_CLIENT_ID}
        render={this.renderGoogleBtn}
        onSuccess={this._onLoginGoogle}
        onFailure={this._onLoginGoogleFailed}
        cookiePolicy="single_host_origin"
      />
    </div>
  );

  _onSubmitLogin = (value) => {
    this.props
      .loginEmail(value)
      .then(() => {
        window.gtag('event', 'login', {
          method: 'Email',
        });
        this.props.history.replace('/');
      })
      .catch((err) => {
        if (err === 'INVALID_EMAIL_OR_PASSWORD')
          this.loginForm.current.setFields([
            {
              name: 'email',
              errors: ['Provided email or password is invalid'],
            },
            {
              name: 'password',
              errors: ['Provided email or password is invalid'],
            },
          ]);
      });
  };

  renderLoginForm = () => (
    <Form
      className="form"
      layout="vertical"
      name="login"
      ref={this.loginForm}
      onFinish={this._onSubmitLogin}
      initialValues={{
        email: undefined,
        password: undefined,
      }}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Email is required',
          },
          {
            type: 'email',
            message: 'Email is not valid',
          },
        ]}
      >
        <Input
          placeholder="you@example.com"
          autoComplete="off"
          className="input-text"
        />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Password is required',
          },
        ]}
        extra={
          <Button
            type="link"
            className="btn-forgot"
            onClick={() => {
              this.props.history.push('forgot-password');
            }}
          >
            Forgot Password?
          </Button>
        }
      >
        <Input.Password autoComplete="off" placeholder="Enter your password" />
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
          className="submit-btn"
          loading={this.props.loadingEmail}
        >
          Log In
        </Button>
      </Form.Item>
    </Form>
  );

  renderRegisterRow = () => (
    <div className="row-footer">
      <Text>
        Don’t have an account?{' '}
        <Button
          type="link"
          onClick={() => {
            this.props.history.replace('register');
          }}
        >
          Register Now
        </Button>
      </Text>
    </div>
  );

  render() {
    return (
      <LandingLayout id="login-page">
        <Title level={3}>Log in to your account</Title>
        {this.renderSocmedButton()}
        <Divider>or log in with</Divider>
        {this.renderLoginForm()}
        {this.renderRegisterRow()}
      </LandingLayout>
    );
  }
}

LoginPage.displayName = config.displayName;
LoginPage.defaultProps = config.defaultProps;
LoginPage.propTypes = config.propTypes;

export default LoginPage;
