import { gql } from '@apollo/client';

export const tableCampaign = gql`
  query tableCampaign($interval: Interval!, $accountId: String!) {
    googleAdsCampaignPerformance(
      paramIn: {
        interval: $interval
        accountId: $accountId
        size: 10
        sortBy: COST
      }
    ) {
      CampaignName
      Cost
      Roas
    }
  }
`;

export const reach = gql`
  query reach($interval: Interval!, $accountId: String!) {
    googleAdsCampaignPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      ImpressionReach
    }
  }
`;

export const impression = gql`
  query impression($interval: Interval!, $accountId: String!) {
    googleAdsAccountPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      Impressions
    }
  }
`;

export const linkCpm = gql`
  query linkCpm($interval: Interval!, $accountId: String!) {
    googleAdsAccountPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      Clicks
      AverageCpm
    }
  }
`;

export const ctrCpc = gql`
  query ctrCpc($interval: Interval!, $accountId: String!) {
    googleAdsAccountPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      AverageCpc
      Ctr
    }
  }
`;

export const conversionRate = gql`
  query conversionRate($interval: Interval!, $accountId: String!) {
    googleAdsAccountPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      AllConversionRate
    }
  }
`;

export const views = gql`
  query views($interval: Interval!, $accountId: String!) {
    googleAdsCampaignPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      VideoViews
      VideoViewRate
    }
  }
`;

export const interactionCpv = gql`
  query interactionCpv($interval: Interval!, $accountId: String!) {
    googleAdsAccountPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      Interactions
      AverageCpv
    }
  }
`;

export const impressionTop = gql`
  query impressionTop($interval: Interval!, $accountId: String!) {
    googleAdsAccountPerformance(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      TopImpressionPercentage
      AbsoluteTopImpressionPercentage
    }
  }
`;
