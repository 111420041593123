/* eslint-disable no-restricted-globals */
import { format } from 'date-fns';

export const capitalizeFirstString = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const formatAvatar = (words) => {
  if (!words) return '';
  return words.split(' ').length > 1
    ? words.split(' ')[0][0].replace(/[\W_]+/g, '') +
        words.split(' ')[1][0].replace(/[\W_]+/g, '')
    : words.split(' ')[0][0].replace(/[\W_]+/g, '');
};

export const formatNumber = (number) => {
  if (isNaN(number)) return '-';
  return number
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatPercentage = (number, withSpace) => {
  if (isNaN(number)) return '-';
  return `${formatNumber(number)}${withSpace ? ' %' : '%'}`;
};

export const formatCurrency = (number, currency, isDecimal, fixed = 2) => {
  if (isNaN(number)) return '-';

  return `${currency || 'Rp'}${formatNumber(
    isDecimal ? parseFloat(number).toFixed(fixed) : number,
  )}`;
};

export const validateEmail = (value) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const formatDate = (date) => format(date, 'yyyy-MM-dd');

export const concatKey = (comp, i) => comp + i;

export const labelFormater = (
  data,
  date,
  isCurrent = true,
  customLabel = null,
) => {
  let label = isCurrent ? 'current' : 'compare';
  if (customLabel) {
    label = customLabel;
  }

  return `${label} (${format(new Date(date.startDate), 'd MMM')}/${format(
    new Date(date.endDate),
    'd MMM yyyy',
  )})`;
};

export const nFormatter = (num, digits) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};
