export const LANDING_IMG = `${process.env.S3_URL}/images/landing.svg`;

export { default as LOGO_IMG } from './logo.svg';
export { default as LOGO_IMG_WHITE } from './logo-white.svg';
export { default as INDUSTRY_APPAREL_AND_FOOTWEAR } from './industry-apparel.svg';
export { default as INDUSTRY_BEAUTY_AND_PERSONAL_CARE } from './industry-personal_care.svg';
export { default as INDUSTRY_CONSUMER_ELECTRONICS } from './industry-electronic.svg';
export { default as INDUSTRY_FOOD_AND_BEVERAGES } from './industry-fnb.svg';
export { default as INDUSTRY_FINANCIAL_SERVICES } from './industry-finance.svg';
export { default as INDUSTRY_HOME } from './industry-home.svg';
export { default as INDUSTRY_JEWELLERY_AND_ACCESSORIES } from './industry-jewellery.svg';
export { default as INDUSTRY_MEDICAL_AND_HEALTHCARE } from './industry-medical.svg';
export { default as INDUSTRY_OTHERS_INDUSTRY } from './industry-other.svg';
