import PropTypes from 'prop-types';

const displayName = 'Dashboard';
const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  withPeriod: PropTypes.bool,
  onSearch: PropTypes.func,
  onPeriodChange: PropTypes.func,
  isSynced: PropTypes.bool,
  isSyncing: PropTypes.bool,
};
const defaultProps = {
  withPeriod: true,
  isSynced: false,
  isSyncing: false,
};

export default { displayName, propTypes, defaultProps };
