import React from 'react';
import get from 'lodash/get';
import { Divider, Typography, Button, Form, Input, Row, Col } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { GoogleLogin } from 'react-google-login';

import { LandingLayout } from '../../components/Layout';
import { ModalConfirmation } from '../../components';
import { ICON_FACEBOOK, ICON_GOOGLE, ICON_SUCCESS } from '../../assets/icons';
import { LANDING_IMG } from '../../assets/images';
import { INDUSTRY_LISTS } from '../../constants/variables';

import config from './Register.component.config';
import './Register.component.styles.less';

const { Title, Text } = Typography;

const registerSteps = {
  INITIAL: 'INITIAL',
  INDUSTRY: 'INDUSTRY',
  FINALIZE: 'FINALIZE',
};
class RegisterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmVisible: false,
      registerStep: get(this.props, 'location.state.isFromLogin', false)
        ? registerSteps.INDUSTRY
        : registerSteps.INITIAL,
      isRegisterManual: false,
      initialForms: {
        email: undefined,
        name: undefined,
      },
      finalizeForms: {
        company_name: undefined,
        password: undefined,
      },
      industryCategory: undefined,
    };
  }

  finalizeForm = React.createRef();

  _onNextStep = () => {
    this.setState({
      registerStep: registerSteps.INDUSTRY,
      finalizeForms: {
        company_name: undefined,
        password: undefined,
      },
    });
  };

  _onLoginGoogleFailed = () => {
    this.props.notificationError('Google');
  };

  renderGoogleBtn = (renderProps) => (
    <Button
      onClick={() => {
        renderProps.onClick();
        this.props.loginGoogle();
      }}
      loading={this.props.loadingGoogle}
      disabled={renderProps.disabled}
    >
      <img src={ICON_GOOGLE} alt="btn-google" />
      Google
    </Button>
  );

  _onLoginSocmed = (type) => (isRegCompleted) => {
    if (!isRegCompleted) {
      this._onNextStep();
    } else {
      window.gtag('event', 'login', {
        method: type,
      });
      this.props.history.replace('/');
    }
  };

  _onRegisterFacebook = () => {
    window.gtag('event', 'begin_sign_up', {
      method: 'Facebook',
    });
    this.props.loginFacebook().then(this._onLoginSocmed('Facebook'));
  };

  _onRegisterGoogle = (resultGoogle) => {
    window.gtag('event', 'begin_sign_up', {
      method: 'Google',
    });
    this.props
      .loginGoogleSuccess(resultGoogle)
      .then(this._onLoginSocmed('Google'));
  };

  renderSocmedButton = () => (
    <div className="row-social-btn">
      <Button
        loading={this.props.loadingFacebook}
        onClick={this._onRegisterFacebook}
      >
        <img src={ICON_FACEBOOK} alt="btn-facebook" />
        Facebook
      </Button>

      <GoogleLogin
        clientId={process.env.GOOGLE_CLIENT_ID}
        render={this.renderGoogleBtn}
        onSuccess={this._onRegisterGoogle}
        onFailure={this._onLoginGoogleFailed}
        cookiePolicy="single_host_origin"
      />
    </div>
  );

  _onSubmitRegisterInitial = (value) => {
    window.gtag('event', 'begin_sign_up', {
      method: 'Email',
    });
    this.setState((prevState) => ({
      isRegisterManual: true,
      registerStep: registerSteps.INDUSTRY,
      initialForms: { ...prevState.initialForms, ...value },
    }));
  };

  renderRegisterInitialForm = () => (
    <Form
      className="form"
      layout="vertical"
      name="registerInitial"
      initialValues={this.state.initialForms}
      onFinish={this._onSubmitRegisterInitial}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'email is required',
          },
          {
            type: 'email',
            message: 'email is not valid',
          },
        ]}
      >
        <Input
          className="input-text"
          placeholder="you@example.com"
          autoComplete="off"
        />
      </Form.Item>

      <Form.Item
        label="Full Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'full name is required',
          },
        ]}
      >
        <Input
          className="input-text"
          placeholder="Full Name"
          autoComplete="off"
        />
      </Form.Item>

      <Form.Item>
        <Button block htmlType="submit" className="submit-btn">
          Continue
        </Button>
      </Form.Item>
    </Form>
  );

  renderLoginRow = () => (
    <div className="row-footer">
      <Text>
        Already have an account?{' '}
        <Button
          type="link"
          onClick={() => {
            this.props.history.replace('login');
          }}
        >
          Login
        </Button>
      </Text>
    </div>
  );

  renderRegisterInitial = () => (
    <Row>
      <Col xs={24} md={12} className="landing-image">
        <img src={LANDING_IMG} alt="landing-img" />
      </Col>
      <Col xs={24} md={12} className="landing-content" id="register-page">
        <Title level={3}>Create your account</Title>
        {this.renderSocmedButton()}
        <Divider>or register with</Divider>
        {this.renderRegisterInitialForm()}
        {this.renderLoginRow()}
      </Col>
    </Row>
  );

  _onSuccessRegister = () => {
    this.setState({ confirmVisible: true });
  };

  _onSubmitRegisterFinalize = (value) => {
    window.gtag('event', 'step_2_sign_up', {
      company_name: value.company_name,
    });
    if (this.state.isRegisterManual) {
      this.props
        .registerEmail({
          ...this.state.initialForms,
          ...value,
          company_category: this.state.industryCategory,
        })
        .then(this._onSuccessRegister);
    } else {
      this.props
        .completeRegister(
          { ...value, company_category: this.state.industryCategory },
          this.props.isPasswordCreated,
        )
        .then(this._onSuccessRegister);
    }
  };

  renderRegisterFinalizeForm = () => (
    <Form
      layout="vertical"
      className="form finalize"
      name="registerFinalize"
      ref={this.finalizeForm}
      onFinish={this._onSubmitRegisterFinalize}
      initialValues={this.state.finalizeForms}
    >
      <Row gutter={24}>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            label="Company Name"
            name="company_name"
            rules={[
              {
                required: true,
                message: 'Company Name is required',
              },
            ]}
          >
            <Input
              className="input-text"
              placeholder="Company Name"
              autoComplete="off"
            />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            label="Create Your Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Create Your Password"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} className="row-submit">
        <Form.Item>
          <Button
            block
            htmlType="submit"
            className="submit-btn"
            loading={this.props.loadingRegister}
          >
            Register
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );

  _backToIndustry = () => {
    this.setState((prevState) => ({
      registerStep: registerSteps.INDUSTRY,
      finalizeForms: {
        ...prevState.finalizeForms,
        ...this.finalizeForm.current.getFieldsValue(),
      },
    }));
  };

  renderRegisterFinalize = () => (
    <>
      <Title level={3}>
        <i className="icon-back" onClick={this._backToIndustry} />
        Enter your company details
      </Title>
      {window.IS_IN_MOBILE && this.renderMobileSteper()}
      {this.renderRegisterFinalizeForm()}
    </>
  );

  _backToInitial = () => {
    if (get(this.props, 'location.state.isFromLogin', false)) {
      this.props.history.goBack();
    } else {
      this.setState({
        registerStep: registerSteps.INITIAL,
      });
    }
  };

  renderSelectIndustry = () => (
    <>
      <Title level={3}>
        <i className="icon-back" onClick={this._backToInitial} />
        Choose the industry business
      </Title>
      {window.IS_IN_MOBILE && this.renderMobileSteper()}
      <div className={window.IS_IN_MOBILE ? 'mobile-select-industry' : ''}>
        <Row gutter={[24, 24]} className="select-industry">
          {INDUSTRY_LISTS.map((industry) => (
            <Col md={8} xs={12} key={industry.key}>
              <div
                className={`card-industry ${
                  this.state.industryCategory === industry.key
                    ? 'selected'
                    : null
                }`}
                onClick={() => {
                  window.gtag('event', 'step_1_sign_up', {
                    business_type: industry.key,
                  });
                  this.setState({
                    industryCategory: industry.key,
                    registerStep: registerSteps.FINALIZE,
                  });
                }}
              >
                <img
                  src={industry.image}
                  alt={`image_${industry.key.toLowerCase()}`}
                />
                <Text>{industry.label}</Text>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  renderSteper = () => {
    const { registerStep } = this.state;
    return (
      <>
        <div className="item-steper">
          <div
            className={`item-point-steper ${
              registerStep === registerSteps.INDUSTRY ? 'active' : 'done'
            }`}
          >
            {registerStep === registerSteps.FINALIZE ? (
              <CheckOutlined className="icon-check" />
            ) : (
              1
            )}
          </div>
          Business Type
        </div>
        <div className="item-steper">
          <div
            className={`item-point-steper ${
              registerStep === registerSteps.FINALIZE ? 'active' : ''
            }`}
          >
            2
          </div>
          Company Detail
        </div>
      </>
    );
  };

  renderMobileSteper = () => {
    const { registerStep } = this.state;
    return (
      <div className="item-steper-mobile">
        <div className="item-steper">
          <div className="steper">
            <div
              className={`item-point-steper ${
                registerStep === registerSteps.INDUSTRY ? 'active' : 'done'
              }`}
            >
              {registerStep === registerSteps.FINALIZE ? (
                <CheckOutlined className="icon-check" />
              ) : (
                1
              )}
            </div>
            Business Type
          </div>
          <div className="marker active" />
        </div>
        <div className="item-steper">
          <div className="steper">
            <div
              className={`item-point-steper ${
                registerStep === registerSteps.FINALIZE ? 'active' : ''
              }`}
            >
              2
            </div>
            Company Detail
          </div>
          <div
            className={`marker ${
              registerStep === registerSteps.FINALIZE ? 'active' : ''
            }`}
          />
        </div>
      </div>
    );
  };

  renderRegisterCompany = () => (
    <Row>
      {!window.IS_IN_MOBILE && (
        <Col xl={8} className="register-steper">
          {this.renderSteper()}
        </Col>
      )}
      <Col
        span={window.IS_IN_MOBILE ? 24 : 16}
        className="landing-content"
        id="register-industry-page"
      >
        {this.state.registerStep === registerSteps.INDUSTRY
          ? this.renderSelectIndustry()
          : this.renderRegisterFinalize()}
      </Col>
    </Row>
  );

  _onCloseModal = () => {
    this.setState({ confirmVisible: false });
  };

  _onConfirmModal = () => {
    this.props.history.replace('login');
  };

  render() {
    return (
      <LandingLayout fromEmpty>
        {this.state.registerStep === registerSteps.INITIAL
          ? this.renderRegisterInitial()
          : this.renderRegisterCompany()}

        <ModalConfirmation
          visible={this.state.confirmVisible}
          config={{
            icon: ICON_SUCCESS,
            title: 'Registrasi Berhasil',
            description:
              'Silakan gunakan email dan password kamu untuk masuk ke akun kamu ',
            btnConfirmText: 'OK',
          }}
          onClose={this._onCloseModal}
          onConfirm={this._onConfirmModal}
        />
      </LandingLayout>
    );
  }
}

RegisterPage.displayName = config.displayName;
RegisterPage.defaultProps = config.defaultProps;
RegisterPage.propTypes = config.propTypes;

export default RegisterPage;
