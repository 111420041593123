import PropTypes from 'prop-types';

const displayName = 'Profile';
const propTypes = {
  data: PropTypes.object,
  updateUser: PropTypes.func,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
