import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from './UnbindedData.component.config';

const UnbindedData = ({ title, center }) => {
  const {
    me: { isGuest },
  } = useSelector((state) => state.account);
  const history = useHistory();

  const bindAccount = () => {
    history.push('/binding');
  };

  const textDesc = isGuest
    ? 'Ask account owner bind the account to see'
    : 'Binding your account to see';

  return (
    <div className={`unbind ${center ? 'centered' : ''}`}>
      <div className="unbind-text">
        {textDesc}
        <br />
        {title} data
      </div>
      {!isGuest && (
        <Button block onClick={bindAccount}>
          Start Account Binding
        </Button>
      )}
    </div>
  );
};

UnbindedData.displayName = config.displayName;
UnbindedData.defaultProps = config.defaultProps;
UnbindedData.propTypes = config.propTypes;

export default UnbindedData;
