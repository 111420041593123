import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountReducer from './account.reducer';
import authReducer from './auth.reducer';
import bindingReducer from './binding.reducer';
import dataReducer from './data.reducer';

import fbAdsReducer from './dashboard/fb_ads.reducer';
import googAdwordsReducer from './dashboard/goog_adwords.reducer';
import googAnalyticsReducer from './dashboard/goog_analytics.reducer';
import homeReducer from './dashboard/home.reducer';
import marketplaceReducer from './dashboard/marketplace.reducer';
import socialmediaReducer from './dashboard/socialmedia.reducer';

const dataPresistConfig = {
  key: 'data',
  storage,
};

const moduleState = {
  account: accountReducer,
  auth: authReducer,
  binding: bindingReducer,
  data: persistReducer(dataPresistConfig, dataReducer),

  home: homeReducer,
  fb_ads: fbAdsReducer,
  goog_adwords: googAdwordsReducer,
  goog_analytics: googAnalyticsReducer,
  marketplace: marketplaceReducer,
  socialmedia: socialmediaReducer,
};

export default combineReducers(moduleState);
