import React, { useState } from 'react';
import get from 'lodash/get';
import { Card, Button, Typography, Modal } from 'antd';

import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardList.component.config';
import './CardList.component.styles.less';
import Loading from '../Loading/Loading.component';
import { ICON_NO_DATA } from '../../assets/icons';

const { Title, Text } = Typography;
const CardList = ({ title, data, limit, loading, isUnbind }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const extraActions = [
    <Button
      type="link"
      onClick={() => setModalVisible(true)}
      disabled={loading}
    >
      View More
    </Button>,
  ];

  const keyTitle = (index) => `${title.replace(/ /g, '')}-${index}`;

  const renderListData = (dataSource) => (
    <ol id="list-data">
      {dataSource.map(({ label, value }, index) => (
        <li key={`list-${keyTitle(index)}`}>
          <div className="list-row">
            <Text className="label">{label}</Text>
            <Text className="value">{value}</Text>
          </div>
        </li>
      ))}
    </ol>
  );

  const all = get(data, 'all', []);
  const short = get(data, 'short', []);

  return (
    <Card
      id="card-list"
      title={title}
      bordered={false}
      actions={all.length > limit && !isUnbind ? extraActions : null}
    >
      <div className="wrapper-data">
        {isUnbind ? (
          <UnbindedData title={title} center />
        ) : (
          <>
            {short.length < 1 && (
              <div className="empty">
                <img src={ICON_NO_DATA} alt="no-data" />
                <Text>
                  Maaf data ini belum tersedia. Silakan coba beberapa saat lagi
                </Text>
              </div>
            )}
            {!loading && renderListData(short)}
          </>
        )}
      </div>
      {loading && <Loading />}

      <Modal
        footer={null}
        closeIcon={<i className="icon-close" />}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <div id="modal-list">
          <Title level={4}>{title}</Title>
          {renderListData(all)}
          <div className="btn-row">
            <Button
              type="link"
              onClick={() => setModalVisible(false)}
              disabled={loading}
            >
              View Less
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

CardList.displayName = config.displayName;
CardList.defaultProps = config.defaultProps;
CardList.propTypes = config.propTypes;

export default CardList;
