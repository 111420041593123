import PropTypes from 'prop-types';

const displayName = 'CardDonutChart';
const propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  total: PropTypes.string,
  isUnbind: PropTypes.bool,
  bindAccount: PropTypes.func,
  loading: PropTypes.bool,
};
const defaultProps = {
  title: '',
  data: {},
  total: '',
  isUnbind: false,
  loading: false,
};

export default { displayName, propTypes, defaultProps };
