import React from 'react';
import { Card, Typography } from 'antd';

import { BarChart } from '../Charts';
import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardBarChart.component.config';
import './CardBarChart.component.styles.less';
import Loading from '../Loading/Loading.component';
import { ICON_CHART_NO_DATA } from '../../assets/icons';
const { Text } = Typography;

const CardBarChart = ({ title, loading, data, isUnbind, chartConfig }) => {
  const showNoData = data.dataList.length < 1;
  const renderData = showNoData ? (
    <div className="empty">
      <img src={ICON_CHART_NO_DATA} alt="no-data" />
      <Text>
        Maaf data chart ini belum tersedia. Silakan coba beberapa saat lagi
      </Text>
    </div>
  ) : (
    <BarChart chartData={data} hasLegend {...chartConfig} />
  );

  return (
    <Card id="card-bar-chart" title={title} bordered={false}>
      <div className="data-wrapper">
        {isUnbind ? <UnbindedData title={title} center /> : renderData}
      </div>
      {loading && <Loading />}
    </Card>
  );
};

CardBarChart.displayName = config.displayName;
CardBarChart.defaultProps = config.defaultProps;
CardBarChart.propTypes = config.propTypes;

export default CardBarChart;
