import React from 'react';
import get from 'lodash/get';
import { Row, Col, Carousel } from 'antd';

import { CardData, CardLineChart, CardList } from '../../components';

import { DashboardLayout } from '../../components/Layout';

import config from './Marketplace.component.config';
import { capitalizeFirstString } from '../../utils/string';
import { COLORS } from '../../constants/variables';

class Marketplace extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      marketplaceType: props.location.pathname.substring(7).toUpperCase(),
    };
  }

  componentDidMount() {
    const { marketplaceType } = this.state;
    this.props.getAggregatedStatus();
    if (this.props.accounts[marketplaceType].haveAccount) {
      this.props.getMarketplace(marketplaceType);
    }
  }

  componentDidUpdate(prevProps) {
    const { marketplaceType } = this.state;
    if (
      prevProps.accounts[marketplaceType].haveAccount !==
      this.props.accounts[marketplaceType].haveAccount
    ) {
      this.props.getMarketplace(marketplaceType);
    }
  }

  renderListDataDesktop = (data, isUnbind) => (
    <>
      <Col xl={8} md={12} sm={24} xs={24}>
        <CardData
          icon="basket-check"
          color={COLORS.green}
          backgroundColor={COLORS.bgGreen}
          title="Completed Orders"
          data={get(data, 'completedOrder')}
          loading={get(data, 'completedOrder.loading')}
          isUnbind={isUnbind}
        />
      </Col>

      <Col xl={8} md={12} sm={24} xs={24}>
        <CardData
          icon="basket-fail"
          color={COLORS.red}
          backgroundColor={COLORS.bgRed}
          title="Canceled Orders"
          data={get(data, 'canceledOrder')}
          loading={get(data, 'canceledOrder.loading')}
          isUnbind={isUnbind}
        />
      </Col>

      <Col xl={8} md={12} sm={24} xs={24}>
        <CardData
          icon="basket"
          color={COLORS.blue}
          backgroundColor={COLORS.bgBlue}
          title="Total Orders"
          data={get(data, 'totalOrder')}
          loading={get(data, 'totalOrder.loading')}
          isUnbind={isUnbind}
        />
      </Col>
    </>
  );

  renderListDataMobile = (data, isUnbind) => (
    <Col xl={8} md={12} sm={24} xs={24}>
      <Carousel centerMode infinite={false}>
        <CardData
          icon="basket-check"
          color={COLORS.green}
          backgroundColor={COLORS.bgGreen}
          title="Completed Orders"
          data={get(data, 'completedOrder')}
          loading={get(data, 'completedOrder.loading')}
          isUnbind={isUnbind}
        />

        <CardData
          icon="basket-fail"
          color={COLORS.red}
          backgroundColor={COLORS.bgRed}
          title="Canceled Orders"
          data={get(data, 'canceledOrder')}
          loading={get(data, 'canceledOrder.loading')}
          isUnbind={isUnbind}
        />

        <CardData
          icon="basket"
          color={COLORS.blue}
          backgroundColor={COLORS.bgBlue}
          title="Total Orders"
          data={get(data, 'totalOrder')}
          loading={get(data, 'totalOrder.loading')}
          isUnbind={isUnbind}
        />
      </Carousel>
    </Col>
  );

  renderListData = (data, isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderListDataMobile(data, isUnbind)
      : this.renderListDataDesktop(data, isUnbind);

  render() {
    const { marketplaceType } = this.state;
    const { getMarketplace, marketplaceData, accounts, location } = this.props;
    const data = marketplaceData[marketplaceType];
    const isUnbind = !accounts[marketplaceType].haveAccount;

    const { isAggregated } = data;

    return (
      <DashboardLayout
        title={`Sales Performance ${capitalizeFirstString(marketplaceType)}`}
        onPeriodChange={() => getMarketplace(marketplaceType)}
        location={location}
        isSynced={isAggregated}
        isSyncing={!isAggregated && !isUnbind}
      >
        <Row gutter={24}>
          <Col xl={8} md={12} sm={24} xs={24}>
            <CardList
              title="Top Sales by Product"
              data={get(data, 'salesByProduct.data')}
              loading={get(data, 'salesByProduct.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={8} md={12} sm={24} xs={24}>
            <CardList
              title="Top Volume by Product"
              data={get(data, 'volumeByProduct.data')}
              loading={get(data, 'volumeByProduct.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={8} md={12} sm={24} xs={24}>
            <CardLineChart
              title="Average Order Value"
              data={get(data, 'chartAvgOrder.data')}
              loading={get(data, 'chartAvgOrder.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          {this.renderListData(data, isUnbind)}
        </Row>
      </DashboardLayout>
    );
  }
}

Marketplace.displayName = config.displayName;
Marketplace.defaultProps = config.defaultProps;
Marketplace.propTypes = config.propTypes;

export default Marketplace;
