import { connect } from 'react-redux';
import Profile from './Profile.component';

import { updateUser } from '../../redux/actions/account.actions';

const mapStateToProps = (state) => ({
  data: state.account.me.data,
});

const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
