// import ConfigPage from '../containers/Config/Config.container';
import DashboardPage from '../containers/Dashboard/Dashboard.container';

import LoginPage from '../containers/Login/Login.container';
import RegisterPage from '../containers/Register/Register.container';
import ForgotPasswordPage from '../containers/ForgotPassword/ForgotPassword.container';

import path from './path';

export const BASE_ROUTE = [
  {
    id: 'login',
    path: path.login,
    component: LoginPage,
    exact: true,
  },
  {
    id: 'register',
    path: path.register,
    component: RegisterPage,
    exact: true,
  },
  {
    id: 'forgot-password',
    path: path['forgot-password'],
    component: ForgotPasswordPage,
    exact: true,
  },
  // {
  //   id: 'config',
  //   path: path.config,
  //   component: ConfigPage,
  // },
  {
    id: 'dashboard',
    path: path.dashboard,
    component: DashboardPage,
  },
];
