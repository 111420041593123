import get from 'lodash/get';

import { store } from '../../store';
import clientGql from '../../../services/graphql.service';

import {
  chartConversion,
  listTopProduct,
  chartTraficSource,
  chartCustomerRate,
  listTopLocation,
  listTopDevice,
  listTopLanding,
} from '../../../graphql/goog_analytics.graphql';
import { mapChartData } from '../../../utils/data';
import { formatNumber } from '../../../utils/string';
import {
  SET_GOOG_ANALYTICS_CONVERSION,
  SET_GOOG_ANALYTICS_CUSTOMER,
  SET_GOOG_ANALYTICS_DEVICE,
  SET_GOOG_ANALYTICS_LANDING,
  SET_GOOG_ANALYTICS_LOCATION,
  SET_GOOG_ANALYTICS_PRODUCTS,
  SET_GOOG_ANALYTICS_TRAFFIC,
} from '../../types';
import { COLORS } from '../../../constants/variables';
import { notification } from '../../../components';

const getVariables = () => {
  const accounts = store.getState().binding.accountList.GOOG_ANALYTICS.data;
  const interval = store.getState().data.period;
  const intervalCompare = `COMPARE_${interval}`;

  const [accountId] = accounts.map((account) => account.account_id);
  return { interval, accountId, intervalCompare };
};

const getConversion = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_CONVERSION,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: chartConversion,
      variables,
      context,
    });

    const conversion = mapChartData(variables.interval)(
      'transactionsPerSession',
      googleGaAnalytics,
    );

    dispatch({
      type: SET_GOOG_ANALYTICS_CONVERSION,
      payload: {
        xLabels: get(conversion, 'xLabels', []),
        dataList: [
          {
            label: 'Conversion Rate',
            hexColor: COLORS.primary,
            data: get(conversion, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_CONVERSION,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTopProducts = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_PRODUCTS,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: listTopProduct,
      variables,
      context,
    });

    const dataList = googleGaAnalytics.map((data) => ({
      label: get(data, 'productName', '-'),
      value: formatNumber(get(data, 'itemQuantity', 0)),
    }));

    dataList.sort(
      (a, b) =>
        b.value.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.value.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_GOOG_ANALYTICS_PRODUCTS,
      payload: {
        all: dataList.slice(0, 10),
        short: dataList.slice(0, 6),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_PRODUCTS,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTrafficSource = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_TRAFFIC,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: chartTraficSource,
      variables,
      context,
    });

    const dataSource = googleGaAnalytics.map((data) => ({
      name: get(data, 'sourceMedium', '-'),
      value: parseInt(get(data, 'sessions', 0), 10),
    }));

    dataSource.sort((a, b) => b.value - a.value);

    const dataList = dataSource.map((data, index) => ({
      ...data,
      color: COLORS.list[index],
    }));

    dispatch({
      type: SET_GOOG_ANALYTICS_TRAFFIC,
      payload: {
        all: dataList.slice(0, 10),
        short: dataList.slice(0, 3),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_TRAFFIC,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getCustomerRate = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_CUSTOMER,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: chartCustomerRate,
      variables,
      context,
    });

    const dataObject = {};
    googleGaAnalytics.forEach((item) => {
      if (dataObject[item.indexTime]) {
        dataObject[item.indexTime] = {
          ...dataObject[item.indexTime],
          [item.userType]: get(item, 'users', 0),
        };
      } else {
        dataObject[item.indexTime] = {
          [item.userType]: get(item, 'users', 0),
        };
      }
    });

    const dataSource = Object.keys(dataObject).map((item) => ({
      indexTime: item,
      new: parseInt(dataObject[item]['New Visitor'], 10),
      returning: parseInt(dataObject[item]['Returning Visitor'], 10),
    }));

    const newVisitor = mapChartData(variables.interval)('new', dataSource);
    const returningVisitor = mapChartData(variables.interval)(
      'returning',
      dataSource,
    );

    dispatch({
      type: SET_GOOG_ANALYTICS_CUSTOMER,
      payload: {
        xLabels: get(newVisitor, 'xLabels', []),
        dataList: [
          {
            label: 'New Visitor',
            hexColor: COLORS.primary,
            data: get(newVisitor, 'data', []),
          },
          {
            label: 'Returning Visitor',
            hexColor: COLORS.secondary,
            data: get(returningVisitor, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_CUSTOMER,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTopLocation = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_LOCATION,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: listTopLocation,
      variables,
      context,
    });

    const dataList = googleGaAnalytics.map((data) => ({
      label: get(data, 'country', '-'),
      value: formatNumber(get(data, 'sessions', 0)),
    }));

    dataList.sort(
      (a, b) =>
        b.value.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.value.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_GOOG_ANALYTICS_LOCATION,
      payload: {
        all: dataList.slice(0, 10),
        short: dataList.slice(0, 6),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_LOCATION,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTopDevice = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_DEVICE,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: listTopDevice,
      variables,
      context,
    });

    const dataList = googleGaAnalytics.map((data) => ({
      label: get(data, 'deviceCategory', '-'),
      value: formatNumber(get(data, 'sessions', 0)),
    }));

    dataList.sort(
      (a, b) =>
        b.value.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.value.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_GOOG_ANALYTICS_DEVICE,
      payload: {
        all: dataList.slice(0, 10),
        short: dataList.slice(0, 6),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_DEVICE,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTopLanding = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ANALYTICS_LANDING,
    loading: true,
  });
  try {
    const {
      data: { googleGaAnalytics },
    } = await clientGql.query({
      query: listTopLanding,
      variables,
      context,
    });

    const dataList = googleGaAnalytics.map((data) => ({
      label: get(data, 'landingPagePath', '-'),
      value: formatNumber(get(data, 'sessions', 0)),
    }));

    dataList.sort(
      (a, b) =>
        b.value.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.value.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_GOOG_ANALYTICS_LANDING,
      payload: {
        all: dataList.slice(0, 10),
        short: dataList.slice(0, 6),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ANALYTICS_LANDING,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

let querySource;

export const getGoogAnalytics = () => async (dispatch) => {
  const variables = getVariables();
  if (querySource) {
    querySource.abort();
  }

  const source = new window.AbortController();
  querySource = source;

  const context = {
    fetchOptions: { signal: source.signal },
  };

  try {
    await Promise.all([
      getConversion(variables, context)(dispatch),
      getTopProducts(variables, context)(dispatch),
      getTrafficSource(variables, context)(dispatch),
      getCustomerRate(variables, context)(dispatch),
      getTopLocation(variables, context)(dispatch),
      getTopDevice(variables, context)(dispatch),
      getTopLanding(variables, context)(dispatch),
    ]);
  } catch (error) {
    notification.error({
      message: 'Terjadi Kesalahan',
      description: `Sebagian data gagal diproses, silakan coba lagi`,
    });
  }
};
