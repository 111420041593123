import { connect } from 'react-redux';
import AccountBinding from './AccountBinding.component';

import {
  bindFbAdsAccount,
  bindFbInstagram,
  saveAccounts,
  bindGoogleAds,
  bindGoogleAnalytics,
  getGoogleAccounts,
  bindTokopedia,
  bindShopify,
  getBindedAccounts,
  unbindAccount,
  bindShopee,
  saveMarketplaceAccount,
} from '../../redux/actions/binding.actions';
import { setIntroDone } from '../../redux/actions/account.actions';

const mapStateToProps = (state) => ({
  ...state.binding,
  isFirstTime: state.account.me.isFirstTime.binding,
});

const mapDispatchToProps = {
  bindFbAdsAccount,
  bindFbInstagram,
  saveAccounts,
  bindGoogleAds,
  bindGoogleAnalytics,
  getGoogleAccounts,
  bindTokopedia,
  bindShopify,
  getBindedAccounts,
  unbindAccount,
  bindShopee,
  saveMarketplaceAccount,
  setIntroDone,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBinding);
