import get from 'lodash/get';

import {
  SET_HOME_TOTAL_SALES,
  SET_HOME_TOP_SALES,
  SET_HOME_TOTAL_AOV,
  SET_HOME_TOTAL_CPA,
  SET_HOME_TOTAL_CPR,
  SET_HOME_VS_AOV,
  SET_HOME_VS_CPA,
  SET_HOME_VS_CPR,
  SET_AGGREGATED_HOME,
} from '../../types';

const initialState = {
  isAggregated: false,
  totalSales: {
    value: 0,
    diff: 0,
    loading: false,
  },
  totalAov: {
    value: 0,
    diff: 0,
    loading: false,
  },
  totalCpr: {
    value: 0,
    diff: 0,
    loading: false,
  },
  totalCpa: {
    value: 0,
    diff: 0,
    loading: false,
  },
  vsAov: {
    value: 0,
    loading: false,
  },
  vsCpr: {
    value: 0,
    loading: false,
  },
  vsCpa: {
    value: 0,
    loading: false,
  },
  topSales: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AGGREGATED_HOME:
      return {
        ...state,
        isAggregated: action.payload,
      };
    case SET_HOME_TOTAL_SALES:
      return {
        ...state,
        totalSales: {
          ...state.totalSales,
          ...get(action, 'payload.totalSales'),
          loading: action.loading,
        },
      };
    case SET_HOME_TOTAL_AOV:
      return {
        ...state,
        totalAov: {
          ...state.totalAov,
          ...get(action, 'payload.totalAov'),
          loading: action.loading,
        },
      };
    case SET_HOME_TOTAL_CPR:
      return {
        ...state,
        totalCpr: {
          ...state.totalCpr,
          ...get(action, 'payload.totalCpr'),
          loading: action.loading,
        },
      };
    case SET_HOME_TOTAL_CPA:
      return {
        ...state,
        totalCpa: {
          ...state.totalCpa,
          ...get(action, 'payload.totalCpa'),
          loading: action.loading,
        },
      };
    case SET_HOME_VS_AOV:
      return {
        ...state,
        vsAov: {
          ...state.vsAov,
          ...get(action, 'payload.vsAov'),
          loading: action.loading,
        },
      };
    case SET_HOME_VS_CPR:
      return {
        ...state,
        vsCpr: {
          ...state.vsCpr,
          ...get(action, 'payload.vsCpr'),
          loading: action.loading,
        },
      };
    case SET_HOME_VS_CPA:
      return {
        ...state,
        vsCpa: {
          ...state.vsCpa,
          ...get(action, 'payload.vsCpa'),
          loading: action.loading,
        },
      };
    case SET_HOME_TOP_SALES:
      return {
        ...state,
        topSales: {
          data: {
            short: get(action, 'payload.short'),
            all: get(action, 'payload.all'),
          },
          loading: action.loading,
        },
      };
    default:
      return state;
  }
};
