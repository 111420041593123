import PropTypes from 'prop-types';

const displayName = 'CardBinding';
const propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClickBind: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  btnLoading: PropTypes.bool,
  accounts: PropTypes.array,
};
const defaultProps = {
  loading: false,
  accounts: [],
};

export default { displayName, propTypes, defaultProps };
