import React from 'react';
import get from 'lodash/get';
import { Card, Typography, Form, Input, Button, Row, Col, Select } from 'antd';
import { formatAvatar } from '../../utils/string';
import { DashboardLayout } from '../../components/Layout';
import { ModalConfirmation, notification } from '../../components';
import { ICON_SUCCESS } from '../../assets/icons';
import { INDUSTRY_LISTS } from '../../constants/variables';

import config from './Profile.component.config';
import './Profile.component.styles.less';

const { Title, Text } = Typography;
const { Option } = Select;

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      inOnEdit: false,
      confirmVisible: false,
    };
  }

  profileForm = React.createRef();

  _onSubmitProfile = (value) => {
    if (this.state.inOnEdit) {
      this.setState({ isLoading: true });
      this.props
        .updateUser(value)
        .then(() => {
          this.setState({
            confirmVisible: true,
            isLoading: false,
          });
        })
        .catch((err) => {
          if (err === 'INVALID_PASSWORD')
            this.profileForm.current.setFields([
              {
                name: 'old_password',
                errors: ['Current Password is not valid'],
              },
            ]);
          notification.error({
            message: 'Update Profile Gagal',
            description: `Update profile belum berhasil`,
          });
          this.setState({ isLoading: false });
        });
    }
  };

  _onCloseModal = () => {
    this.setState({ confirmVisible: false });
  };

  _onConfirmModal = () => {
    this.setState({
      confirmVisible: false,
      inOnEdit: false,
    });
  };

  renderBtnForm = () => {
    const { isLoading } = this.state;

    return this.state.inOnEdit ? (
      <>
        <Button
          type="default"
          htmlType="button"
          style={{ marginRight: 16 }}
          onClick={(e) => {
            e.preventDefault();

            this.profileForm.current.resetFields();
            this.setState({ inOnEdit: false });
          }}
        >
          Cancel
        </Button>

        <Button loading={isLoading} type="primary" htmlType="submit">
          Save
        </Button>
      </>
    ) : (
      <Button
        type="primary"
        htmlType="button"
        onClick={(e) => {
          e.preventDefault();
          this.setState({ inOnEdit: true });
        }}
      >
        Edit
      </Button>
    );
  };

  render() {
    const { data, location } = this.props;
    const { inOnEdit } = this.state;

    return (
      <DashboardLayout title="Profile" withPeriod={false} location={location}>
        <Card id="profile" bordered={false}>
          <div className="row-profile">
            <div className="avatar">{formatAvatar(get(data, 'name'))}</div>
            <div className="info">
              <Title level={4}>{get(data, 'name')}</Title>
              <Text>{get(data, 'email')}</Text>
            </div>
          </div>

          {data.name && (
            <Form
              ref={this.profileForm}
              className="form"
              layout="vertical"
              name="profile"
              initialValues={data}
              onFinish={this._onSubmitProfile}
            >
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Full Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Full Name is required',
                      },
                    ]}
                  >
                    <Input
                      disabled={!inOnEdit}
                      placeholder="Full Name"
                      autoComplete="off"
                      className="input-text"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Company Name"
                    name="company_name"
                    rules={[
                      {
                        required: true,
                        message: 'Company Name is required',
                      },
                    ]}
                  >
                    <Input
                      disabled={!inOnEdit}
                      className="input-text"
                      placeholder="Company Name"
                      autoComplete="off"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Industry Category"
                    name="company_category"
                    rules={[
                      {
                        required: true,
                        message: 'Industry Category is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Industry Category"
                      disabled={!inOnEdit}
                    >
                      {INDUSTRY_LISTS.map((industry) => (
                        <Option
                          value={industry.key}
                          key={industry.key}
                          className="industry-item-option"
                        >
                          <img
                            src={industry.image}
                            alt={`image_${industry.key.toLowerCase()}`}
                          />
                          {industry.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={12} xs={24}>
                  <Form.Item
                    label="Current Password"
                    name="old_password"
                    dependencies={['password']}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value && getFieldValue('password')) {
                            return Promise.reject(
                              new Error('Current Password is required'),
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      disabled={!inOnEdit}
                      autoComplete="off"
                      placeholder="Current Password"
                    />
                  </Form.Item>

                  <Form.Item
                    label="New Password"
                    name="password"
                    dependencies={['old_password']}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let result;
                          if (!value && getFieldValue('old_password')) {
                            result = Promise.reject(
                              new Error('New Password is required'),
                            );
                          } else if (
                            getFieldValue('old_password') &&
                            getFieldValue('old_password') === value
                          ) {
                            result = Promise.reject(
                              new Error(
                                "New Password shouldn't equal with Current Password",
                              ),
                            );
                          } else {
                            result = Promise.resolve();
                          }

                          return result;
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      disabled={!inOnEdit}
                      autoComplete="off"
                      placeholder="New Password"
                    />
                  </Form.Item>

                  <Form.Item className="button">
                    {this.renderBtnForm()}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Card>

        <ModalConfirmation
          visible={this.state.confirmVisible}
          config={{
            icon: ICON_SUCCESS,
            title: 'Update Profile Berhasil',
            description: 'Yeah!! proses update profile kamu sudah berhasil.',
            btnConfirmText: 'OK',
          }}
          onClose={this._onCloseModal}
          onConfirm={this._onConfirmModal}
        />
      </DashboardLayout>
    );
  }
}

Profile.displayName = config.displayName;
Profile.defaultProps = config.defaultProps;
Profile.propTypes = config.propTypes;

export default Profile;
