const basePath = {
  login: '/login',
  register: '/register',
  'forgot-password': '/forgot-password',
  dashboard: '/',
  config: '/config',
};

const dashboardPath = {
  summary: `${basePath.dashboard}summary`,
  performace: `${basePath.dashboard}performace`,
  tokopedia: `${basePath.dashboard}sales/tokopedia`,
  shopee: `${basePath.dashboard}sales/shopee`,
  shopify: `${basePath.dashboard}sales/shopify`,
  binding: `${basePath.dashboard}binding`,
  'social-media': `${basePath.dashboard}social-media`,
  'fb-ads': `${basePath.dashboard}campaign/fb-ads`,
  'google-ads': `${basePath.dashboard}campaign/google-ads`,
  profile: `${basePath.dashboard}profile`,
};

export default {
  ...basePath,
  dashboardPath,
};
