import PropTypes from 'prop-types';

const displayName = 'CampaignFB';
const propTypes = {
  getFbAds: PropTypes.func.isRequired,
  accounts: PropTypes.object,
  fbData: PropTypes.object,
};
const defaultProps = {
  accounts: {},
  fbData: {},
};
export default { displayName, propTypes, defaultProps };
