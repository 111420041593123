import React from 'react';
import get from 'lodash/get';
import { Row, Col, Carousel } from 'antd';

import {
  CardList,
  CardLineChart,
  CardBarChart,
  CardDonutListChart,
} from '../../components';

import { DashboardLayout } from '../../components/Layout';

import config from './PerformanceAnalytics.component.config';

class PerformanceAnalytics extends React.PureComponent {
  componentDidMount() {
    if (this.props.accounts.GOOG_ANALYTICS.haveAccount) {
      this.props.getGoogAnalytics();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.accounts.GOOG_ANALYTICS.haveAccount !==
      this.props.accounts.GOOG_ANALYTICS.haveAccount
    ) {
      this.props.getGoogAnalytics();
    }
  }

  renderListDesktop = (isUnbind) => {
    const { performanceData } = this.props;
    return (
      <>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardList
            title="Top Product Sold"
            data={get(performanceData, 'listTopProducts.data')}
            loading={get(performanceData, 'listTopProducts.loading')}
            isUnbind={isUnbind}
          />
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardDonutListChart
            title="Traffic Source"
            data={get(performanceData, 'chartTraffic.data')}
            loading={get(performanceData, 'chartTraffic.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </>
    );
  };

  renderListMobile = (isUnbind) => {
    const { performanceData } = this.props;
    return (
      <Col xl={6} md={12} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardList
            title="Top Product Sold"
            data={get(performanceData, 'listTopProducts.data')}
            loading={get(performanceData, 'listTopProducts.loading')}
            isUnbind={isUnbind}
          />

          <CardDonutListChart
            title="Traffic Source"
            data={get(performanceData, 'chartTraffic.data')}
            loading={get(performanceData, 'chartTraffic.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderList = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderListMobile(isUnbind)
      : this.renderListDesktop(isUnbind);

  render() {
    const {
      getGoogAnalytics,
      performanceData,
      accounts,
      location,
    } = this.props;
    const isUnbind = !accounts.GOOG_ANALYTICS.haveAccount;

    return (
      <DashboardLayout
        title="Performance"
        onPeriodChange={getGoogAnalytics}
        location={location}
      >
        <Row gutter={24}>
          <Col xl={12} md={24} sm={24} xs={24}>
            <CardLineChart
              title="Conversion Rate"
              data={get(performanceData, 'chartConversion.data')}
              loading={get(performanceData, 'chartConversion.loading')}
              isUnbind={isUnbind}
              chartConfig={{
                hasLegend: true,
                config: {
                  hideYAxis: window.IS_IN_MOBILE,
                  height: 260,
                  YLabelRight: true,
                },
              }}
            />
          </Col>

          {this.renderList()}

          <Col xl={12} md={24} sm={24} xs={24}>
            <CardBarChart
              title="Running Customer Rate"
              data={get(performanceData, 'chartCustomer.data')}
              loading={get(performanceData, 'chartCustomer.loading')}
              isUnbind={isUnbind}
              chartConfig={{
                config: {
                  hideYAxis: window.IS_IN_MOBILE,
                  hideXAxis: false,
                  YLabelRight: true,
                },
              }}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardList
              title="Location Based"
              data={get(performanceData, 'listTopLocation.data')}
              loading={get(performanceData, 'listTopLocation.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardList
              title="Top Device"
              data={get(performanceData, 'listTopDevice.data')}
              loading={get(performanceData, 'listTopDevice.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardList
              title="Top Landing Page"
              data={get(performanceData, 'listTopLanding.data')}
              loading={get(performanceData, 'listTopLanding.loading')}
              isUnbind={isUnbind}
            />
          </Col>
        </Row>
      </DashboardLayout>
    );
  }
}

PerformanceAnalytics.displayName = config.displayName;
PerformanceAnalytics.defaultProps = config.defaultProps;
PerformanceAnalytics.propTypes = config.propTypes;

export default PerformanceAnalytics;
