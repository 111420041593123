import SummaryPage from '../containers/Summary/Summary.container';

import AccountBindingPage from '../containers/AccountBinding/AccountBinding.container';
import ProfilePage from '../containers/Profile/Profile.container';

import CampaignFBPage from '../containers/CampaignFB/CampaignFB.container';
import CampaignGooglePage from '../containers/CampaignGoogle/CampaignGoogle.container';
import PerformancePage from '../containers/PerformanceAnalytics/PerformanceAnalytics.container';
import MarketplacePage from '../containers/Marketplace/Marketplace.container';
import SocialMediaPage from '../containers/SocialMediaInstagram/SocialMediaInstagram.container';

import path from './path';

export const DASHBOARD_ROUTE = [
  {
    id: 'index',
    path: path.dashboardPath.summary,
    component: SummaryPage,
  },
  {
    id: 'summary',
    path: path.dashboardPath.summary,
    component: SummaryPage,
    exact: true,
  },
  {
    id: 'performace',
    path: path.dashboardPath.performace,
    component: PerformancePage,
    exact: true,
  },
  {
    id: 'tokopedia',
    path: path.dashboardPath.tokopedia,
    component: MarketplacePage,
    exact: true,
  },
  {
    id: 'shopee',
    path: path.dashboardPath.shopee,
    component: MarketplacePage,
    exact: true,
  },
  {
    id: 'shopify',
    path: path.dashboardPath.shopify,
    component: MarketplacePage,
    exact: true,
  },
  {
    id: 'binding',
    path: path.dashboardPath.binding,
    component: AccountBindingPage,
    exact: true,
  },
  {
    id: 'fb-ads',
    path: path.dashboardPath['fb-ads'],
    component: CampaignFBPage,
    exact: true,
  },
  {
    id: 'google-ads',
    path: path.dashboardPath['google-ads'],
    component: CampaignGooglePage,
    exact: true,
  },
  {
    id: 'social-media',
    path: path.dashboardPath['social-media'],
    component: SocialMediaPage,
    exact: true,
  },
  {
    id: 'profile',
    path: path.dashboardPath.profile,
    component: ProfilePage,
    exact: true,
  },
];
