import PropTypes from 'prop-types';

const displayName = 'UnbindedData';
const propTypes = {
  title: PropTypes.string,
  center: PropTypes.bool,
};
const defaultProps = {
  title: '',
  center: false,
};

export default { displayName, propTypes, defaultProps };
