/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import get from 'lodash/get';

import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import BorderRadius from 'highcharts-border-radius';

import { formatNumber, nFormatter } from '../../../utils/string';

import config from './HorizontalBarChart.component.config';
import { COLORS } from '../../../constants/variables';

BorderRadius(Highcharts);
NoData(Highcharts);

const HorizontalBarChart = ({ chartData, hasLegend }) => {
  const dataSeries = chartData.dataList.map((item) => {
    const withRadius = window.IS_IN_MOBILE ? 8 : 16;
    return {
      name: item.label,
      color: item.hexColor,
      data: item.data,

      borderRadiusTopLeft: item.label === 'Male' ? withRadius : 0,
      borderRadiusTopRight: item.label === 'Male' ? withRadius : 0,
      borderRadiusBottomLeft: item.label === 'Female' ? withRadius : 0,
      borderRadiusBottomRight: item.label === 'Female' ? withRadius : 0,
    };
  });

  const xAxisSingle = {
    categories: chartData.xLabels,
    crosshair: true,
    lineColor: 'rgb(204, 214, 235)',
    lineWidth: 1,
  };

  const xAxisMirror = [
    {
      categories: chartData.xLabels,
      reversed: false,
      crosshair: true,
      lineColor: 'rgb(204, 214, 235)',
      lineWidth: 1,
    },
    {
      opposite: true,
      reversed: false,
      categories: chartData.xLabels,
      linkedTo: 0,
      crosshair: true,
      lineColor: 'rgb(204, 214, 235)',
      lineWidth: 1,
    },
  ];

  const xAxisDesktop =
    chartData.dataList.length === 1 ? xAxisSingle : xAxisMirror;

  const options = {
    chart: {
      type: 'bar',
      height:
        (window.IS_IN_MOBILE ? 100 : 200) +
        get(dataSeries[0], 'data.length', 1) * (window.IS_IN_MOBILE ? 16 : 32),
    },
    title: {
      text: '',
    },
    lang: {
      noData: 'No Data',
    },
    credits: {
      enabled: false,
    },
    xAxis: window.IS_IN_MOBILE
      ? { visible: false, categories: chartData.xLabels }
      : xAxisDesktop,
    yAxis: {
      title: {
        text: '',
      },
      visible: true,
      gridLineColor: 'none',
      lineColor: 'rgb(204, 214, 235)',
      lineWidth: 1,
      labels: {
        formatter: (params) => `${nFormatter(Math.abs(params.value))}`,
      },
    },
    tooltip: {
      crosshairs: true,
      formatter() {
        return (
          '<b>' +
          this.series.name +
          ', age ' +
          this.point.category +
          '</b><br/>' +
          formatNumber(Math.abs(this.point.y).toFixed(0))
        );
      },
      backgroundColor: COLORS['dark-background'],
      borderRadius: 16,
      style: {
        color: '#fff',
      },
      shape: 'callout',
    },
    legend: {
      enabled: hasLegend,
      align: 'left',
      verticalAlign: 'top',
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 2,
      squareSymbol: true,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
      bar: {
        borderWidth: 0,
        pointWidth: window.IS_IN_MOBILE ? 16 : 32,
      },
    },
    series: dataSeries,
  };

  return (
    <div id="bar-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

HorizontalBarChart.displayName = config.displayName;
HorizontalBarChart.defaultProps = config.defaultProps;
HorizontalBarChart.propTypes = config.propTypes;

export default HorizontalBarChart;
