import { connect } from 'react-redux';
import { resetPassword } from '../../redux/actions/auth.actions';

import ForgotPassword from './ForgotPassword.component';

const mapDispatchToProps = {
  resetPassword,
};

const mapStateToProps = (state) => ({
  ...state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
