import {
  SET_LOGIN_FACEBOOK,
  SET_LOGIN_GOOGLE,
  SET_LOGIN_EMAIL,
  SET_RESET_PASSWORD,
  SET_REGISTER,
} from '../types';

const initialState = {
  loadingFacebook: false,
  loadingGoogle: false,
  loadingEmail: false,
  loadingRegister: false,
  isPasswordCreated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_FACEBOOK:
    case SET_LOGIN_GOOGLE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_RESET_PASSWORD:
    case SET_LOGIN_EMAIL:
      return {
        ...state,
        loadingEmail: action.loading,
      };
    case SET_REGISTER:
      return {
        ...state,
        loadingRegister: action.loading,
      };
    default:
      return state;
  }
};
