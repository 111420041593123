import React from 'react';
import { Typography, Button, Modal } from 'antd';

import config from './ModalConfirmation.component.config';
import './ModalConfirmation.component.styles.less';

const { Title, Text } = Typography;
const ModalConfirmation = ({
  visible,
  config: { icon, title, description, btnConfirmText, extra, btnStyle },
  onClose,
  onConfirm,
}) => (
  <Modal
    visible={visible}
    footer={null}
    closeIcon={<i className="icon-close" />}
    onCancel={onClose}
  >
    <div id="modal-confirm">
      <img src={icon} alt="icon-sent" />
      <Title level={4}>{title}</Title>
      <Text>{description}</Text>
      {extra}
      <Button type="primary" onClick={onConfirm} className={btnStyle}>
        {btnConfirmText}
      </Button>
    </div>
  </Modal>
);

ModalConfirmation.displayName = config.displayName;
ModalConfirmation.defaultProps = config.defaultProps;
ModalConfirmation.propTypes = config.propTypes;

export default ModalConfirmation;
