import PropTypes from 'prop-types';

const displayName = 'SideMenuDashboard';
const propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  isGuest: PropTypes.bool,
};
const defaultProps = {
  isGuest: false,
};

export default {
  displayName,
  propTypes,
  defaultProps,
};
