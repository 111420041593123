import React from 'react';
import { Layout, Menu, Image, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { LOGO_IMG } from '../../../assets/images';
import { destroyToken } from '../../../services/token.service';
import path from '../../../navigation/path';

import SideMenuDashboardItem from '../SideMenuDashboardItem/SideMenuDashboardItem.component';

import config from './SideMenuDashboard.component.config';
import './SideMenuDashboard.component.styles.less';
import { ICON_CLOSE } from '../../../assets/icons';

const { Sider } = Layout;
const { confirm } = Modal;
const { SubMenu } = Menu;

const SIDE_MENU_ITEMS = {
  summary: {
    title: 'Dashboard',
    icon: 'home',
    url: path.dashboardPath.summary,
  },
  performance: {
    title: 'Performance',
    icon: 'performance',
    url: path.dashboardPath.performace,
  },
  binding: {
    title: 'Account Binding',
    icon: 'binding',
    url: path.dashboardPath.binding,
  },
  'social-media': {
    title: 'Social Media',
    icon: 'instagram',
    url: path.dashboardPath['social-media'],
  },
};

const SideMenuDashboard = ({ location, history, closeMenu, isGuest }) => {
  const goToDashboard = () => {
    history.replace('/');
  };
  const onNavigate = (e) => {
    if (e.key !== 'divider') history.replace(`/${e.key}`);
    if (closeMenu) {
      closeMenu();
    }
  };

  const onLogOut = () => {
    confirm({
      icon: <ExclamationCircleOutlined style={{ color: '#ea4e4e' }} />,
      content: `Are you sure do you want to ${isGuest ? 'Exit' : 'log out'}?`,
      onOk: destroyToken,
    });
  };
  return (
    <Sider width={272} id="dashboard-side-menu" className="no-scroll-bar">
      <div>
        <div className="sidebar-title">
          <Image
            src={LOGO_IMG}
            alt="bdd-logo"
            preview={false}
            className="bdd-logo"
            onClick={goToDashboard}
          />
          {window.IS_IN_MOBILE && (
            <div
              className="close-sidebar"
              onClick={window.IS_IN_MOBILE ? closeMenu : () => {}}
            >
              <img src={ICON_CLOSE} alt="icon-close" />
            </div>
          )}
        </div>

        <Menu
          defaultSelectedKeys={[location.pathname.slice(1)]}
          selectedKeys={[location.pathname.slice(1)]}
          onClick={onNavigate}
          mode="inline"
        >
          <Menu.Item key={SIDE_MENU_ITEMS.summary.url.slice(1)}>
            <SideMenuDashboardItem item={SIDE_MENU_ITEMS.summary} />
          </Menu.Item>
          <Menu.Item key={SIDE_MENU_ITEMS.performance.url.slice(1)}>
            <SideMenuDashboardItem item={SIDE_MENU_ITEMS.performance} />
          </Menu.Item>
          <SubMenu
            icon={<i className="icon-marketplace" />}
            key="sales"
            title="Sales Performance"
          >
            <Menu.Item key="sales/tokopedia">
              Tokopedia <i className="ant-menu-submenu-arrow" />
            </Menu.Item>
            <Menu.Item key="sales/shopee">
              Shopee <i className="ant-menu-submenu-arrow" />
            </Menu.Item>
            <Menu.Item key="sales/shopify">
              Shopify <i className="ant-menu-submenu-arrow" />
            </Menu.Item>
          </SubMenu>
          <SubMenu
            icon={<i className="icon-campaign" />}
            key="campaign"
            title="Campaign"
          >
            <Menu.Item key="campaign/fb-ads">
              Facebook Ads <i className="ant-menu-submenu-arrow" />
            </Menu.Item>
            <Menu.Item key="campaign/google-ads">
              Google Ads <i className="ant-menu-submenu-arrow" />
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={SIDE_MENU_ITEMS['social-media'].url.slice(1)}>
            <SideMenuDashboardItem item={SIDE_MENU_ITEMS['social-media']} />
          </Menu.Item>
          {!isGuest && (
            <Menu.Item
              key={SIDE_MENU_ITEMS.binding.url.slice(1)}
              id="binding-menu"
            >
              <SideMenuDashboardItem item={SIDE_MENU_ITEMS.binding} />
            </Menu.Item>
          )}
        </Menu>
      </div>

      <Menu
        style={{ marginBottom: 20 }}
        selectedKeys={[location.pathname.slice(1)]}
        mode="inline"
        onClick={onLogOut}
      >
        <Menu.Item key="logout">
          <SideMenuDashboardItem
            className="logout-btn"
            item={{ icon: 'logout', title: isGuest ? 'Exit' : 'Log Out' }}
          />
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

SideMenuDashboard.displayName = config.displayName;
SideMenuDashboard.defaultProps = config.defaultProps;
SideMenuDashboard.propTypes = config.propTypes;

export default SideMenuDashboard;
