import { connect } from 'react-redux';
import Marketplace from './Marketplace.component';

import {
  getMarketplace,
  getAggregatedStatus,
} from '../../redux/actions/dashboard/marketplace.actions';

const mapStateToProps = (state) => ({
  accounts: state.binding.accountList,
  marketplaceData: state.marketplace,
});

const mapDispatchToProps = { getMarketplace, getAggregatedStatus };

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
