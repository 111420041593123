import { connect } from 'react-redux';
import CampaignGoogle from './CampaignGoogle.component';

import { getGoogleAds } from '../../redux/actions/dashboard/goog_adwords.actions';

const mapStateToProps = (state) => ({
  accounts: state.binding.accountList,
  adwordData: state.goog_adwords,
});

const mapDispatchToProps = { getGoogleAds };

export default connect(mapStateToProps, mapDispatchToProps)(CampaignGoogle);
