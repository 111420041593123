import React from 'react';
import get from 'lodash/get';
import memoize from 'lodash.memoize';
import { Row, Col, Tabs } from 'antd';
import introJs from 'intro.js';

import {
  CardBinding,
  ModalBinding,
  ModalBindingTokopedia,
  ModalBindingShopify,
} from '../../components';
import { DashboardLayout } from '../../components/Layout';
import {
  FB_ADS_INSIGHT,
  FB_INSTAGRAM,
  GOOG_ADWORDS,
  GOOG_ANALYTICS,
  SHOPEE,
  SHOPIFY,
  TOKOPEDIA,
} from '../../constants/variables';
import { getParamURL } from '../../utils/data';

import {
  ICON_TOKOPEDIA,
  ICON_SHOPEE,
  ICON_SHOPIFY,
  ICON_FACEBOOK_ADS,
  ICON_GOOGLE_ADS,
  ICON_GOOGLE_ANALYTIC,
  ICON_INSTAGRAM,
} from '../../assets/icons';

import config from './AccountBinding.component.config';

const { TabPane } = Tabs;

class AccountBinding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: '1',
    };
  }

  componentDidMount() {
    const haveParams = this.props.location.search.length > 0;
    if (haveParams) {
      const payload = {
        state: getParamURL('state'),
        code: getParamURL('code'),
        scopes: getParamURL('scope'),
      };

      const shopId = getParamURL('shop_id');

      if (payload.scopes.includes('analytics')) {
        this.setState({ selectedTab: '3' }, () => {
          this.props.getGoogleAccounts(GOOG_ANALYTICS, payload);
        });
      } else if (payload.scopes.includes('adwords')) {
        this.setState({ selectedTab: '2' }, () => {
          this.props.getGoogleAccounts(GOOG_ADWORDS, payload);
        });
      } else if (shopId) {
        this.setState({ selectedTab: '1' }, () => {
          this.props
            .saveMarketplaceAccount(SHOPEE, {
              shop_id: shopId,
              name: SHOPEE,
            })
            .then(() => this.props.getBindedAccounts(SHOPEE));
        });
      }
    }

    if (this.props.isFirstTime) {
      setTimeout(() => {
        introJs()
          .setOptions({
            tooltipClass: 'tutorial',
            disableInteraction: true,
            steps: [
              {
                element: document.querySelector('.ant-tabs-nav-wrap'),
                title: 'Cara Binding Account',
                intro:
                  'Binding accountmu mulai dari Marketplace, Marketing, Performance, dan Social Media',
              },
              {
                element: document.querySelector('.ant-card'),
                title: 'Cara Binding Account',
                intro:
                  'Pilih tombol “Binding Account” lalu masukkan data informasi akun yang ingin dihubungkan',
              },
            ],
          })
          .start();

        this.props.setIntroDone(true);
      }, 100);
    }
  }

  bindingConfig = memoize((props) => ({
    marketplace: [
      {
        icon: ICON_TOKOPEDIA,
        type: 'Tokopedia',
        asset: get(props.assetList, TOKOPEDIA),
        onClickBind: props.bindTokopedia,
        btnLoading: props.bindingTokopedia.btnLoading,
        accounts: props.accountList.TOKOPEDIA.data,
        loading: props.accountList.TOKOPEDIA.loading,
      },
      {
        icon: ICON_SHOPEE,
        type: 'Shopee',
        asset: get(props.assetList, SHOPEE),
        onClickBind: props.bindShopee,
        btnLoading: props.bindingShopee.btnLoading,
        accounts: props.accountList.SHOPEE.data,
        loading: props.accountList.SHOPEE.loading,
      },
      {
        icon: ICON_SHOPIFY,
        type: 'Shopify',
        asset: get(props.assetList, SHOPIFY),
        onClickBind: props.bindShopify,
        btnLoading: props.bindingShopify.btnLoading,
        accounts: props.accountList.SHOPIFY.data,
        loading: props.accountList.SHOPIFY.loading,
      },
    ],
    marketing: [
      {
        icon: ICON_FACEBOOK_ADS,
        type: 'Facebook Ads',
        asset: get(props.assetList, FB_ADS_INSIGHT),
        onClickBind: props.bindFbAdsAccount,
        btnLoading: props.bindingFacebook.btnLoading,
        accounts: props.accountList.FB_ADS_INSIGHT.data,
        loading: props.accountList.FB_ADS_INSIGHT.loading,
      },
      {
        icon: ICON_GOOGLE_ADS,
        type: 'Google Ads',
        asset: get(props.assetList, GOOG_ADWORDS),
        onClickBind: props.bindGoogleAds,
        btnLoading: props.bindingGoogleAds.btnLoading,
        accounts: props.accountList.GOOG_ADWORDS.data,
        loading: props.accountList.GOOG_ADWORDS.loading,
      },
    ],
    performance: [
      {
        icon: ICON_GOOGLE_ANALYTIC,
        type: 'Google Analytics',
        asset: get(props.assetList, GOOG_ANALYTICS),
        onClickBind: props.bindGoogleAnalytics,
        btnLoading: props.bindingGoogleAnalytics.btnLoading,
        accounts: props.accountList.GOOG_ANALYTICS.data,
        loading: props.accountList.GOOG_ANALYTICS.loading,
      },
    ],
    social: [
      {
        icon: ICON_INSTAGRAM,
        type: 'Instagram',
        asset: get(props.assetList, FB_INSTAGRAM),
        onClickBind: props.bindFbInstagram,
        btnLoading: props.bindingInstagram.btnLoading,
        accounts: props.accountList.FB_INSTAGRAM.data,
        loading: props.accountList.FB_INSTAGRAM.loading,
      },
    ],
  }));

  renderCardBindings = (account) => (
    <Col xl={6} md={12} xs={24} key={account.type}>
      <CardBinding
        icon={account.icon}
        type={account.type}
        onClickBind={account.onClickBind}
        onClickUnbind={this.props.unbindAccount}
        btnLoading={account.btnLoading}
        accounts={account.accounts}
        loading={account.loading}
        asset={account.asset}
      />
    </Col>
  );

  _onSaveAccount = (accountType, assetId, selectedAccounts) => {
    this.props
      .saveAccounts(accountType, assetId, selectedAccounts)
      .then(() => this.props.getBindedAccounts(accountType));
  };

  _onSaveTokopediaAccount = (payload) => {
    this.props
      .saveMarketplaceAccount(TOKOPEDIA, payload)
      .then(() => this.props.getBindedAccounts(TOKOPEDIA));
  };

  _onSaveShopifyAccount = (payload) => {
    const formPayload = {
      ...payload,
      shop_link: payload.shop_link
        .replace('http://', '')
        .replace('https://', ''),
    };
    this.props
      .saveMarketplaceAccount(SHOPIFY, formPayload)
      .then(() => this.props.getBindedAccounts(SHOPIFY));
  };

  renderBindingModal = () => {
    const {
      bindingFacebook,
      bindingGoogleAds,
      bindingGoogleAnalytics,
      bindingTokopedia,
      bindingInstagram,
      bindingShopee,
      bindingShopify,
      selectAccountModalVisible,
      currentAccountType,
    } = this.props;

    const DATA_SOURCE = {
      FB_ADS_INSIGHT: bindingFacebook,
      GOOG_ADWORDS: bindingGoogleAds,
      GOOG_ANALYTICS: bindingGoogleAnalytics,
      FB_INSTAGRAM: bindingInstagram,
      TOKOPEDIA: bindingTokopedia,
      SHOPEE: bindingShopee,
      SHOPIFY: bindingShopify,
    };

    if (currentAccountType === TOKOPEDIA) {
      return (
        <ModalBindingTokopedia
          visible={selectAccountModalVisible}
          onConfirm={this._onSaveTokopediaAccount}
          loading={get(DATA_SOURCE, `${currentAccountType}.btnLoading`)}
        />
      );
    }
    if (currentAccountType === SHOPIFY) {
      return (
        <ModalBindingShopify
          visible={selectAccountModalVisible}
          onConfirm={this._onSaveShopifyAccount}
          loading={get(DATA_SOURCE, `${currentAccountType}.btnLoading`)}
        />
      );
    }

    return (
      <ModalBinding
        visible={selectAccountModalVisible}
        data={get(DATA_SOURCE, `${currentAccountType}.data`)}
        accountType={currentAccountType}
        onConfirm={this._onSaveAccount}
        maxAccount={currentAccountType === FB_ADS_INSIGHT ? 3 : 1}
        loading={get(DATA_SOURCE, `${currentAccountType}.btnLoading`)}
      />
    );
  };

  render() {
    return (
      <DashboardLayout
        title="Account Binding"
        withPeriod={false}
        location={this.props.location}
      >
        <Tabs
          type="card"
          activeKey={this.state.selectedTab}
          onChange={(selectedTab) => {
            this.setState({ selectedTab });
          }}
        >
          <TabPane tab="Marketplace" key="1">
            <Row gutter={24}>
              {this.bindingConfig(this.props).marketplace.map(
                this.renderCardBindings,
              )}
            </Row>
          </TabPane>
          <TabPane tab="Marketing" key="2">
            <Row gutter={24}>
              {this.bindingConfig(this.props).marketing.map(
                this.renderCardBindings,
              )}
            </Row>
          </TabPane>
          <TabPane tab="Performance" key="3">
            <Row gutter={24}>
              {this.bindingConfig(this.props).performance.map(
                this.renderCardBindings,
              )}
            </Row>
          </TabPane>
          <TabPane tab="Social Media" key="4">
            <Row gutter={24}>
              {this.bindingConfig(this.props).social.map(
                this.renderCardBindings,
              )}
            </Row>
          </TabPane>
        </Tabs>

        {this.renderBindingModal()}
      </DashboardLayout>
    );
  }
}

AccountBinding.displayName = config.displayName;
AccountBinding.defaultProps = config.defaultProps;
AccountBinding.propTypes = config.propTypes;

export default AccountBinding;
