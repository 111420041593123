import React from 'react';

import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import variablePie from 'highcharts/modules/variable-pie';
import HighchartsReact from 'highcharts-react-official';

import config from './DonutThicknessChart.component.config';
import { COLORS } from '../../../constants/variables';

NoData(Highcharts);
variablePie(Highcharts);

const DonutThicknessChart = ({
  chartData,
  chartSize,
  withWidth,
  showLabel,
}) => {
  const dataSeries = chartData.map((item) => ({
    name: item.name,
    y: item.value,
    z: item.value,
    color: item.color,
    dataLabels: {
      borderColor: item.color,
    },
  }));

  const defaultHeight = window.IS_IN_MOBILE ? 130 : 160;

  const chartMobile = {
    type: 'variablepie',
    height: chartSize * 1.2 || defaultHeight,
    width: withWidth ? window.innerWidth * 0.65 : null,
  };

  const chartDesktop = {
    type: 'variablepie',
    height: chartSize * 1.2 || defaultHeight,
  };

  const options = {
    chart: window.IS_IN_MOBILE ? chartMobile : chartDesktop,
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    lang: {
      noData: 'No Data',
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      backgroundColor: COLORS['dark-background'],
      borderRadius: 16,
      style: {
        color: '#fff',
      },
      pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)',
    },
    plotOptions: {
      series: {
        enableMouseTracking: true,
      },
      variablepie: {
        size: chartSize || `${defaultHeight - 30}px`,
        dataLabels: {
          alignTo: 'connectors',
          enabled: showLabel,
          format: '<br/>{point.percentage:.1f}%',
          borderWidth: 1,
          connectorPadding: 0,
          borderRadius: 4,
          padding: 4,
        },
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: '50%',
        colorByPoint: true,
        data: dataSeries,
      },
    ],
  };

  return (
    <div id="donutThicknessChart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

DonutThicknessChart.displayName = config.displayName;
DonutThicknessChart.defaultProps = config.defaultProps;
DonutThicknessChart.propTypes = config.propTypes;

export default DonutThicknessChart;
