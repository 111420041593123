import PropTypes from 'prop-types';

const displayName = 'CardData';
const propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  extraText: PropTypes.string,
  config: PropTypes.object,
};
const defaultProps = {
  icon: '',
  color: '',
  title: '',
  backgroundColor: '',
  data: {},
  loading: false,
  extraText: '',
  config: {
    loadingClass: '',
  },
};

export default { displayName, propTypes, defaultProps };
