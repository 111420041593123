import React, { useEffect, useState } from 'react';

import { Typography, Button, Modal, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';

import { SET_BINDING_MODAL_VISIBLE } from '../../redux/types';

import config from './ModalBindingTokopedia.component.config';
import './ModalBindingTokopedia.component.styles.less';

const { Title } = Typography;
const ModalBindingTokopedia = ({ visible, onConfirm, loading }) => {
  const dispatch = useDispatch();
  const [elementHeight, setElementHeight] = useState(0);
  useEffect(() => {
    setElementHeight(
      window.innerHeight -
        document.getElementsByClassName('ant-modal-body')[0].clientHeight,
    );
  }, [visible]);

  return (
    <Modal
      visible={visible}
      footer={null}
      style={window.IS_IN_MOBILE ? { top: elementHeight } : {}}
      className="modal-tokopedia"
      closeIcon={<i className="icon-close" />}
      onCancel={() => {
        dispatch({
          type: SET_BINDING_MODAL_VISIBLE,
          isVisible: false,
        });
      }}
    >
      <div id="modal-binding-tokopedia">
        <Title level={4}>Add your Tokopedia account here</Title>
        <Form
          className="form"
          layout="vertical"
          name="profile"
          onFinish={onConfirm}
        >
          <Form.Item
            label="Marketplace Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Marketplace Name is required',
              },
            ]}
          >
            <Input
              placeholder="Marketplace Name"
              autoComplete="off"
              className="input-text"
            />
          </Form.Item>

          <Form.Item
            label="Marketplace Account ID"
            name="shop_id"
            rules={[
              {
                required: true,
                message: 'Marketplace Account ID is required',
              },
            ]}
          >
            <Input
              placeholder="Marketplace Account ID"
              autoComplete="off"
              className="input-text"
            />
          </Form.Item>

          <div className="row-btn">
            <Button loading={loading} type="primary" htmlType="submit">
              Binding Tokopedia
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

ModalBindingTokopedia.displayName = config.displayName;
ModalBindingTokopedia.defaultProps = config.defaultProps;
ModalBindingTokopedia.propTypes = config.propTypes;

export default ModalBindingTokopedia;
