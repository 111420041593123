/* eslint-disable no-unused-vars */
import {
  subDays,
  subMonths,
  startOfMonth,
  endOfMonth,
  subQuarters,
  startOfQuarter,
  endOfQuarter,
  isSameMonth,
  isSameYear,
  format,
} from 'date-fns';

import {
  INDUSTRY_APPAREL_AND_FOOTWEAR,
  INDUSTRY_BEAUTY_AND_PERSONAL_CARE,
  INDUSTRY_CONSUMER_ELECTRONICS,
  INDUSTRY_FOOD_AND_BEVERAGES,
  INDUSTRY_FINANCIAL_SERVICES,
  INDUSTRY_HOME,
  INDUSTRY_JEWELLERY_AND_ACCESSORIES,
  INDUSTRY_MEDICAL_AND_HEALTHCARE,
  INDUSTRY_OTHERS_INDUSTRY,
} from '../assets/images';

export const FB_ADS_INSIGHT = 'FB_ADS_INSIGHT';
export const GOOG_ADWORDS = 'GOOG_ADWORDS';
export const GOOG_ANALYTICS = 'GOOG_ANALYTICS';
export const FB_INSTAGRAM = 'FB_INSTAGRAM';
export const TOKOPEDIA = 'TOKOPEDIA';
export const SHOPEE = 'SHOPEE';
export const SHOPIFY = 'SHOPIFY';
export const GOOG_YOUTUBE = 'GOOG_YOUTUBE';
export const FB_MARKETPLACE = 'FB_MARKETPLACE';
export const FB_ANALYTICS = 'FB_ANALYTICS';
export const FB_PAGE = 'FB_PAGE';
export const TIKTOK_ADS = 'TIKTOK_ADS';

export const INDUSTRY_LISTS = [
  {
    image: INDUSTRY_APPAREL_AND_FOOTWEAR,
    key: 'APPAREL_AND_FOOTWEAR',
    label: 'Apparel and Footwear',
  },
  {
    image: INDUSTRY_BEAUTY_AND_PERSONAL_CARE,
    key: 'BEAUTY_AND_PERSONAL_CARE',
    label: 'Beauty and Personal Care',
  },
  {
    image: INDUSTRY_CONSUMER_ELECTRONICS,
    key: 'CONSUMER_ELECTRONICS',
    label: 'Consumer Electronics',
  },
  {
    image: INDUSTRY_FOOD_AND_BEVERAGES,
    key: 'FOOD_AND_BEVERAGES',
    label: 'Food and Beverages',
  },
  {
    image: INDUSTRY_FINANCIAL_SERVICES,
    key: 'FINANCIAL_SERVICES',
    label: 'Financial Services',
  },
  {
    image: INDUSTRY_HOME,
    key: 'HOME',
    label: 'Home, Garden and Kitchen',
  },
  {
    image: INDUSTRY_JEWELLERY_AND_ACCESSORIES,
    key: 'JEWELLERY_AND_ACCESSORIES',
    label: 'Jewellery and Accessories',
  },
  {
    image: INDUSTRY_MEDICAL_AND_HEALTHCARE,
    key: 'MEDICAL_AND_HEALTHCARE',
    label: 'Medical and Healthcare',
  },
  {
    image: INDUSTRY_OTHERS_INDUSTRY,
    key: 'OTHERS_INDUSTRY',
    label: 'Others Industry',
  },
];

const today = new Date();
const compareLast7DayDate = () => {
  const start = subDays(today, 14);
  const end = subDays(today, 8);

  return isSameMonth(start, end)
    ? `${format(start, 'd')} to ${format(end, 'd MMM yyyy')}`
    : `${format(start, 'd MMM')} to ${format(end, 'd MMM yyyy')}`;
};
const compareLastMonth = () => {
  const initDate = subMonths(today, 2);
  const start = startOfMonth(initDate);
  const end = endOfMonth(initDate);

  return isSameYear(start, initDate)
    ? `${format(start, 'd')} to ${format(end, 'd MMM')}`
    : `${format(start, 'd')} to ${format(end, 'd MMM yyyy')}`;
};
const compareLastQuarter = () => {
  const initDate = subQuarters(today, 2);
  const start = startOfQuarter(initDate);
  const end = endOfQuarter(initDate);

  return `${format(start, 'd MMM')} to ${format(end, 'd MMM yyyy')}`;
};

export const PERIODS = {
  TODAY: { label: 'Today', compareLabel: 'Yesterday' },
  YESTERDAY: {
    label: 'Yesterday',
    compareLabel: format(subDays(today, 2), 'd MMM yyyy'),
  },
  LAST_7_DAYS: {
    label: 'Last 7 days',
    compareLabel: compareLast7DayDate(),
  },
  THIS_WEEK: { label: 'This Week', compareLabel: 'Last week' },
  CURRENT_MONTH: { label: 'Current Month', compareLabel: 'Last month' },
  LAST_MONTH: {
    label: 'Last Month',
    compareLabel: compareLastMonth(),
  },
  THIS_QUARTER: { label: 'This Quarter', compareLabel: 'Last quarter' },
  LAST_QUARTER: { label: 'Last Quarter', compareLabel: compareLastQuarter() },
};

export const COLORS = {
  primary: '#1f36c1',
  secondary: '#ef7752',
  list: [
    '#1f36c1',
    '#ef7752',
    '#2E81FF',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  'dark-background': '#000F43',
  green: '#2CBE55',
  bgGreen: '#D8FFE3',
  red: '#EA4E4E',
  bgRed: '#FFE5E5',
  blue: '#2188E8',
  bgBlue: '#E4F2FF',
  yellow: '#FA9600',
  bgYellow: '#FFEBCD',
  gray: '#9b9b9b',
  passive: '#E6E6E6',
};
