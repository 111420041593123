import PropTypes from 'prop-types';
import { CHART_LINE_EXAMPLE } from '../../constants/fixtures/chart.fixtures';

const displayName = 'CardLineChart';
const propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  chartConfig: PropTypes.object,
};
const defaultProps = {
  title: '',
  data: CHART_LINE_EXAMPLE,
  loading: false,
  chartConfig: {
    hasLegend: true,
    config: {
      hideYAxis: true,
    },
  },
};

export default { displayName, propTypes, defaultProps };
