import { connect } from 'react-redux';
import Dashboard from './Dashboard.component';

import {
  getUserData,
  setTemporaryUser,
} from '../../redux/actions/account.actions';
import { getBindedAccounts } from '../../redux/actions/binding.actions';

const mapStateToProps = (state) => ({
  me: state.account.me,
});
const mapDispatchToProps = { getUserData, setTemporaryUser, getBindedAccounts };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
