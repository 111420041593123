import PropTypes from 'prop-types';

const displayName = 'ModalBindingTokopedia';
const propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};
const defaultProps = {
  visible: false,
};

export default { displayName, propTypes, defaultProps };
