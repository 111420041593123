import PropTypes from 'prop-types';

const displayName = 'Dashboard';
const propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  getUserData: PropTypes.func,
  getBindedAccounts: PropTypes.func,
  loading: PropTypes.bool,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
