import { gql } from '@apollo/client';

export const getStatusAggregation = gql`
  query getStatusAggregation {
    aggregationStatusCompleted {
      facebook
    }
  }
`;

export const tableCampaign = gql`
  query tableCampaign($interval: Interval!, $accountId: [String]!) {
    fbAdsCollaborative(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      campaignName
      spend
      roas
    }
  }
`;

export const reachImpression = gql`
  query reachImpression($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      reach
      impressions
      frequency
    }
  }
`;

export const linkCpm = gql`
  query linkCpm($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      actionsLinkClick
      cpm
    }
  }
`;

export const ctrCpc = gql`
  query ctrCpc($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      ctr
      cpc
    }
  }
`;

export const chartATC = gql`
  query chartATC($interval: Interval!, $accountId: [String]!) {
    fbAdsCollaborative(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      indexTime
      numberOfTotalAddToCart
      numberOfTotalPurchase
    }
  }
`;

export const chartAtcCost = gql`
  query chartAtcCost($interval: Interval!, $accountId: [String]!) {
    fbAdsCollaborative(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      indexTime
      costPerAddToCart
      costPerPurchase
    }
  }
`;

export const chartAtcValue = gql`
  query chartAtcValue($interval: Interval!, $accountId: [String]!) {
    fbAdsCollaborative(
      paramIn: { interval: $interval, accountId: $accountId }
    ) {
      indexTime
      addToCartValue
      purchaseValue
    }
  }
`;

export const lpvContent = gql`
  query lpvContent($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      actionsLandingPageView
      catalogSegmentActionsOmniViewContent
    }
  }
`;

export const shareComment = gql`
  query shareComment($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      actionsPost
      actionsComment
    }
  }
`;

export const engagementSaved = gql`
  query engagementSaved($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      actionsPostEngagement
      actionsOnsiteConversionPostSave
    }
  }
`;

export const chartOutboundClicks = gql`
  query chartOutboundClicks($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      indexTime
      clicks
      outboundClicksOutboundClick
    }
  }
`;

export const appInstall = gql`
  query appInstall($interval: Interval!, $accountId: [String]!) {
    fbAdsInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      actionsAppInstall
      costPerActionTypeAppInstall
    }
  }
`;
