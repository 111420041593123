import PropTypes from 'prop-types';

const displayName = 'DonutChart';
const propTypes = {
  chartData: PropTypes.array.isRequired,
  chartSize: PropTypes.number,
};
const defaultProps = {
  chartData: [],
  chartSize: 86,
  title: '',
  config: {
    titleFontSize: 14,
    thickness: 10,
    showPoint: false,
  },
};

export default { displayName, propTypes, defaultProps };
