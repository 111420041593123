import React from 'react';
import { Typography, Button, Form, Input } from 'antd';

import { LandingLayout } from '../../components/Layout';
import { ModalConfirmation } from '../../components';
import { ICON_SENT } from '../../assets/icons';

import config from './ForgotPassword.component.config';
import './ForgotPassword.component.styles.less';

const { Title, Text } = Typography;
class ForgotPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmVisible: false,
    };
  }

  _onResetPassword = (value) => {
    this.props.resetPassword(value).then(() => {
      this.setState({ confirmVisible: true });
    });
  };

  renderForgotPasswordForm = () => (
    <Form
      className="form"
      layout="vertical"
      name="forgot-password"
      onFinish={this._onResetPassword}
    >
      <Form.Item>
        <Text>Please enter your email to get new password</Text>
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'email is required, please insert your email',
          },
          {
            type: 'email',
            message: 'email is not valid',
          },
        ]}
      >
        <Input
          placeholder="you@example.com"
          autoComplete="off"
          className="input-text"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
          className="submit-btn"
          loading={this.props.loadingEmail}
        >
          Send
        </Button>
      </Form.Item>
    </Form>
  );

  onConfirmModal = () => {
    this.setState({ confirmVisible: false });
  };

  render() {
    return (
      <LandingLayout id="forgot-password-page">
        <Title level={3}>
          <i
            className="icon-back"
            onClick={() => {
              this.props.history.goBack();
            }}
          />
          Forgot Password
        </Title>
        {this.renderForgotPasswordForm()}
        <ModalConfirmation
          visible={this.state.confirmVisible}
          config={{
            icon: ICON_SENT,
            title: 'Password baru sudah dikirim',
            description:
              'Silakan gunakan password yang sudah dikirim ke email kamu untuk proses login',
            btnConfirmText: 'OK',
          }}
          onClose={this.onConfirmModal}
          onConfirm={this.onConfirmModal}
        />
      </LandingLayout>
    );
  }
}

ForgotPasswordPage.displayName = config.displayName;
ForgotPasswordPage.defaultProps = config.defaultProps;
ForgotPasswordPage.propTypes = config.propTypes;

export default ForgotPasswordPage;
