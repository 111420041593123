import PropTypes from 'prop-types';

const displayName = 'ForgotPassword';
const propTypes = {
  history: PropTypes.object,
  resetPassword: PropTypes.func,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
