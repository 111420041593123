import React, { useState, useEffect } from 'react';
import { findIndex, get } from 'lodash';
import { Typography, Button, Modal, Avatar, Checkbox, Radio } from 'antd';
import { useDispatch } from 'react-redux';

import { formatAvatar } from '../../utils/string';
import { SET_BINDING_MODAL_VISIBLE } from '../../redux/types';

import config from './ModalBinding.component.config';
import './ModalBinding.component.styles.less';
import {
  FB_ADS_INSIGHT,
  GOOG_ADWORDS,
  GOOG_ANALYTICS,
  FB_INSTAGRAM,
} from '../../constants/variables';

const { Title } = Typography;

const BINDING_STATE = {
  SELECT: 'SELECT',
  CONFIRM: 'CONFIRM',
};

const ModalBinding = ({ visible, data, onConfirm, loading, accountType }) => {
  const dispatch = useDispatch();
  const adsAccounts = get(data, 'accounts', []);
  const assetId = get(data, 'asset_id');
  const [selectedAccountIndex, setSelectedAccountIndex] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [bindingState, setBindingState] = useState(BINDING_STATE.SELECT);
  const [countRender, setCountRender] = useState(0);
  const [maxAccount, setMaxAccount] = useState(1);
  const [elementHeight, setElementHeight] = useState(0);

  const isBindFacebook = accountType === FB_ADS_INSIGHT;

  const callculateHeight = () => {
    setElementHeight(
      window.innerHeight -
        get(
          document.getElementsByClassName('ant-modal-body'),
          `[0].clientHeight`,
          0,
        ),
    );
    setCountRender(countRender + 1);
  };

  useEffect(() => {
    callculateHeight();
    if (!visible) {
      setBindingState(BINDING_STATE.SELECT);
    }
  }, [visible]);

  useEffect(() => {
    callculateHeight();
  }, [bindingState]);

  useEffect(() => {
    const maxAccountLength = accountType === FB_ADS_INSIGHT ? 3 : 1;
    setMaxAccount(maxAccountLength);
  }, [accountType]);

  const renderAccountRowRadio = (account) => (
    <Radio value={account} key={account.account_id}>
      <div className="row-account-left">
        <Avatar shape="circle" size="small">
          {formatAvatar(account.account_name)}
        </Avatar>
        <Typography>{account.account_name}</Typography>
      </div>
    </Radio>
  );

  const renderAccountRow = (account, index) => (
    <div className="row-account" key={account.id}>
      <div className="row-account-left">
        <Avatar shape="circle" size="small">
          {formatAvatar(account.account_name)}
        </Avatar>
        <Typography>{account.account_name}</Typography>
      </div>

      <Checkbox
        disabled={
          selectedAccounts.length === maxAccount &&
          findIndex(selectedAccountIndex, (item) => item === index) < 0
        }
        checked={findIndex(selectedAccountIndex, (item) => item === index) >= 0}
        onChange={({ target: { checked } }) => {
          const temporarySelectedAccounts = [...selectedAccounts];
          const temporarySelectedAccountIndex = [...selectedAccountIndex];
          if (checked) {
            temporarySelectedAccounts.push(account);
            temporarySelectedAccountIndex.push(index);
          } else {
            const currentIndex = findIndex(
              temporarySelectedAccounts,
              (item) => item.account_id === account.account_id,
            );

            temporarySelectedAccounts.splice(currentIndex, 1);
            temporarySelectedAccountIndex.splice(currentIndex, 1);
          }

          setSelectedAccounts(temporarySelectedAccounts);
          setSelectedAccountIndex(temporarySelectedAccountIndex);
        }}
      />
    </div>
  );

  const renderAccountList = () => {
    if (maxAccount === 1) {
      return (
        <Radio.Group
          onChange={({ target: { value } }) => {
            setSelectedAccounts([value]);
          }}
        >
          {adsAccounts.map(renderAccountRowRadio)}
        </Radio.Group>
      );
    }
    return adsAccounts.map(renderAccountRow);
  };

  const _getAccountType = () => {
    switch (accountType) {
      case FB_INSTAGRAM:
        return 'Instagram';
      case GOOG_ANALYTICS:
        return 'Google Analytics';
      case GOOG_ADWORDS:
        return 'Google Ads';
      case FB_ADS_INSIGHT:
      default:
        return 'Facebook Ads';
    }
  };

  const renderButtonSave = () => (
    <Button
      loading={loading}
      type="primary"
      disabled={selectedAccounts.length < 1}
      onClick={() => {
        onConfirm(accountType, assetId, selectedAccounts);
      }}
    >
      Save
    </Button>
  );

  const renderButtonSelectFb = () => (
    <Button
      type="primary"
      disabled={selectedAccounts.length < 1}
      onClick={() => {
        const newArray = selectedAccounts.map((account) => ({
          ...account,
          isCpas: false,
        }));
        setSelectedAccounts(newArray);
        setBindingState(BINDING_STATE.CONFIRM);
      }}
    >
      Next
    </Button>
  );

  const renderButtonConfirmFb = () => (
    <div className="row-button">
      <Button
        type="default"
        disabled={selectedAccounts.length < 1}
        onClick={() => {
          setBindingState(BINDING_STATE.SELECT);
        }}
      >
        Back
      </Button>

      <Button
        loading={loading}
        type="primary"
        disabled={selectedAccounts.length < 1}
        onClick={() => {
          const selectedAccountsFb = selectedAccounts.map((account) => ({
            account_id: account.account_id,
            account_name: account.account_name,
            account_type: account.isCpas ? 'CPAS' : 'NON_CPAS',
          }));
          onConfirm(accountType, assetId, selectedAccountsFb);
        }}
      >
        Binding
      </Button>
    </div>
  );

  const renderButtonBindFb = () =>
    bindingState === BINDING_STATE.SELECT
      ? renderButtonSelectFb()
      : renderButtonConfirmFb();

  const renderAccountListSelect = () => (
    <div className="account-list">{renderAccountList()}</div>
  );

  const renderAccountRowConfirm = (account, index) => (
    <div className="row-confirm" key={account.id}>
      <div className="row-account-confirm">
        <div className="row-account-left">
          <Avatar shape="circle" size="small">
            {formatAvatar(account.account_name)}
          </Avatar>
          <Typography>{account.account_name}</Typography>
        </div>

        <Checkbox
          checked={account.isCpas}
          onChange={({ target: { checked } }) => {
            const newArray = selectedAccounts;
            newArray[index].isCpas = checked;

            setSelectedAccounts(newArray);
            setCountRender(countRender + 1);
          }}
        />
      </div>

      <div className="row-account-desc">This is a Collaborative Ads</div>
    </div>
  );

  const renderAccountConfirm = () =>
    selectedAccounts.map(renderAccountRowConfirm);

  const renderAccountListConfirm = () => (
    <div className="account-list account-confirm">{renderAccountConfirm()}</div>
  );

  const renderAccountListFb = () =>
    bindingState === BINDING_STATE.SELECT
      ? renderAccountListSelect()
      : renderAccountListConfirm();

  const showConfirmFb =
    bindingState === BINDING_STATE.CONFIRM && isBindFacebook;

  const defaultModalTitle = `Choose your ${_getAccountType()} account that you want to bind ${
    isBindFacebook ? '(Max. 3 accounts)' : null
  }`;
  const modalTitle = showConfirmFb
    ? 'Do you run one or more Collaborative Ads in your Ads Account ?'
    : defaultModalTitle;

  return (
    <Modal
      visible={visible}
      footer={null}
      style={window.IS_IN_MOBILE ? { top: elementHeight } : {}}
      className="modal-binding"
      closeIcon={<i className="icon-close" />}
      onCancel={() => {
        dispatch({
          type: SET_BINDING_MODAL_VISIBLE,
          isVisible: false,
        });
      }}
    >
      <div id="modal-binding">
        {window.IS_IN_MOBILE && (
          <div style={{ opacity: 0, height: 0 }}>{countRender}</div>
        )}
        {/* above is uses to force rerender element  */}
        <Title level={4}>{modalTitle}</Title>
        {showConfirmFb && (
          <div className="info-desc">
            Tell us from the list of Ads Account below, which one is a
            Collaborative Ads. If you are not sure about this, leave it
            un-ticked
          </div>
        )}
        {isBindFacebook ? renderAccountListFb() : renderAccountListSelect()}
        {showConfirmFb && (
          <div className="info-row">
            Find out more about{' '}
            <a
              target="_blank"
              href="https://www.facebook.com/business/tools/collaborative-ads"
            >
              Collaborative Ads
            </a>
          </div>
        )}
        {isBindFacebook ? renderButtonBindFb() : renderButtonSave()}
      </div>
    </Modal>
  );
};

ModalBinding.displayName = config.displayName;
ModalBinding.defaultProps = config.defaultProps;
ModalBinding.propTypes = config.propTypes;

export default ModalBinding;
