import React from 'react';

import config from './Legend.component.config';
import './Legend.component.styles.less';

const Legend = ({ series }) => (
  <div id="legend">
    {series.map(({ color, name }) => (
      <div className="legend-item" key={name.replace(' ', '_').toLowerCase()}>
        <div className="point" style={{ backgroundColor: color }} />{' '}
        <span>{name}</span>
      </div>
    ))}
  </div>
);

Legend.displayName = config.displayName;
Legend.defaultProps = config.defaultProps;
Legend.propTypes = config.propTypes;

export default Legend;
