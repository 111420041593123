import {
  SET_GOOG_ANALYTICS_CONVERSION,
  SET_GOOG_ANALYTICS_PRODUCTS,
  SET_GOOG_ANALYTICS_TRAFFIC,
  SET_GOOG_ANALYTICS_CUSTOMER,
  SET_GOOG_ANALYTICS_LOCATION,
  SET_GOOG_ANALYTICS_DEVICE,
  SET_GOOG_ANALYTICS_LANDING,
} from '../../types';

const initialState = {
  chartConversion: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  listTopProducts: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
  chartTraffic: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
  chartCustomer: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  listTopLocation: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
  listTopDevice: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
  listTopLanding: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GOOG_ANALYTICS_CONVERSION:
      return {
        ...state,
        chartConversion: {
          ...state.chartConversion,
          data: {
            ...state.chartConversion.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ANALYTICS_PRODUCTS:
      return {
        ...state,
        listTopProducts: {
          ...state.listTopProducts,
          data: {
            ...state.listTopProducts.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ANALYTICS_TRAFFIC:
      return {
        ...state,
        chartTraffic: {
          ...state.chartTraffic,
          data: {
            ...state.chartTraffic.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ANALYTICS_CUSTOMER:
      return {
        ...state,
        chartCustomer: {
          ...state.chartCustomer,
          data: {
            ...state.chartCustomer.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ANALYTICS_LOCATION:
      return {
        ...state,
        listTopLocation: {
          ...state.listTopLocation,
          data: {
            ...state.listTopLocation.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ANALYTICS_DEVICE:
      return {
        ...state,
        listTopDevice: {
          ...state.listTopDevice,
          data: {
            ...state.listTopDevice.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ANALYTICS_LANDING:
      return {
        ...state,
        listTopLanding: {
          ...state.listTopLanding,
          data: {
            ...state.listTopLanding.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    default:
      return state;
  }
};
