import PropTypes from 'prop-types';

const displayName = 'SideMenuItemDashboard';

const propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
const defaultProps = {
  className: '',
};

export default {
  displayName,
  propTypes,
  defaultProps,
};
