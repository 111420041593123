import React from 'react';
import Lottie from 'lottie-react';

import config from './Loading.component.config';
import './Loading.component.styles.less';
import animationData from '../../assets/loading.json';

const Loading = ({ size, style, text }) => (
  <div className="loadingWrapper" style={style}>
    <div style={{ height: size, width: size }}>
      <Lottie animationData={animationData} />
    </div>
    {text}
  </div>
);

Loading.displayName = config.displayName;
Loading.defaultProps = config.defaultProps;
Loading.propTypes = config.propTypes;

export default Loading;
