import { connect } from 'react-redux';
import Summary from './Summary.component';
import {
  getHome,
  getAggregatedStatus,
} from '../../redux/actions/dashboard/home.actions';
import { setIntroDone } from '../../redux/actions/account.actions';

const mapStateToProps = (state) => ({
  isFirstTime: state.account.me.isFirstTime.summary,
  accounts: state.binding.accountList,
  homeData: state.home,
});

const mapDispatchToProps = {
  getHome,
  getAggregatedStatus,
  setIntroDone,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
