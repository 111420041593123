import React from 'react';
import { Card, Row, Col, Typography } from 'antd';

import { DonutChart } from '../Charts';
import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardDonutChart.component.config';
import './CardDonutChart.component.styles.less';
import { formatNumber } from '../../utils/string';

const { Title, Text } = Typography;
const CardDonutChart = ({ title, data, isUnbind, loading }) => {
  const renderWithData = () => (
    <Row>
      <Col span={10} className="col-legend">
        <Title level={2}>{data.total}</Title>
        <div className="legend">
          {data.dataList.map((item) => (
            <div className="legend-item">
              <div className="point-name">
                <div
                  className="point"
                  style={{ backgroundColor: item.hexColor }}
                />
                <Text className="label">{item.label}</Text>
              </div>
              <Text className="value">{formatNumber(item.data)}</Text>
            </div>
          ))}
        </div>
      </Col>
      <Col span={14} className="col-chart">
        <DonutChart
          chartData={data.dataList}
          title={data.centerValue}
          chartSize={140}
          config={{ titleFontSize: 12, thickness: 15, showPoint: true }}
        />
      </Col>
    </Row>
  );

  if (loading) {
    return (
      <Card title={title} bordered={false} id="card-donut-chart-loading">
        <Row>
          <Col span={10} className="col-left">
            <div>
              <div className="total loading" />
              <div className="description loading" />
              <div className="description loading" />
            </div>
          </Col>
          <Col span={14} className="col-right">
            <div className="donut-chart loading" />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card title={title} bordered={false} id="card-donut-chart">
      {isUnbind ? <UnbindedData title={title} center /> : renderWithData()}
    </Card>
  );
};

CardDonutChart.displayName = config.displayName;
CardDonutChart.defaultProps = config.defaultProps;
CardDonutChart.propTypes = config.propTypes;

export default CardDonutChart;
