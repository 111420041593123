import { gql } from '@apollo/client';

export const getStatusAggregation = gql`
  query getStatusAggregation {
    aggregationStatusCompleted {
      shopee
      shopify
      tokopedia
    }
  }
`;

export const getTotalOrder = gql`
  query getTotalOrder(
    $interval: Interval!
    $channel: [MarketplaceChannel]!
    $category: [OrderStatus]!
  ) {
    marketplaceOrders(
      paramIn: { interval: $interval, channel: $channel, category: $category }
    ) {
      status
      totalNumber
      totalAmount
    }
  }
`;

export const getSummaryTotal = gql`
  query getSummaryTotal($interval: Interval!, $metric: MetricIndustry!) {
    summaryAnalytics(paramIn: { interval: $interval, metric: $metric }) {
      selfValue
    }
  }
`;

export const getVersusIndustry = gql`
  query getVersusIndustry($interval: Interval!, $metric: MetricIndustry!) {
    versusIndustry(paramIn: { interval: $interval, metric: $metric }) {
      percentageValue
    }
  }
`;

// summaryAnalytics(paramIn:{
//   interval: THIS_WEEK
//   metric: CPA
// }){
//   selfValue
// }
