import PropTypes from 'prop-types';

const displayName = 'CardTab';
const propTypes = {
  title: PropTypes.string,
  tabConfig: PropTypes.array,
};
const defaultProps = {
  title: '',
  tabConfig: [
    {
      title: '',
      key: '',
      content: '',
    },
  ],
};

export default { displayName, propTypes, defaultProps };
