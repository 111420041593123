import PropTypes from 'prop-types';

const displayName = 'AccountBinding';
const propTypes = {
  bindFbAdsAccount: PropTypes.func,
  bindFbInstagram: PropTypes.func,
  saveAccounts: PropTypes.func,
  bindGoogleAds: PropTypes.func,
  bindGoogleAnalytics: PropTypes.func,
  getGoogleAccounts: PropTypes.func,
  bindTokopedia: PropTypes.func,
  bindShopify: PropTypes.func,
  getBindedAccounts: PropTypes.func,
  unbindAccount: PropTypes.func,
  bindShopee: PropTypes.func,
  saveMarketplaceAccount: PropTypes.func,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
