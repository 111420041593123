import PropTypes from 'prop-types';

const displayName = 'CardHorizontalBarChart';
const propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  isUnbind: PropTypes.bool,
};
const defaultProps = {
  title: '',
  data: {},
  loading: false,
};

export default { displayName, propTypes, defaultProps };
