import { gql } from '@apollo/client';

export const chartConversion = gql`
  query chartConversion($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      indexTime
      transactionsPerSession
    }
  }
`;

export const listTopProduct = gql`
  query listTopProduct($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      productName
      itemQuantity
    }
  }
`;

export const chartTraficSource = gql`
  query chartTraficSource($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      sourceMedium
      sessions
    }
  }
`;

export const chartCustomerRate = gql`
  query chartTraficSource($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      indexTime
      userType
      users
    }
  }
`;

export const listTopLocation = gql`
  query listTopLocation($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      country
      sessions
    }
  }
`;

export const listTopDevice = gql`
  query listTopDevice($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      deviceCategory
      sessions
    }
  }
`;

export const listTopLanding = gql`
  query listTopLanding($interval: Interval!, $accountId: String!) {
    googleGaAnalytics(paramIn: { interval: $interval, accountId: $accountId }) {
      landingPagePath
      sessions
    }
  }
`;
