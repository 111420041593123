/* eslint-disable no-restricted-globals */
import { format } from 'date-fns';
import _ from 'lodash';
import get from 'lodash/get';

export const getParamURL = (param) => {
  const rawUrl = window.location.search;

  const objectURL = _.chain(rawUrl)
    .replace('?', '')
    .split('&')
    .map(_.partial(_.split, _, '=', 2))
    .fromPairs()
    .value();

  const getParam = objectURL[param] || '';

  return getParam;
};

const getLabelDate = (dateValue, isShowMonth) =>
  format(new Date(dateValue), isShowMonth ? 'MMM' : 'dd MMM');

export const mapChartData = (period) => (
  key,
  collections,
  calculateTotal,
  formater,
  decimal,
) => {
  const data = [];
  const xLabels = [];
  let total = 0;

  const isShowMonth = period === 'THIS_QUARTER' || period === 'LAST_QUARTER';

  collections.forEach((item) => {
    if (calculateTotal && decimal) {
      total += parseFloat(get(item, key, '0'));
    } else if (calculateTotal) total += parseInt(get(item, key, '0'), 10);
    data.push(item.value || get(item, key));
    xLabels.push(item.label || getLabelDate(item.indexTime, isShowMonth));
  });

  const result = {
    data,
    xLabels,
  };
  if (calculateTotal) {
    result.total = formater(total, null, decimal);
    if (calculateTotal === 'average') {
      result.total = formater(total / data.length, null, decimal);
    }
  }

  return result;
};

export const calculateDiff = (value, prev) => {
  const currentValue = Number(value);
  const compareValue = Number(prev);
  const divider = compareValue > 0 ? compareValue : 1;

  const diff = ((currentValue - compareValue) / divider) * 100;
  const decimalLength = diff < 100 ? 2 : 0;
  return isNaN(diff) ? 0 : diff.toFixed(decimalLength);
};

export const getValueFromArray = (array, keyValue, keySearch, valueSearch) => {
  let result = 0;
  array.forEach((item) => {
    if (get(item, keySearch) === valueSearch) {
      result = get(item, keyValue);
    }
  });

  return result;
};
