import React from 'react';
import get from 'lodash/get';

import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';

import { COLORS } from '../../../constants/variables';
import Legend from '../Legend/Legend.component';

import config from './LineChart.component.config';
import './LineChart.component.styles.less';

NoData(Highcharts);

const LineChart = ({
  chartData,
  title,
  hasLegend,
  config: configChart,
  setRef,
}) => {
  const series = chartData.dataList.map((data) => ({
    name: data.label,
    data: data.data.map((item) =>
      Number(
        parseFloat((item || 0).toString().replace(/[^0-9.-]+/g, ',')).toFixed(
          2,
        ),
      ),
    ),
    color: data.hexColor,
    fillColor: {
      linearGradient: [0, 0, 0, 300],
      stops: [
        [0, Highcharts.color(data.hexColor).setOpacity(0.3).get('rgba')],
        [1, Highcharts.color(data.hexColor).setOpacity(0).get('rgba')],
      ],
    },
  }));

  const options = {
    chart: {
      type: 'spline',
      height: get(configChart, 'height') || 180,
      spacing: [10, 8, 8, 8],
    },
    title: {
      text: title,
      style: {
        fontSize: 12,
      },
    },
    lang: {
      noData: 'No Data to Display',
      decimalPoint: ',',
      thousandsSep: '.',
    },
    xAxis: {
      categories: chartData.xLabels,
      tickInterval: 2,
      labels: {
        enabled: !configChart.hideXAxis,
      },
      lineColor: '#9b9b9b',
      lineWidth: 1,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: !configChart.hideYAxis,
      },
      opposite: configChart.YLabelRight,
      gridLineColor: 'none',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      backgroundColor: COLORS['dark-background'],
      borderRadius: 16,
      style: {
        color: '#fff',
      },
      shape: 'callout',
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: configChart.hideYAxis,
          allowOverlap: false,
        },
        marker: {
          enabled: chartData.xLabels.length === 1,
          symbol: 'circle',
        },
      },
    },
    series,
  };

  const renderLegend = () => {
    if (chartData.total) {
      return <span className="total">{chartData.total}</span>;
    }
    if (hasLegend && chartData.dataList.length > 1) {
      return <Legend series={series} />;
    }
    return null;
  };

  return (
    <div id="line-chart">
      {renderLegend()}
      <HighchartsReact ref={setRef} highcharts={Highcharts} options={options} />
    </div>
  );
};
LineChart.displayName = config.displayName;
LineChart.defaultProps = config.defaultProps;
LineChart.propTypes = config.propTypes;

export default LineChart;
