// AUTH
export const SET_LOGIN_FACEBOOK = 'SET_LOGIN_FACEBOOK';
export const SET_LOGIN_GOOGLE = 'SET_LOGIN_GOOGLE';
export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL';
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD';
export const SET_REGISTER = 'SET_REGISTER';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_INTRO = 'SET_USER_INTRO';

// GENERAL
export const SET_PERIOD = 'SET_PERIOD';
export const SET_KEYWORD = 'SET_KEYWORD';

// BINDING
export const SET_BINDING_MODAL_VISIBLE = 'SET_BINDING_MODAL_VISIBLE';
export const SET_BINDING_FB_ADS_INSIGHT = 'SET_BINDING_FB_ADS_INSIGHT';
export const SET_BINDING_GOOG_ADWORDS = 'SET_BINDING_GOOG_ADWORDS';
export const SET_BINDING_GOOG_ANALYTICS = 'SET_BINDING_GOOG_ANALYTICS';
export const SET_BINDING_FB_INSTAGRAM = 'SET_BINDING_FB_INSTAGRAM';
export const SET_BINDING_TOKOPEDIA = 'SET_BINDING_TOKOPEDIA';
export const SET_BINDING_SHOPEE = 'SET_BINDING_SHOPEE';
export const SET_BINDING_SHOPIFY = 'SET_BINDING_SHOPIFY';

export const SET_ACCOUNTS_ALL = 'SET_BINDING_ACCOUNTS_ALL';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';

// FB_ADS_INSIGHT
export const SET_FB_ADS_CAMPAIGN = 'SET_FB_ADS_CAMPAIGN';
export const SET_FB_ADS_REACH = 'SET_FB_ADS_REACH';
export const SET_FB_ADS_IMPRESSIONS = 'SET_FB_ADS_IMPRESSIONS';
export const SET_FB_ADS_ATC = 'SET_FB_ADS_ATC';
export const SET_FB_ADS_ATC_COST = 'SET_FB_ADS_ATC_COST';
export const SET_FB_ADS_ATC_VALUE = 'SET_FB_ADS_ATC_VALUE';
export const SET_FB_ADS_CPC = 'SET_FB_ADS_CPC';
export const SET_FB_ADS_CPM = 'SET_FB_ADS_CPM';
export const SET_FB_ADS_LPV = 'SET_FB_ADS_LPV';
export const SET_FB_ADS_SHARE = 'SET_FB_ADS_SHARE';
export const SET_FB_ADS_ENGAGEMENT = 'SET_FB_ADS_ENGAGEMENT';
export const SET_FB_ADS_OUTBOUND = 'SET_FB_ADS_OUTBOUND';
export const SET_FB_ADS_INSTALL = 'SET_FB_ADS_INSTALL';
export const SET_AGGREGATED_FB_ADS = 'SET_AGGREGATED_FB_ADS';

// GOOG_ADWORDS
export const SET_GOOG_ADWORDS_CAMPAIGN = 'SET_GOOG_ADWORDS_CAMPAIGN';
export const SET_GOOG_ADWORDS_REACH = 'SET_GOOG_ADWORDS_REACH';
export const SET_GOOG_ADWORDS_IMPRESSIONS = 'SET_GOOG_ADWORDS_IMPRESSIONS';
export const SET_GOOG_ADWORDS_CPM = 'SET_GOOG_ADWORDS_CPM';
export const SET_GOOG_ADWORDS_CPC = 'SET_GOOG_ADWORDS_CPC';
export const SET_GOOG_ADWORDS_VIEWS = 'SET_GOOG_ADWORDS_VIEWS';
export const SET_GOOG_ADWORDS_CONVERSION = 'SET_GOOG_ADWORDS_CONVERSION';
export const SET_GOOG_ADWORDS_INTERACTION = 'SET_GOOG_ADWORDS_INTERACTION';
export const SET_GOOG_ADWORDS_IMPRESSION_TOP =
  'SET_GOOG_ADWORDS_IMPRESSION_TOP';

// GOOG_ANALYTICS
export const SET_GOOG_ANALYTICS_CONVERSION = 'SET_GOOG_ANALYTICS_CONVERSION';
export const SET_GOOG_ANALYTICS_PRODUCTS = 'SET_GOOG_ANALYTICS_PRODUCTS';
export const SET_GOOG_ANALYTICS_TRAFFIC = 'SET_GOOG_ANALYTICS_TRAFFIC';
export const SET_GOOG_ANALYTICS_CUSTOMER = 'SET_GOOG_ANALYTICS_CUSTOMER';
export const SET_GOOG_ANALYTICS_LOCATION = 'SET_GOOG_ANALYTICS_LOCATION';
export const SET_GOOG_ANALYTICS_DEVICE = 'SET_GOOG_ANALYTICS_DEVICE';
export const SET_GOOG_ANALYTICS_LANDING = 'SET_GOOG_ANALYTICS_LANDING';

// MARKETPLACE
export const SET_TOKOPEDIA_PRODUCT_AMOUNT = 'SET_TOKOPEDIA_PRODUCT_AMOUNT';
export const SET_TOKOPEDIA_PRODUCT_QTY = 'SET_TOKOPEDIA_PRODUCT_QUANTITY';
export const SET_TOKOPEDIA_TOTAL_ORDER = 'SET_TOKOPEDIA_TOTAL_ORDER';
export const SET_TOKOPEDIA_AVERAGE_ORDER = 'SET_TOKOPEDIA_AVERAGE_ORDER';
export const SET_AGGREGATED_TOKOPEDIA = 'SET_AGGREGATED_TOKOPEDIA';

export const SET_SHOPEE_PRODUCT_QTY = 'SET_SHOPEE_PRODUCT_QUANTITY';
export const SET_SHOPEE_PRODUCT_AMOUNT = 'SET_SHOPEE_PRODUCT_AMOUNT';
export const SET_SHOPEE_TOTAL_ORDER = 'SET_SHOPEE_TOTAL_ORDER';
export const SET_SHOPEE_AVERAGE_ORDER = 'SET_SHOPEE_AVERAGE_ORDER';
export const SET_AGGREGATED_SHOPEE = 'SET_AGGREGATED_SHOPEE';

export const SET_SHOPIFY_PRODUCT_AMOUNT = 'SET_SHOPIFY_PRODUCT_AMOUNT';
export const SET_SHOPIFY_PRODUCT_QTY = 'SET_SHOPIFY_PRODUCT_QUANTITY';
export const SET_SHOPIFY_TOTAL_ORDER = 'SET_SHOPIFY_TOTAL_ORDER';
export const SET_SHOPIFY_AVERAGE_ORDER = 'SET_SHOPIFY_AVERAGE_ORDER';
export const SET_AGGREGATED_SHOPIFY = 'SET_AGGREGATED_SHOPIFY';

// FB_INSTAGRAM
export const SET_FB_INSTAGRAM_REACH = 'SET_FB_INSTAGRAM_REACH';
export const SET_FB_INSTAGRAM_FOLLOWER = 'SET_FB_INSTAGRAM_FOLLOWER';
export const SET_FB_INSTAGRAM_FOLLOWER_GROWTH =
  'SET_FB_INSTAGRAM_FOLLOWER_GROWTH';
export const SET_FB_INSTAGRAM_REACH_CHART = 'SET_FB_INSTAGRAM_REACH_CHART';
export const SET_FB_INSTAGRAM_BREAKDOWN_GENDER =
  'SET_FB_INSTAGRAM_BREAKDOWN_GENDER';
export const SET_FB_INSTAGRAM_BREAKDOWN_LOCATION =
  'SET_FB_INSTAGRAM_BREAKDOWN_LOCATION';

export const SET_HOME_TOTAL_SALES = 'SET_HOME_TOTAL_SALES';
export const SET_HOME_TOP_SALES = 'SET_HOME_TOP_SALES';
export const SET_HOME_TOTAL_AOV = 'SET_HOME_TOTAL_AOV';
export const SET_HOME_TOTAL_CPR = 'SET_HOME_TOTAL_CPR';
export const SET_HOME_TOTAL_CPA = 'SET_HOME_TOTAL_CPA';
export const SET_HOME_VS_AOV = 'SET_HOME_VS_AOV';
export const SET_HOME_VS_CPR = 'SET_HOME_VS_CPR';
export const SET_HOME_VS_CPA = 'SET_HOME_VS_CPA';
export const SET_AGGREGATED_HOME = 'SET_AGGREGATED_HOME';
