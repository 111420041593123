import { connect } from 'react-redux';
import CampaignFB from './CampaignFB.component';

import {
  getFbAds,
  getAggregatedStatus,
} from '../../redux/actions/dashboard/fb_ads.actions';

const mapStateToProps = (state) => ({
  accounts: state.binding.accountList,
  fbData: state.fb_ads,
});

const mapDispatchToProps = { getFbAds, getAggregatedStatus };

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFB);
