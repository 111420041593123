import React, { useState } from 'react';
import { Layout, Card, Row, Col, Typography } from 'antd';
import { format } from 'date-fns';

import animations from '../../../utils/animations';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation.component';
import { LANDING_IMG, LOGO_IMG, LOGO_IMG_WHITE } from '../../../assets/images';

import config from './Landing.component.config';
import './Landing.component.styles.less';
import { ICON_THORIUM, ICON_SHARE } from '../../../assets/icons';
import useIsIOS from '../../../utils/check-ios';

const { Content } = Layout;
const { Text, Title } = Typography;

const Landing = ({ children, id, fromEmpty }) => {
  const [isShowPrompt, setIsShowPrompt] = useState(true);
  const { prompt } = useIsIOS();

  const closeShowPrompt = () => setIsShowPrompt(false);

  return (
    <Layout id="landing-page">
      <div className="logo-img">
        <img
          src={window.IS_IN_MOBILE ? LOGO_IMG_WHITE : LOGO_IMG}
          alt="logo-bdd"
        />
      </div>
      <Content className="content">
        <Card style={{ animation: animations.popIn }} className="card">
          {fromEmpty ? (
            children
          ) : (
            <Row>
              <Col xs={24} md={12} className="landing-image">
                <img src={LANDING_IMG} alt="landing-img" className="loading" />
                <Title level={3}>
                  We are here to reach your goal through digital marketing.
                </Title>
              </Col>
              <Col xs={24} md={12} className="landing-content" id={id}>
                {children}
              </Col>
            </Row>
          )}
        </Card>
      </Content>
      <Text className="footer">
        Copyright © {format(new Date(), 'yyyy')} Boleh Dicoba Digital. All
        rights reserved
      </Text>
      {prompt && (
        <ModalConfirmation
          visible={isShowPrompt}
          config={{
            icon: ICON_THORIUM,
            title: 'Install Thorium to Homescreen',
            description:
              'Install this application on your homescreen for a better experience',
            btnConfirmText: 'OK',
            extra: (
              <Text style={{ marginTop: 16 }}>
                Tap{' '}
                <img
                  style={{ height: 24, width: 24 }}
                  src={ICON_SHARE}
                  alt="icon-share"
                />{' '}
                then “Add to Home Screen”
              </Text>
            ),
          }}
          onClose={closeShowPrompt}
          onConfirm={closeShowPrompt}
        />
      )}
    </Layout>
  );
};

Landing.displayName = config.displayName;
Landing.defaultProps = config.defaultProps;
Landing.propTypes = config.propTypes;

export default Landing;
