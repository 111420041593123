import React from 'react';
import get from 'lodash/get';

import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';

import Legend from '../Legend/Legend.component';

import config from './BarChart.component.config';
import { COLORS } from '../../../constants/variables';

NoData(Highcharts);

const BarChart = ({ chartData, hasLegend, config: configChart }) => {
  const dataSeries = chartData.dataList.map((item) => ({
    name: item.label,
    color: item.hexColor,
    data: item.data,
  }));

  const options = {
    chart: {
      type: 'column',
      height: get(configChart, 'height') || 180,
      spacing: [10, 8, 8, 8],
    },
    title: {
      text: '',
    },
    lang: {
      noData: 'No Data',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: chartData.xLabels,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      labels: {
        enabled: !configChart.hideYAxis,
      },
      opposite: configChart.YLabelRight,
      gridLineColor: 'none',
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      backgroundColor: COLORS['dark-background'],
      borderRadius: 16,
      style: {
        color: '#fff',
      },
    },
    legend: {
      enabled: false,
      align: 'left',
      verticalAlign: 'top',
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 2,
      squareSymbol: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        borderRadius: 4,
        dataLabels: {
          enabled: configChart.hideYAxis,
        },
      },
    },
    series: dataSeries,
  };

  const renderLegend = () => {
    if (hasLegend && chartData.dataList.length > 1) {
      return <Legend series={dataSeries} />;
    }
    return null;
  };

  return (
    <div id="bar-chart">
      {renderLegend()}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

BarChart.displayName = config.displayName;
BarChart.defaultProps = config.defaultProps;
BarChart.propTypes = config.propTypes;

export default BarChart;
