import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import packageInfo from '../package.json';
import { initFacebookSdk } from './services/facebook.service';
import withClearCache from './cache';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './containers';

import './index.less';
import './assets/fontello/css/fontello.css';

Sentry.init({
  dsn:
    'https://b6c150ac06434ecb80a25296024b9b7a@o1085317.ingest.sentry.io/6095935',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.ENV,
  release: packageInfo.version,
  tracesSampleRate: process.env.SENTRY_TRACES_RATE,
});

const SentryProfiler = Sentry.withProfiler(App);
const ClearCacheComponent = withClearCache(SentryProfiler);

initFacebookSdk().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ClearCacheComponent />
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

serviceWorkerRegistration.register();
