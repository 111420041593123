import React, { useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import { Layout, Typography, Avatar, Select, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';

import api from '../../../services/api.service';
import { formatAvatar } from '../../../utils/string';
import { SET_PERIOD } from '../../../redux/types';
import { PERIODS } from '../../../constants/variables';
import {
  ICON_LOADING,
  ICON_MENU,
  ICON_SHARELINK,
  ICON_CHECKED,
  ICON_CHECKED_OUTLINE,
} from '../../../assets/icons';
import { LOGO_IMG_WHITE } from '../../../assets/images';
import animationData from '../../../assets/loading.json';
import { notification } from '../../index';
import SideMenuDashboard from '../SideMenuDashboard/SideMenuDashboard.component';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation.component';

import config from './Dashboard.component.config';
import './Dashboard.component.styles.less';

const { Content, Header } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const { apiService } = api.getInstance();

const periodOptions = Object.keys(PERIODS).map((key) => ({
  value: key,
  label: PERIODS[key].label,
}));

const Dashboard = ({
  children,
  title,
  withPeriod,
  onPeriodChange,
  location,
  isSyncing,
  isSynced,
}) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [loadingShare, setLoadingShare] = useState(false);
  const [showStatus, setShowStatus] = useState(true);
  const [shareToken, setShareToken] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const {
    me: { data, loading, isGuest },
  } = useSelector((state) => state.account);
  const { period } = useSelector((state) => state.data);

  const fullName = get(data, 'name', 'J D');
  const firstName = fullName.split(' ')[0];
  const avatar = formatAvatar(fullName);
  const dispatch = useDispatch();
  const history = useHistory();

  const renderAccountBox = () => (
    <div
      className="account-section"
      onClick={() => {
        history.push('profile');
      }}
    >
      <span className="account-name">{fullName}</span>{' '}
      <Avatar shape="square">{avatar}</Avatar>
    </div>
  );

  const onShareLink = async () => {
    setLoadingShare(true);
    try {
      const {
        data: { token },
      } = await apiService.get('generate_guest_token');
      setShareToken(token);
    } catch (error) {
      notification.error({
        message: 'Share Akses Gagal',
        description: `Share akses belum berhasil`,
      });
    }

    setLoadingShare(false);
  };

  const renderShareLink = () => {
    if (isGuest) return null;
    return (
      <div className="header-share" onClick={onShareLink}>
        {loadingShare ? (
          <Lottie
            animationData={animationData}
            style={{ height: 40, width: 40 }}
          />
        ) : (
          <img src={ICON_SHARELINK} alt="icon-sharelink" />
        )}
      </div>
    );
  };

  const renderRightSectionHeader = () => (
    <div className="header-right-section">
      <div className="header-right-section-top">
        {renderAccountBox()}
        {renderShareLink()}
      </div>

      <div className="header-right-section-bottom">
        {withPeriod && (
          <Select
            id="select-period"
            defaultValue="today"
            value={period}
            onChange={(value) => {
              dispatch({ type: SET_PERIOD, period: value });
              onPeriodChange(value);
            }}
          >
            {periodOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );

  const renderLoading = () => (
    <>
      <div className="section-left-placeholder">
        <div className="greetings loading" />
        <div className="title loading" />
      </div>

      <div className="section-right-placeholder">
        <div className="account-section">
          <div className="account loading" />
          <div className="avatar loading" />
        </div>
        <div className="period loading" />
      </div>
    </>
  );

  const renderHeaderDesktop = () => (
    <>
      <div className="header-left-section">
        <Title>Hello {firstName},</Title>
        <Title level={2}>{title}</Title>
      </div>

      {renderRightSectionHeader()}
    </>
  );

  const renderHeaderMobile = () => (
    <div className="header-mobile">
      <div className="header-nav">
        <div
          className="header-menu"
          onClick={() => {
            setOpenSideMenu(true);
          }}
        >
          <img src={ICON_MENU} alt="icon-menu" />
        </div>
        <img src={LOGO_IMG_WHITE} alt="logo-mobile" className="header-logo" />

        <div className="header-profile">
          <Avatar
            shape="square"
            onClick={() => {
              history.push('profile');
            }}
          >
            {avatar}
          </Avatar>
          {renderShareLink()}
        </div>
      </div>
      <div className="header-greetings">
        <Title>Hello {firstName},</Title>
        <Title level={2}>{title}</Title>
      </div>

      <div className="header-period">
        {withPeriod && (
          <Select
            id="select-period"
            defaultValue="today"
            value={period}
            onChange={(value) => {
              dispatch({ type: SET_PERIOD, period: value });
              onPeriodChange(value);
            }}
          >
            {periodOptions.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );

  const onCloseShare = () => {
    setShareToken('');
  };

  const onConfirmShare = () => {
    const url = `${window.location.origin}?share=${shareToken}`;
    if (navigator.share) {
      navigator.share({
        title: 'Bagikan Akses Sementara',
        url,
      });
    } else {
      navigator.clipboard.writeText(url);
      setIsCopied(true);
    }
  };

  const withStatus = isSyncing || isSynced;
  const renderStatusSyncing = () => (
    <Row style={{ marginBottom: 24 }}>
      <Col span={24}>
        <div className="status syncing">
          <div className="info">
            <img src={ICON_LOADING} alt="icon-status" />
            Proses sinkronisasi data sedang berlangsung. Mohon tunggu maksimal 1
            x 24 jam untuk mendapatkan data yang valid.
          </div>
        </div>
      </Col>
    </Row>
  );

  const renderStatusSynced = () =>
    showStatus && (
      <Row style={{ marginBottom: 24 }}>
        <Col span={24}>
          <div className="status synced">
            <div className="info">
              <img src={ICON_CHECKED} alt="icon-status" />
              Proses sinkronisasi data sudah berhasil. Data yang ditampilkan
              saat ini adalah data yang valid.
            </div>
            <i className="icon-close" onClick={() => setShowStatus(false)} />
          </div>
        </Col>
      </Row>
    );

  return (
    <>
      <Layout id="dashboard-layout">
        {!window.IS_IN_MOBILE && (
          <Header>{loading ? renderLoading() : renderHeaderDesktop()}</Header>
        )}
        {window.IS_IN_MOBILE && renderHeaderMobile()}
        <Content
          style={window.IS_IN_MOBILE ? { marginTop: withPeriod ? 40 : 24 } : {}}
        >
          {withStatus &&
            (isSyncing ? renderStatusSyncing() : renderStatusSynced())}
          {children}
        </Content>

        <ModalConfirmation
          visible={shareToken.length > 0}
          config={{
            icon: ICON_SHARELINK,
            title: 'Bagikan Akses Sementara',
            extra: (
              <Text>
                Klik tombol “Share” untuk memberikan link akses sementara
                <br />
                <Text style={{ color: '#9b9b9b' }}>
                  (akses hanya berlaku selama 1x24 jam)
                </Text>
              </Text>
            ),
            btnConfirmText: isCopied ? (
              <div id="link-copied">
                <img src={ICON_CHECKED_OUTLINE} alt="icon-checked-outline" />{' '}
                Link Copied
              </div>
            ) : (
              'Share'
            ),
            btnStyle: isCopied ? 'copied' : null,
          }}
          onClose={onCloseShare}
          onConfirm={onConfirmShare}
        />
      </Layout>

      <SlidingPane
        className="sidebar-mobile"
        hideHeader
        width="324px"
        from="left"
        isOpen={openSideMenu}
        onRequestClose={() => {}}
      >
        <div>
          <SideMenuDashboard
            isGuest={isGuest}
            location={location}
            history={history}
            closeMenu={() => {
              setOpenSideMenu(false);
            }}
          />
        </div>
      </SlidingPane>
    </>
  );
};

Dashboard.displayName = config.displayName;
Dashboard.defaultProps = config.defaultProps;
Dashboard.propTypes = config.propTypes;

export default Dashboard;
