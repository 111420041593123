import get from 'lodash/get';

import {
  SET_AGGREGATED_TOKOPEDIA,
  SET_AGGREGATED_SHOPEE,
  SET_AGGREGATED_SHOPIFY,
  SET_TOKOPEDIA_PRODUCT_AMOUNT,
  SET_SHOPEE_PRODUCT_AMOUNT,
  SET_SHOPIFY_PRODUCT_AMOUNT,
  SET_TOKOPEDIA_PRODUCT_QTY,
  SET_SHOPEE_PRODUCT_QTY,
  SET_SHOPIFY_PRODUCT_QTY,
  SET_TOKOPEDIA_TOTAL_ORDER,
  SET_SHOPEE_TOTAL_ORDER,
  SET_SHOPIFY_TOTAL_ORDER,
  SET_TOKOPEDIA_AVERAGE_ORDER,
  SET_SHOPEE_AVERAGE_ORDER,
  SET_SHOPIFY_AVERAGE_ORDER,
} from '../../types';

const initialState = {
  TOKOPEDIA: {
    isAggregated: false,
    salesByProduct: {
      data: {
        short: [],
        all: [],
      },
      loading: false,
    },
    volumeByProduct: {
      data: {
        short: [],
        all: [],
      },
      loading: false,
    },
    chartAvgOrder: {
      data: { xLabels: [], dataList: [], total: '' },
      loading: false,
    },
    completedOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
    canceledOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
    totalOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
  },
  SHOPEE: {
    isAggregated: false,
    salesByProduct: {
      data: {
        short: [],
        all: [],
      },
      loading: false,
    },
    volumeByProduct: {
      data: {
        short: [],
        all: [],
      },
      loading: false,
    },
    chartAvgOrder: {
      data: { xLabels: [], dataList: [], total: '' },
      loading: false,
    },
    completedOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
    canceledOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
    totalOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
  },
  SHOPIFY: {
    isAggregated: false,
    salesByProduct: {
      data: {
        short: [],
        all: [],
      },
      loading: false,
    },
    volumeByProduct: {
      data: {
        short: [],
        all: [],
      },
      loading: false,
    },
    chartAvgOrder: {
      data: { xLabels: [], dataList: [], total: '' },
      loading: false,
    },
    completedOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
    canceledOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
    totalOrder: {
      value: 0,
      diff: 0,
      loading: false,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AGGREGATED_TOKOPEDIA:
      return {
        ...state,
        TOKOPEDIA: {
          ...state.TOKOPEDIA,
          isAggregated: action.payload,
        },
      };
    case SET_AGGREGATED_SHOPEE:
      return {
        ...state,
        SHOPEE: {
          ...state.SHOPEE,
          isAggregated: action.payload,
        },
      };
    case SET_AGGREGATED_SHOPIFY:
      return {
        ...state,
        SHOPIFY: {
          ...state.SHOPIFY,
          isAggregated: action.payload,
        },
      };
    case SET_TOKOPEDIA_PRODUCT_AMOUNT:
      return {
        ...state,
        TOKOPEDIA: {
          ...state.TOKOPEDIA,
          salesByProduct: {
            ...state.TOKOPEDIA.salesByProduct,
            data: {
              ...state.TOKOPEDIA.salesByProduct.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_SHOPEE_PRODUCT_AMOUNT:
      return {
        ...state,
        SHOPEE: {
          ...state.SHOPEE,
          salesByProduct: {
            ...state.SHOPEE.salesByProduct,
            data: {
              ...state.SHOPEE.salesByProduct.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_SHOPIFY_PRODUCT_AMOUNT:
      return {
        ...state,
        SHOPIFY: {
          ...state.SHOPIFY,
          salesByProduct: {
            ...state.SHOPIFY.salesByProduct,
            data: {
              ...state.SHOPIFY.salesByProduct.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_TOKOPEDIA_PRODUCT_QTY:
      return {
        ...state,
        TOKOPEDIA: {
          ...state.TOKOPEDIA,
          volumeByProduct: {
            ...state.TOKOPEDIA.volumeByProduct,
            data: {
              ...state.TOKOPEDIA.volumeByProduct.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_SHOPEE_PRODUCT_QTY:
      return {
        ...state,
        SHOPEE: {
          ...state.SHOPEE,
          volumeByProduct: {
            ...state.SHOPEE.volumeByProduct,
            data: {
              ...state.SHOPEE.volumeByProduct.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_SHOPIFY_PRODUCT_QTY:
      return {
        ...state,
        SHOPIFY: {
          ...state.SHOPIFY,
          volumeByProduct: {
            ...state.SHOPIFY.volumeByProduct,
            data: {
              ...state.SHOPIFY.volumeByProduct.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_TOKOPEDIA_TOTAL_ORDER:
      return {
        ...state,
        TOKOPEDIA: {
          ...state.TOKOPEDIA,
          completedOrder: {
            ...state.completedOrder,
            ...get(action, 'payload.completedOrder'),
            loading: action.loading,
          },
          canceledOrder: {
            ...state.canceledOrder,
            ...get(action, 'payload.canceledOrder'),
            loading: action.loading,
          },
          totalOrder: {
            ...state.totalOrder,
            ...get(action, 'payload.totalOrder'),
            loading: action.loading,
          },
        },
      };
    case SET_SHOPEE_TOTAL_ORDER:
      return {
        ...state,
        SHOPEE: {
          ...state.SHOPEE,
          completedOrder: {
            ...state.completedOrder,
            ...get(action, 'payload.completedOrder'),
            loading: action.loading,
          },
          canceledOrder: {
            ...state.canceledOrder,
            ...get(action, 'payload.canceledOrder'),
            loading: action.loading,
          },
          totalOrder: {
            ...state.totalOrder,
            ...get(action, 'payload.totalOrder'),
            loading: action.loading,
          },
        },
      };
    case SET_SHOPIFY_TOTAL_ORDER:
      return {
        ...state,
        SHOPIFY: {
          ...state.SHOPIFY,
          completedOrder: {
            ...state.completedOrder,
            ...get(action, 'payload.completedOrder'),
            loading: action.loading,
          },
          canceledOrder: {
            ...state.canceledOrder,
            ...get(action, 'payload.canceledOrder'),
            loading: action.loading,
          },
          totalOrder: {
            ...state.totalOrder,
            ...get(action, 'payload.totalOrder'),
            loading: action.loading,
          },
        },
      };
    case SET_TOKOPEDIA_AVERAGE_ORDER:
      return {
        ...state,
        TOKOPEDIA: {
          ...state.TOKOPEDIA,
          chartAvgOrder: {
            ...state.TOKOPEDIA.chartAvgOrder,
            data: {
              ...state.TOKOPEDIA.chartAvgOrder.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_SHOPEE_AVERAGE_ORDER:
      return {
        ...state,
        SHOPEE: {
          ...state.SHOPEE,
          chartAvgOrder: {
            ...state.SHOPEE.chartAvgOrder,
            data: {
              ...state.SHOPEE.chartAvgOrder.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_SHOPIFY_AVERAGE_ORDER:
      return {
        ...state,
        SHOPIFY: {
          ...state.SHOPIFY,
          chartAvgOrder: {
            ...state.SHOPIFY.chartAvgOrder,
            data: {
              ...state.SHOPIFY.chartAvgOrder.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    default:
      return state;
  }
};
