import React from 'react';
import { Card, Avatar, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { ICON_UP_TREND, ICON_DOWN_TREND } from '../../assets/icons';
import { formatPercentage } from '../../utils/string';
import { PERIODS } from '../../constants/variables';
import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardData.component.config';
import './CardData.component.styles.less';
import Loading from '../Loading/Loading.component';

const { Title, Text } = Typography;
const CardData = ({
  icon,
  color,
  backgroundColor,
  title,
  data,
  isUnbind,
  loading,
  extraText,
}) => {
  const { period } = useSelector((state) => state.data);

  const percentage = parseFloat(data.diff);
  const trend = percentage < 0 ? 'down' : 'up';
  const renderWithData = () => (
    <>
      <div className="row-ellipsis">
        <Text className="data">{data.value}</Text>
      </div>
      <div className="row-ellipsis">
        <div className="data-comparison">
          <img
            src={trend === 'up' ? ICON_UP_TREND : ICON_DOWN_TREND}
            alt="img-trend"
          />
          <Text className="text-comparison">
            <Text
              className={`percentage ${
                trend === 'up' ? 'text-success' : 'text-danger'
              }`}
            >
              {formatPercentage(Math.abs(percentage))}
            </Text>{' '}
            vs {PERIODS[period].compareLabel}
          </Text>
        </div>
      </div>
    </>
  );

  return (
    <Card bordered={false} id="card-data">
      <div className="wrapper-data">
        {icon && (
          <Avatar
            shape="square"
            icon={<i className={`icon-${icon}`} />}
            style={{ backgroundColor, color }}
          />
        )}
        <Title level={4}>{title}</Title>
        {isUnbind ? <UnbindedData title={title} center /> : renderWithData()}
        {extraText && (
          <div>
            <span className="extra">{extraText}</span>
          </div>
        )}
      </div>
      {loading && <Loading />}
    </Card>
  );
};

CardData.displayName = config.displayName;
CardData.defaultProps = config.defaultProps;
CardData.propTypes = config.propTypes;

export default CardData;
