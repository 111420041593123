import PropTypes from 'prop-types';

const displayName = 'CampaignGoogle';
const propTypes = {
  accounts: PropTypes.object,
  adwordData: PropTypes.object,
  getGoogleAds: PropTypes.func,
};
const defaultProps = {
  accounts: {},
  adwordData: {},
};
export default { displayName, propTypes, defaultProps };
