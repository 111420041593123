import get from 'lodash/get';

import {
  SET_GOOG_ADWORDS_CAMPAIGN,
  SET_GOOG_ADWORDS_REACH,
  SET_GOOG_ADWORDS_IMPRESSIONS,
  SET_GOOG_ADWORDS_CPM,
  SET_GOOG_ADWORDS_CPC,
  SET_GOOG_ADWORDS_VIEWS,
  SET_GOOG_ADWORDS_CONVERSION,
  SET_GOOG_ADWORDS_INTERACTION,
  SET_GOOG_ADWORDS_IMPRESSION_TOP,
} from '../../types';

const initialState = {
  tableCampaign: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
  reach: {
    value: 0,
    diff: 0,
    loading: false,
  },
  impression: {
    value: 0,
    diff: 0,
    loading: false,
  },
  frequency: {
    value: 0,
    diff: 0,
    loading: false,
  },
  linkClick: {
    value: 0,
    diff: 0,
    loading: false,
  },
  cpm: {
    value: 0,
    diff: 0,
    loading: false,
  },
  cpc: {
    value: 0,
    diff: 0,
    loading: false,
  },
  ctr: {
    value: 0,
    diff: 0,
    loading: false,
  },

  views: {
    value: 0,
    diff: 0,
    loading: false,
  },
  viewRate: {
    value: 0,
    diff: 0,
    loading: false,
  },
  watchTime: {
    value: 0,
    diff: 0,
    loading: false,
  },

  conversionRate: {
    value: 0,
    diff: 0,
    loading: false,
  },

  interactions: {
    value: 0,
    diff: 0,
    loading: false,
  },
  cpv: {
    value: 0,
    diff: 0,
    loading: false,
  },
  impressionTop: {
    value: 0,
    diff: 0,
    loading: false,
  },
  impressionAbsoluteTop: {
    value: 0,
    diff: 0,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GOOG_ADWORDS_CAMPAIGN:
      return {
        ...state,
        tableCampaign: {
          ...state.tableCampaign,
          data: {
            ...state.tableCampaign.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_REACH:
      return {
        ...state,
        reach: {
          ...state.reach,
          ...get(action, 'payload.reach'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_IMPRESSIONS:
      return {
        ...state,
        impression: {
          ...state.impression,
          ...get(action, 'payload.impression'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_CPM:
      return {
        ...state,
        linkClick: {
          ...state.linkClick,
          ...get(action, 'payload.linkClick'),
          loading: action.loading,
        },
        cpm: {
          ...state.cpm,
          ...get(action, 'payload.cpm'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_CPC:
      return {
        ...state,
        cpc: {
          ...state.cpc,
          ...get(action, 'payload.cpc'),
          loading: action.loading,
        },
        ctr: {
          ...state.ctr,
          ...get(action, 'payload.ctr'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_VIEWS:
      return {
        ...state,
        views: {
          ...state.views,
          ...get(action, 'payload.views'),
          loading: action.loading,
        },
        viewRate: {
          ...state.viewRate,
          ...get(action, 'payload.viewRate'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_CONVERSION:
      return {
        ...state,
        conversionRate: {
          ...state.conversionRate,
          ...get(action, 'payload.conversionRate'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_INTERACTION:
      return {
        ...state,
        interactions: {
          ...state.interactions,
          ...get(action, 'payload.interactions'),
          loading: action.loading,
        },
        cpv: {
          ...state.cpv,
          ...get(action, 'payload.cpv'),
          loading: action.loading,
        },
      };
    case SET_GOOG_ADWORDS_IMPRESSION_TOP:
      return {
        ...state,
        impressionTop: {
          ...state.impressionTop,
          ...get(action, 'payload.impressionTop'),
          loading: action.loading,
        },
        impressionAbsoluteTop: {
          ...state.impressionAbsoluteTop,
          ...get(action, 'payload.impressionAbsoluteTop'),
          loading: action.loading,
        },
      };
    default:
      return state;
  }
};
