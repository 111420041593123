/* eslint-disable camelcase */
import get from 'lodash/get';

import api from '../../services/api.service';
import { getToken } from '../../services/token.service';
import { notification } from '../../components';

import { SET_USER_DATA, SET_USER_INTRO } from '../types';
const { apiService, setAuthToken } = api.getInstance();

export const setIntroDone = (isBinding) => (dispatch) => {
  let payload = {
    summary: false,
  };
  if (isBinding) {
    payload = {
      binding: false,
    };
  }
  dispatch({ type: SET_USER_INTRO, payload });
};

export const getUserData = () => async (dispatch) => {
  let result;
  const currentToken = getToken();
  const token = get(currentToken, 'token');

  setAuthToken(token);
  dispatch({ type: SET_USER_DATA, payload: { loading: true } });

  const isNotGuest = get(currentToken, 'decodedToken.app') !== 'GUEST';
  try {
    if (isNotGuest) {
      const { data } = await apiService.get('users');
      dispatch({
        type: SET_USER_DATA,
        payload: { loading: false, data },
        isFirstTime: checkUserSession(data),
      });
    } else {
      dispatch({
        type: SET_USER_DATA,
        payload: { loading: false, data: { name: 'Guest' } },
        isGuest: true,
      });
    }
    result = Promise.resolve();
  } catch (error) {
    result = Promise.reject();
  }

  return result;
};

const checkUserSession = (data) => {
  const currentToken = getToken();
  const isGuest = get(currentToken, 'decodedToken.app') === 'GUEST';
  if (isGuest) return false;

  const CACHE_KEY = `CACHE_${data.email.toUpperCase()}`;

  const currentCache = localStorage.getItem(CACHE_KEY);
  if (currentCache) return false;
  localStorage.setItem(CACHE_KEY, 'true');

  return true;
};

export const setTemporaryUser = (token) => (dispatch) => {
  setAuthToken(token);
  dispatch({
    type: SET_USER_DATA,
    payload: { loading: false, data: { name: 'Guest' } },
    isGuest: true,
  });

  return Promise.resolve();
};

export const updateUser = (payload) => async (dispatch) => {
  let result;
  dispatch({ type: SET_USER_DATA, payload: { loading: false } });
  try {
    const { status: updateStatus, data } = await apiService.put(
      'users',
      payload,
    );
    if (updateStatus === 201) {
      result = Promise.resolve();
      dispatch({ type: SET_USER_DATA, payload: { loading: false, data } });
    } else {
      throw new Error('Failed');
    }
  } catch (error) {
    const {
      response: {
        data: { error_code },
      },
    } = error;
    let errorMessage;
    result = Promise.reject(error_code);
    if (error_code === 'INVALID_PASSWORD') {
      errorMessage = {
        message: 'Error',
        description: 'Your Current Password is Invalid',
      };
    } else {
      errorMessage = {
        message: 'Error',
        description: `Failed to update user data`,
      };
    }
    notification.error(errorMessage);
    dispatch({ type: SET_USER_DATA, payload: { loading: false } });
  }

  return result;
};
