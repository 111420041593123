import PropTypes from 'prop-types';

const displayName = 'LineChart';
const propTypes = {
  chartData: PropTypes.object.isRequired,
  title: PropTypes.string,
  hasLegend: PropTypes.bool,
  config: PropTypes.shape({
    hideYAxis: PropTypes.bool,
    hideXAxis: PropTypes.bool,
    YLabelRight: PropTypes.bool,
    showDataLabels: PropTypes.bool,
  }),
};
const defaultProps = {
  title: '',
  hasLegend: false,
  config: {
    hideYAxis: false,
    hideXAxis: false,
    YLabelRight: true,
    showDataLabels: false,
  },
};

export default { displayName, propTypes, defaultProps };
