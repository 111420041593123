import PropTypes from 'prop-types';

const displayName = 'Register';
const propTypes = {
  history: PropTypes.object,
  login: PropTypes.func,
  resetPassword: PropTypes.func,
  notificationError: PropTypes.func,
  loginFacebook: PropTypes.func,
  loginGoogle: PropTypes.func,
  loginGoogleSuccess: PropTypes.func,
  registerEmail: PropTypes.func,
  completeRegister: PropTypes.func,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
