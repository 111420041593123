import { SET_USER_DATA, SET_USER_INTRO } from '../types';

const initialState = {
  me: {
    data: {},
    isGuest: false,
    isFirstTime: {
      summary: false,
      binding: false,
    },
    loading: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        me: {
          ...state.me,
          ...action.payload,
          isFirstTime: {
            summary: action.isFirstTime,
            binding: action.isFirstTime,
          },
          isGuest: action.isGuest,
        },
      };
    case SET_USER_INTRO:
      return {
        ...state,
        me: {
          ...state.me,
          isFirstTime: {
            ...state.me.isFirstTime,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};
