import get from 'lodash/get';

import {
  SET_FB_ADS_CAMPAIGN,
  SET_FB_ADS_REACH,
  SET_FB_ADS_ATC,
  SET_FB_ADS_ATC_COST,
  SET_FB_ADS_ATC_VALUE,
  SET_FB_ADS_CPM,
  SET_FB_ADS_CPC,
  SET_FB_ADS_LPV,
  SET_FB_ADS_SHARE,
  SET_FB_ADS_ENGAGEMENT,
  SET_FB_ADS_OUTBOUND,
  SET_FB_ADS_INSTALL,
  SET_AGGREGATED_FB_ADS,
} from '../../types';

const initialState = {
  isAggregated: false,
  tableCampaign: {
    data: {
      short: [],
      all: [],
    },
    loading: false,
  },
  reach: {
    value: 0,
    diff: 0,
    loading: false,
  },
  impression: {
    value: 0,
    diff: 0,
    loading: false,
  },
  frequency: {
    value: 0,
    diff: 0,
    loading: false,
  },
  chartAtc: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  chartCostAtc: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  chartValueAtc: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  linkClick: {
    value: 0,
    diff: 0,
    loading: false,
  },
  cpc: {
    value: 0,
    diff: 0,
    loading: false,
  },
  cpm: {
    value: 0,
    diff: 0,
    loading: false,
  },
  ctr: {
    value: 0,
    diff: 0,
    loading: false,
  },
  lpv: {
    value: 0,
    diff: 0,
    loading: false,
  },
  contentView: {
    value: 0,
    diff: 0,
    loading: false,
  },
  postShare: {
    value: 0,
    diff: 0,
    loading: false,
  },
  postComment: {
    value: 0,
    diff: 0,
    loading: false,
  },
  postEngagement: {
    value: 0,
    diff: 0,
    loading: false,
  },
  postSaved: {
    value: 0,
    diff: 0,
    loading: false,
  },
  chartOutboundClick: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  appInstall: {
    value: 0,
    diff: 0,
    loading: false,
  },
  costAppInstall: {
    value: 0,
    diff: 0,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AGGREGATED_FB_ADS:
      return {
        ...state,
        isAggregated: action.payload,
      };
    case SET_FB_ADS_CAMPAIGN:
      return {
        ...state,
        tableCampaign: {
          ...state.tableCampaign,
          data: {
            ...state.tableCampaign.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_ADS_REACH:
      return {
        ...state,
        reach: {
          ...state.reach,
          ...get(action, 'payload.reach'),
          loading: action.loading,
        },
        impression: {
          ...state.impression,
          ...get(action, 'payload.impression'),
          loading: action.loading,
        },
        frequency: {
          ...state.frequency,
          ...get(action, 'payload.frequency'),
          loading: action.loading,
        },
      };
    case SET_FB_ADS_ATC:
      return {
        ...state,
        chartAtc: {
          ...state.chartAtc,
          data: {
            ...state.chartAtc.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_ADS_ATC_COST:
      return {
        ...state,
        chartCostAtc: {
          ...state.chartCostAtc,
          data: {
            ...state.chartCostAtc.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_ADS_ATC_VALUE:
      return {
        ...state,
        chartValueAtc: {
          ...state.chartValueAtc,
          data: {
            ...state.chartValueAtc.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_ADS_CPM:
      return {
        ...state,
        linkClick: {
          ...state.linkClick,
          ...get(action, 'payload.linkClick'),
          loading: action.loading,
        },
        cpm: {
          ...state.cpm,
          ...get(action, 'payload.cpm'),
          loading: action.loading,
        },
      };
    case SET_FB_ADS_CPC:
      return {
        ...state,
        cpc: {
          ...state.cpc,
          ...get(action, 'payload.cpc'),
          loading: action.loading,
        },
        ctr: {
          ...state.ctr,
          ...get(action, 'payload.ctr'),
          loading: action.loading,
        },
      };
    case SET_FB_ADS_LPV:
      return {
        ...state,
        lpv: {
          ...state.lpv,
          ...get(action, 'payload.lpv'),
          loading: action.loading,
        },
        contentView: {
          ...state.contentView,
          ...get(action, 'payload.contentView'),
          loading: action.loading,
        },
      };
    case SET_FB_ADS_SHARE:
      return {
        ...state,
        postShare: {
          ...state.postShare,
          ...get(action, 'payload.postShare'),
          loading: action.loading,
        },
        postComment: {
          ...state.postComment,
          ...get(action, 'payload.postComment'),
          loading: action.loading,
        },
      };
    case SET_FB_ADS_ENGAGEMENT:
      return {
        ...state,
        postEngagement: {
          ...state.postEngagement,
          ...get(action, 'payload.postEngagement'),
          loading: action.loading,
        },
        postSaved: {
          ...state.postSaved,
          ...get(action, 'payload.postSaved'),
          loading: action.loading,
        },
      };
    case SET_FB_ADS_OUTBOUND:
      return {
        ...state,
        chartOutboundClick: {
          ...state.chartOutboundClick,
          data: {
            ...state.chartOutboundClick.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_ADS_INSTALL:
      return {
        ...state,
        appInstall: {
          ...state.appInstall,
          ...get(action, 'payload.appInstall'),
          loading: action.loading,
        },
        costAppInstall: {
          ...state.costAppInstall,
          ...get(action, 'payload.costAppInstall'),
          loading: action.loading,
        },
      };
    default:
      return state;
  }
};
