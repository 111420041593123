import React, { useState } from 'react';
import get from 'lodash/get';
import { Card, Typography, Button, Modal } from 'antd';

import { DonutThicknessChart } from '../Charts';
import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardDonutListChart.component.config';
import './CardDonutListChart.component.styles.less';
import { formatNumber } from '../../utils/string';
import Loading from '../Loading/Loading.component';

const { Text, Title } = Typography;
const CardDonutListChart = ({ title, loading, data, isUnbind }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const extraActions = [
    <Button
      type="link"
      onClick={() => setModalVisible(true)}
      disabled={loading}
    >
      View More
    </Button>,
  ];

  const renderLegend = (legendData) => (
    <div id="legend-chart">
      {legendData.map((item) => (
        <div className="legend-item" key={item.name}>
          <div className="legend-item-left">
            <div className="pointer" style={{ backgroundColor: item.color }} />
            <Text className="legend-name">{item.name}</Text>
          </div>
          <Text className="legend-value">{formatNumber(item.value)}</Text>
        </div>
      ))}
    </div>
  );

  const all = get(data, 'all', []);
  const short = get(data, 'short', []);

  return (
    <Card
      id="card-donut-list-chart"
      actions={all.length > 3 && !isUnbind ? extraActions : null}
      title={title}
      bordered={false}
    >
      <div className="data-wrapper">
        {isUnbind ? (
          <UnbindedData title={title} center />
        ) : (
          <>
            <DonutThicknessChart chartData={all} withWidth />

            {renderLegend(short)}
          </>
        )}
      </div>
      {loading && <Loading />}

      <Modal
        footer={null}
        closeIcon={<i className="icon-close" />}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <div id="modal-chart">
          <Title level={4}>{title}</Title>
          <DonutThicknessChart chartData={all} chartSize={200} showLabel />

          {renderLegend(all)}
          <div className="btn-row">
            <Button
              type="link"
              onClick={() => setModalVisible(false)}
              disabled={loading}
            >
              View Less
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

CardDonutListChart.displayName = config.displayName;
CardDonutListChart.defaultProps = config.defaultProps;
CardDonutListChart.propTypes = config.propTypes;

export default CardDonutListChart;
