import React from 'react';
import { notification as notif } from 'antd';

export { default as Loading } from './Loading/Loading.component';

export { default as CardBarChart } from './CardBarChart/CardBarChart.component';
export { default as CardHorizontalBarChart } from './CardHorizontalBarChart/CardHorizontalBarChart.component';
export { default as CardBinding } from './CardBinding/CardBinding.component';
export { default as CardComparison } from './CardComparison/CardComparison.component';
export { default as CardData } from './CardData/CardData.component';
export { default as CardDonutChart } from './CardDonutChart/CardDonutChart.component';
export { default as CardDonutListChart } from './CardDonutListChart/CardDonutListChart.component';
export { default as CardList } from './CardList/CardList.component';
export { default as CardTable } from './CardTable/CardTable.component';
export { default as CardLineChart } from './CardLineChart/CardLineChart.component';
export { default as CardTab } from './CardTab/CardTab.component';

export { default as ModalConfirmation } from './ModalConfirmation/ModalConfirmation.component';
export { default as ModalBinding } from './ModalBinding/ModalBinding.component';
export { default as ModalBindingTokopedia } from './ModalBindingTokopedia/ModalBindingTokopedia.component';
export { default as ModalBindingShopify } from './ModalBindingShopify/ModalBindingShopify.component';

export const notification = {
  success: ({ message, description }) => {
    notif.open({
      message,
      description,
      style: {
        backgroundColor: '#C5FBD4',
        borderRadius: 16,
      },
      icon: '',
      closeIcon: <i className="icon-close" style={{ color: 'black' }} />,
    });
  },
  error: ({ message, description }) => {
    notif.open({
      message,
      description,
      style: {
        backgroundColor: '#FFDBDB',
        borderRadius: 16,
      },
      icon: '',
      closeIcon: <i className="icon-close" style={{ color: 'black' }} />,
    });
  },
};
