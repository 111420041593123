import React from 'react';
import { Card, Row, Typography } from 'antd';

import UnbindedData from '../UnbindedData/UnbindedData.component';
import Loading from '../Loading/Loading.component';

import config from './CardComparison.component.config';
import './CardComparison.component.styles.less';
import { ICON_NO_DATA } from '../../assets/icons';

const { Text } = Typography;
const CardComparison = ({ title, data, isUnbind, loading }) => {
  const renderWithData = () => (
    <div className="wrapper-data">
      <Row className="row-value">
        <Text className="data-value">{data}</Text>
        <Text className="data-percentege">%</Text>
      </Row>
      <Row>
        <Text className="data-desc">Lower is better</Text>
      </Row>
    </div>
  );

  const renderNoData = () => (
    <div className="empty">
      <img src={ICON_NO_DATA} alt="no-data" />
      <Text>Maaf data ini belum tersedia. Silakan coba beberapa saat lagi</Text>
    </div>
  );

  const renderData = () => (data === '-' ? renderNoData() : renderWithData());

  return (
    <Card title={title} bordered={false} id="card-comparison">
      {isUnbind ? <UnbindedData title={title} center /> : renderData()}
      {loading && <Loading />}
    </Card>
  );
};

CardComparison.displayName = config.displayName;
CardComparison.defaultProps = config.defaultProps;
CardComparison.propTypes = config.propTypes;

export default CardComparison;
