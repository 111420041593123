import axios from 'axios';

import { storeToken } from './token.service';

class ApiService {
  static INSTANCE;

  static getInstance = () => {
    if (!ApiService.INSTANCE) ApiService.INSTANCE = new ApiService();
    return ApiService.INSTANCE;
  };

  constructor() {
    this.apiService = axios.create({
      baseURL: process.env.REST_URL,
      timeout: 30000,
    });
    this.gqlService = axios.create({
      baseURL: process.env.GQL_URL,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  setAuthToken = (token, dontStoreToken) => {
    this.apiService.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    this.gqlService.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    if (!dontStoreToken) storeToken(token);
  };
}

export default ApiService;
