import { SET_PERIOD, SET_KEYWORD } from '../types';

const initialState = {
  keyword: '',
  period: 'TODAY',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PERIOD:
      return {
        ...state,
        period: action.period,
      };
    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword,
      };
    default:
      return state;
  }
};
