import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

import { getToken } from './token.service';

const httpLink = createHttpLink({
  uri: process.env.GQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const store = getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: store ? `Bearer ${store.token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    Sentry.captureMessage(`GraphQL error: ${graphQLErrors.join(',')}`);
    return;
  }

  if (networkError) {
    Sentry.captureMessage(`Network error: ${networkError}`);
  }
});

export default new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
