/* eslint-disable no-unused-vars */
import get from 'lodash/get';
import { store } from '../../store';
import clientGql from '../../../services/graphql.service';

import {
  tableCampaign,
  reachImpression,
  chartATC,
  chartAtcCost,
  chartAtcValue,
  linkCpm,
  ctrCpc,
  lpvContent,
  shareComment,
  engagementSaved,
  chartOutboundClicks,
  appInstall,
  getStatusAggregation,
} from '../../../graphql/fb_ads.graphql';
import { mapChartData, calculateDiff } from '../../../utils/data';
import {
  formatPercentage,
  formatCurrency,
  formatNumber,
} from '../../../utils/string';
import {
  SET_FB_ADS_CAMPAIGN,
  SET_FB_ADS_REACH,
  SET_FB_ADS_ATC,
  SET_FB_ADS_ATC_COST,
  SET_FB_ADS_ATC_VALUE,
  SET_FB_ADS_CPM,
  SET_FB_ADS_CPC,
  SET_FB_ADS_LPV,
  SET_FB_ADS_SHARE,
  SET_FB_ADS_ENGAGEMENT,
  SET_FB_ADS_OUTBOUND,
  SET_FB_ADS_INSTALL,
  SET_AGGREGATED_FB_ADS,
} from '../../types';
import { COLORS } from '../../../constants/variables';
import { notification } from '../../../components';

const getVariables = () => {
  const accounts = store.getState().binding.accountList.FB_ADS_INSIGHT.data;
  const interval = store.getState().data.period;
  const intervalCompare = `COMPARE_${interval}`;

  const accountId = accounts.map((account) => account.account_id.substring(4));
  return { interval, accountId, intervalCompare };
};

const getCampaign = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_CAMPAIGN,
    loading: true,
  });
  try {
    const {
      data: { fbAdsCollaborative },
    } = await clientGql.query({
      query: tableCampaign,
      variables,
      context,
    });

    const dataList = fbAdsCollaborative.map((data) => ({
      campaign: get(data, 'campaignName', '-'),
      spend: formatCurrency(get(data, 'spend', 0).toFixed(2)),
      roas: parseFloat(get(data, 'roas', 0)).toFixed(2),
    }));

    dataList.sort(
      (a, b) =>
        b.spend.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.spend.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_FB_ADS_CAMPAIGN,
      payload: {
        all: dataList,
        short: dataList.slice(0, 6),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_CAMPAIGN,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getReach = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_REACH,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: reachImpression,
      variables,
      context,
    });

    const reach = get(data, 'reach', 0);
    const impression = get(data, 'impressions', 0);
    const frequency = get(data, 'frequency', 0);

    dispatch({
      type: SET_FB_ADS_REACH,
      payload: {
        reach: { value: formatNumber(parseInt(reach, 10)) },
        impression: { value: formatNumber(parseInt(impression, 10)) },
        frequency: { value: formatNumber(parseInt(frequency, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: reachImpression,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareReach = calculateDiff(reach, get(dataCompare, 'reach', 0));
    const compareImpression = calculateDiff(
      impression,
      get(dataCompare, 'impression', 0),
    );
    const compareFrequency = calculateDiff(
      frequency,
      get(dataCompare, 'frequency', 0),
    );

    dispatch({
      type: SET_FB_ADS_REACH,
      payload: {
        reach: { value: formatNumber(parseInt(reach, 10)), diff: compareReach },
        impression: {
          value: formatNumber(parseInt(impression, 10)),
          diff: compareImpression,
        },
        frequency: {
          value: formatNumber(parseInt(frequency, 10)),
          diff: compareFrequency,
        },
      },
      loading: false,
    });

    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_REACH,
      loading: false,
      payload: {
        reach: {
          value: 0,
          diff: 0,
        },
        impression: {
          value: 0,
          diff: 0,
        },
        frequency: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getCpm = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_CPM,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: linkCpm,
      variables,
      context,
    });

    const linkClick = get(data, 'actionsLinkClick', 0);
    const cpm = get(data, 'cpm', 0);

    dispatch({
      type: SET_FB_ADS_CPM,
      payload: {
        linkClick: { value: formatNumber(parseInt(linkClick, 10)) },
        cpm: { value: formatCurrency(parseInt(cpm, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: linkCpm,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareLinkClick = calculateDiff(
      linkClick,
      get(dataCompare, 'actionsLinkClick', 0),
    );
    const compareCpm = calculateDiff(cpm, get(dataCompare, 'cpm', 0));

    dispatch({
      type: SET_FB_ADS_CPM,
      payload: {
        linkClick: {
          value: formatNumber(parseInt(linkClick, 10)),
          diff: compareLinkClick,
        },
        cpm: { value: formatCurrency(parseInt(cpm, 10)), diff: compareCpm },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_CPM,
      loading: false,
      payload: {
        linkClick: {
          value: 0,
          diff: 0,
        },
        cpm: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getCpc = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_CPC,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: ctrCpc,
      variables,
      context,
    });

    const cpc = get(data, 'cpc', 0);
    const ctr = get(data, 'ctr', 0);

    dispatch({
      type: SET_FB_ADS_CPC,
      payload: {
        cpc: { value: formatCurrency(parseInt(cpc, 10)) },
        ctr: {
          value: formatPercentage(parseFloat(ctr).toFixed(2)),
        },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: ctrCpc,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareCpc = calculateDiff(cpc, get(dataCompare, 'cpc', 0));
    const compareCtr = calculateDiff(ctr, get(dataCompare, 'ctr', 0));

    dispatch({
      type: SET_FB_ADS_CPC,
      payload: {
        cpc: { value: formatCurrency(parseInt(cpc, 10)), diff: compareCpc },
        ctr: {
          value: formatPercentage(parseFloat(ctr).toFixed(2)),
          diff: compareCtr,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_CPC,
      loading: false,
      payload: {
        cpc: {
          value: 0,
          diff: 0,
        },
        ctr: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartATC = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_ATC,
    loading: true,
  });
  try {
    const {
      data: { fbAdsCollaborative },
    } = await clientGql.query({
      query: chartATC,
      variables,
      context,
    });

    const atc = mapChartData(variables.interval)(
      'numberOfTotalAddToCart',
      fbAdsCollaborative,
    );
    const purchase = mapChartData(variables.interval)(
      'numberOfTotalPurchase',
      fbAdsCollaborative,
    );

    dispatch({
      type: SET_FB_ADS_ATC,
      payload: {
        xLabels: get(atc, 'xLabels', []),
        dataList: [
          {
            label: 'Add to Cart',
            hexColor: COLORS.primary,
            data: get(atc, 'data', []),
          },
          {
            label: 'Total Purchase',
            hexColor: COLORS.secondary,
            data: get(purchase, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_ATC,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartAtcCost = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_ATC_COST,
    loading: true,
  });
  try {
    const {
      data: { fbAdsCollaborative },
    } = await clientGql.query({
      query: chartAtcCost,
      variables,
      context,
    });

    const costAtc = mapChartData(variables.interval)(
      'costPerAddToCart',
      fbAdsCollaborative,
    );
    const costPurchase = mapChartData(variables.interval)(
      'costPerPurchase',
      fbAdsCollaborative,
    );
    dispatch({
      type: SET_FB_ADS_ATC_COST,
      payload: {
        xLabels: get(costAtc, 'xLabels', []),
        dataList: [
          {
            label: 'Cost per Add to Cart',
            hexColor: COLORS.primary,
            data: get(costAtc, 'data', []),
          },
          {
            label: 'Cost per Purchase',
            hexColor: COLORS.secondary,
            data: get(costPurchase, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_ATC_COST,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartAtcValue = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_ATC_VALUE,
    loading: true,
  });
  try {
    const {
      data: { fbAdsCollaborative },
    } = await clientGql.query({
      query: chartAtcValue,
      variables,
      context,
    });

    const atc = mapChartData(variables.interval)(
      'addToCartValue',
      fbAdsCollaborative,
    );
    const purchase = mapChartData(variables.interval)(
      'purchaseValue',
      fbAdsCollaborative,
    );

    dispatch({
      type: SET_FB_ADS_ATC_VALUE,
      payload: {
        xLabels: get(atc, 'xLabels', []),
        dataList: [
          {
            label: 'Add to Cart Value',
            hexColor: COLORS.primary,
            data: get(atc, 'data', []),
          },
          {
            label: 'Purchase Value',
            hexColor: COLORS.secondary,
            data: get(purchase, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_ATC_VALUE,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getLpv = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_LPV,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: lpvContent,
      variables,
      context,
    });

    const lpv = get(data, 'actionsLandingPageView', 0);
    const contentView = get(data, 'catalogSegmentActionsOmniViewContent', 0);

    dispatch({
      type: SET_FB_ADS_LPV,
      payload: {
        lpv: { value: formatNumber(parseInt(lpv, 10)) },
        contentView: {
          value: formatNumber(parseInt(contentView, 10)),
        },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: lpvContent,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareLpv = calculateDiff(
      lpv,
      get(dataCompare, 'actionsLandingPageView', 0),
    );
    const compareContentView = calculateDiff(
      contentView,
      get(dataCompare, 'catalogSegmentActionsOmniViewContent', 0),
    );

    dispatch({
      type: SET_FB_ADS_LPV,
      payload: {
        lpv: { value: formatNumber(parseInt(lpv, 10)), diff: compareLpv },
        contentView: {
          value: formatNumber(parseInt(contentView, 10)),
          diff: compareContentView,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_LPV,
      loading: false,
      payload: {
        lpv: {
          value: 0,
          diff: 0,
        },
        contentView: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getShare = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_SHARE,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: shareComment,
      variables,
      context,
    });

    const postShare = get(data, 'actionsPost', 0);
    const postComment = get(data, 'actionsComment', 0);

    dispatch({
      type: SET_FB_ADS_SHARE,
      payload: {
        postShare: {
          value: formatNumber(parseInt(postShare, 10)),
        },
        postComment: { value: formatNumber(parseInt(postComment, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: shareComment,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const comparePostShare = calculateDiff(
      postShare,
      get(dataCompare, 'actionsPost', 0),
    );
    const comparePostComment = calculateDiff(
      postComment,
      get(dataCompare, 'actionsComment', 0),
    );

    dispatch({
      type: SET_FB_ADS_SHARE,
      payload: {
        postShare: {
          value: formatNumber(parseInt(postShare, 10)),
          diff: comparePostShare,
        },
        postComment: {
          value: formatNumber(parseInt(postComment, 10)),
          diff: comparePostComment,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_SHARE,
      loading: false,
      payload: {
        postShare: {
          value: 0,
          diff: 0,
        },
        postComment: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getEngagement = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_ENGAGEMENT,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: engagementSaved,
      variables,
      context,
    });

    const postEngagement = get(data, 'actionsPostEngagement', 0);
    const postSaved = get(data, 'actionsOnsiteConversionPostSave', 0);

    dispatch({
      type: SET_FB_ADS_ENGAGEMENT,
      payload: {
        postEngagement: { value: formatNumber(parseInt(postEngagement, 10)) },
        postSaved: {
          value: formatNumber(parseInt(postSaved, 10)),
        },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: engagementSaved,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const comparePostEngagement = calculateDiff(
      postEngagement,
      get(dataCompare, 'actionsPostEngagement', 0),
    );
    const comparePostSaved = calculateDiff(
      postSaved,
      get(dataCompare, 'actionsOnsiteConversionPostSave', 0),
    );

    dispatch({
      type: SET_FB_ADS_ENGAGEMENT,
      payload: {
        postEngagement: {
          value: formatNumber(parseInt(postEngagement, 10)),
          diff: comparePostEngagement,
        },
        postSaved: {
          value: formatNumber(parseInt(postSaved, 10)),
          diff: comparePostSaved,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_ENGAGEMENT,
      loading: false,
      payload: {
        postEngagement: {
          value: 0,
          diff: 0,
        },
        postSaved: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartOutbound = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_OUTBOUND,
    loading: true,
  });
  try {
    const {
      data: { fbAdsInsights },
    } = await clientGql.query({
      query: chartOutboundClicks,
      variables,
      context,
    });

    const outbound = mapChartData(variables.interval)(
      'outboundClicksOutboundClick',
      fbAdsInsights,
    );
    const click = mapChartData(variables.interval)('clicks', fbAdsInsights);

    dispatch({
      type: SET_FB_ADS_OUTBOUND,
      payload: {
        xLabels: get(outbound, 'xLabels', []),
        dataList: [
          {
            label: 'Outbound Clicks',
            hexColor: COLORS.primary,
            data: get(outbound, 'data', []),
          },
          {
            label: 'Clicks',
            hexColor: COLORS.secondary,
            data: get(click, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_OUTBOUND,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getAppInstall = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_ADS_INSTALL,
    loading: true,
  });
  try {
    const {
      data: {
        fbAdsInsights: [data],
      },
    } = await clientGql.query({
      query: appInstall,
      variables,
      context,
    });

    const dataAppInstall = get(data, 'actionsAppInstall', 0);
    const costAppInstall = get(data, 'costPerActionTypeAppInstall', 0);

    dispatch({
      type: SET_FB_ADS_INSTALL,
      payload: {
        appInstall: { value: formatCurrency(parseInt(dataAppInstall, 10)) },
        costAppInstall: {
          value: formatCurrency(parseInt(costAppInstall, 10)),
        },
      },
      loading: true,
    });

    const {
      data: {
        fbAdsInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: appInstall,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareAppInstall = calculateDiff(
      dataAppInstall,
      get(dataCompare, 'actionsAppInstall', 0),
    );
    const compareCostAppInstall = calculateDiff(
      costAppInstall,
      get(dataCompare, 'costPerActionTypeAppInstall', 0),
    );

    dispatch({
      type: SET_FB_ADS_INSTALL,
      payload: {
        appInstall: {
          value: formatCurrency(parseInt(dataAppInstall, 10)),
          diff: compareAppInstall,
        },
        costAppInstall: {
          value: formatCurrency(parseInt(costAppInstall, 10)),
          diff: compareCostAppInstall,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_ADS_INSTALL,
      loading: false,
      payload: {
        cpc: {
          value: 0,
          diff: 0,
        },
        ctr: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

export const getAggregatedStatus = () => async (dispatch) => {
  try {
    const {
      data: { aggregationStatusCompleted: data },
    } = await clientGql.query({
      query: getStatusAggregation,
    });

    const isAggregated = get(data, 'facebook');

    dispatch({
      type: SET_AGGREGATED_FB_ADS,
      payload: !!isAggregated,
    });
  } catch (error) {
    dispatch({
      type: SET_AGGREGATED_FB_ADS,
      payload: false,
    });
  }
};

let querySource;

export const getFbAds = () => async (dispatch) => {
  const variables = getVariables();
  if (querySource) {
    querySource.abort();
  }

  const source = new window.AbortController();
  querySource = source;

  const context = {
    fetchOptions: { signal: source.signal },
  };
  try {
    await Promise.all([
      getCampaign(variables, context)(dispatch),
      getReach(variables, context)(dispatch),
      getCpm(variables, context)(dispatch),
      getCpc(variables, context)(dispatch),
      getChartATC(variables, context)(dispatch),
      getChartAtcCost(variables, context)(dispatch),
      getChartAtcValue(variables, context)(dispatch),
      getLpv(variables, context)(dispatch),
      getShare(variables, context)(dispatch),
      getEngagement(variables, context)(dispatch),
      getChartOutbound(variables, context)(dispatch),
      getAppInstall(variables, context)(dispatch),
    ]);
  } catch (error) {
    notification.error({
      message: 'Terjadi Kesalahan',
      description: `Sebagian data gagal diproses, silakan coba lagi`,
    });
  }

  return source;
};
