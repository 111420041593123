import React from 'react';

import { Layout } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SideMenuDashboard } from '../../components/Layout';
import { DASHBOARD_ROUTE } from '../../navigation/dashboard.route';

import config from './Dashboard.component.config';
import { getParamURL } from '../../utils/data';

class DashboardPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  _onErrorGetUser = () => {
    localStorage.clear();
    window.location.replace('/login');
  };

  _getBindedAccounts = () => {
    this.props
      .getBindedAccounts()
      .then(() => this.setState({ isLoading: false }));
  };

  componentDidMount() {
    window.Intercom('boot', {
      app_id: 'j89bitbz',
    });
    const shareToken = getParamURL('share');
    if (shareToken.length > 0) {
      this.props.setTemporaryUser(shareToken).then(this._getBindedAccounts);
    } else {
      this.props
        .getUserData()
        .then(this._getBindedAccounts)
        .catch(this._onErrorGetUser);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.Intercom('update');
    }
  }

  render() {
    const {
      location,
      history,
      me: { loading, isGuest },
    } = this.props;
    if (loading || this.state.isLoading) return null;

    return (
      <Layout id="dashboard">
        {!window.IS_IN_MOBILE && (
          <SideMenuDashboard
            location={location}
            history={history}
            isGuest={isGuest}
          />
        )}

        <Switch>
          {DASHBOARD_ROUTE.map((route) => (
            <Route key={route.id} {...route} />
          ))}
          <Redirect to="/summary" />
        </Switch>
      </Layout>
    );
  }
}

DashboardPage.displayName = config.displayName;
DashboardPage.defaultProps = config.defaultProps;
DashboardPage.propTypes = config.propTypes;

export default DashboardPage;
