import PropTypes from 'prop-types';

const displayName = 'CardList';
const propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  textExtra: PropTypes.string,
  onClickExtra: PropTypes.func,
  loading: PropTypes.bool,
};
const defaultProps = {
  title: 'exmple title',
  data: {
    short: [],
    all: [],
  },
  textExtra: '',
  loading: false,
  limit: 6,
};

export default { displayName, propTypes, defaultProps };
