import PropTypes from 'prop-types';

const displayName = 'Loading';
const propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};
const defaultProps = {
  size: 48,
  color: '#526180',
  style: {},
};

export default { displayName, propTypes, defaultProps };
