import React from 'react';
import get from 'lodash/get';
import { Row, Col, Card, Carousel } from 'antd';

import { CardTable, CardLineChart, CardData } from '../../components';

import { DashboardLayout } from '../../components/Layout';

import config from './CampaignFB.component.config';

class CampaignFB extends React.PureComponent {
  componentDidMount() {
    this.props.getAggregatedStatus();
    if (this.props.accounts.FB_ADS_INSIGHT.haveAccount) {
      this.props.getFbAds();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.accounts.FB_ADS_INSIGHT.haveAccount !==
      this.props.accounts.FB_ADS_INSIGHT.haveAccount
    ) {
      this.props.getFbAds();
    }
  }

  // ----------------------------- total ads reach

  renderAdsReachDesktop = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <Row gutter={24}>
        <Col xl={12} md={24} sm={24} xs={24}>
          <CardData
            title="Reach"
            data={get(fbData, 'reach')}
            config={{
              loadingClass: 'in-card',
            }}
            loading={get(fbData, 'reach.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={12} md={24} sm={24} xs={24}>
          <CardData
            title="Impression"
            data={get(fbData, 'impression')}
            config={{
              loadingClass: 'in-card',
            }}
            loading={get(fbData, 'impression.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={12} md={24} sm={24} xs={24}>
          <CardData
            title="Frequency"
            data={get(fbData, 'frequency')}
            config={{
              loadingClass: 'in-card',
            }}
            loading={get(fbData, 'frequency.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </Row>
    );
  };

  renderAdsReachMobile = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <Carousel centerMode infinite={false}>
        <CardData
          title="Reach"
          data={get(fbData, 'reach')}
          config={{
            loadingClass: 'in-card',
          }}
          loading={get(fbData, 'reach.loading')}
          isUnbind={isUnbind}
        />

        <CardData
          title="Impression"
          data={get(fbData, 'impression')}
          config={{
            loadingClass: 'in-card',
          }}
          loading={get(fbData, 'impression.loading')}
          isUnbind={isUnbind}
        />

        <CardData
          title="Frequency"
          data={get(fbData, 'frequency')}
          config={{
            loadingClass: 'in-card',
          }}
          loading={get(fbData, 'frequency.loading')}
          isUnbind={isUnbind}
        />
      </Carousel>
    );
  };

  renderTotalAdsReach = (isUnbind) => (
    <Col xl={12} md={24} sm={24} xs={24}>
      <Card title="Total Ads Reach" className="no-bottom">
        {window.IS_IN_MOBILE
          ? this.renderAdsReachMobile(isUnbind)
          : this.renderAdsReachDesktop(isUnbind)}
      </Card>
    </Col>
  );

  // ----------------------------- cpc ctr cpm linkClick

  renderCpcCtrDesktop = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Link Clicks"
            data={get(fbData, 'linkClick')}
            loading={get(fbData, 'linkClick.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Cost per Mille (CPM)"
            data={get(fbData, 'cpm')}
            loading={get(fbData, 'cpm.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Clickthrough rate (CTR)"
            data={get(fbData, 'ctr')}
            loading={get(fbData, 'ctr.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Cost per Click (CPC)"
            data={get(fbData, 'cpc')}
            loading={get(fbData, 'cpc.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </>
    );
  };

  renderCpcCtrMobile = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            title="Link Clicks"
            data={get(fbData, 'linkClick')}
            loading={get(fbData, 'linkClick.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Cost per Mille (CPM)"
            data={get(fbData, 'cpm')}
            loading={get(fbData, 'cpm.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Clickthrough rate (CTR)"
            data={get(fbData, 'ctr')}
            loading={get(fbData, 'ctr.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Cost per Click (CPC)"
            data={get(fbData, 'cpc')}
            loading={get(fbData, 'cpc.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderCpcCtr = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderCpcCtrMobile(isUnbind)
      : this.renderCpcCtrDesktop(isUnbind);

  // ----------------------------- lpv post~

  renderLpvPostDesktop = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Landing Page View"
            data={get(fbData, 'lpv')}
            loading={get(fbData, 'lpv.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Content View (CPAS)"
            data={get(fbData, 'contentView')}
            loading={get(fbData, 'contentView.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Post Share"
            data={get(fbData, 'postShare')}
            loading={get(fbData, 'postShare.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Post Comment"
            data={get(fbData, 'postComment')}
            loading={get(fbData, 'postComment.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </>
    );
  };

  renderLpvPostMobile = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            title="Landing Page View"
            data={get(fbData, 'lpv')}
            loading={get(fbData, 'lpv.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Content View (CPAS)"
            data={get(fbData, 'contentView')}
            loading={get(fbData, 'contentView.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Post Share"
            data={get(fbData, 'postShare')}
            loading={get(fbData, 'postShare.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Post Comment"
            data={get(fbData, 'postComment')}
            loading={get(fbData, 'postComment.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderLpvPost = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderLpvPostMobile(isUnbind)
      : this.renderLpvPostDesktop(isUnbind);

  // ----------------------------- post install

  renderPostInstallDesktop = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Row gutter={24}>
          <Col xl={12} md={12} sm={24} xs={24}>
            <CardData
              title="Post Engagement"
              data={get(fbData, 'postEngagement')}
              loading={get(fbData, 'postEngagement.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardData
              title="Post Saved"
              data={get(fbData, 'postSaved')}
              loading={get(fbData, 'postSaved.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardData
              title="App Install"
              data={get(fbData, 'appInstall')}
              loading={get(fbData, 'appInstall.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardData
              title="Cost per App Install"
              data={get(fbData, 'costAppInstall')}
              loading={get(fbData, 'costAppInstall.loading')}
              isUnbind={isUnbind}
            />
          </Col>
        </Row>
      </Col>
    );
  };

  renderPostInstallMobile = (isUnbind) => {
    const { fbData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            title="Post Engagement"
            data={get(fbData, 'postEngagement')}
            loading={get(fbData, 'postEngagement.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Post Saved"
            data={get(fbData, 'postSaved')}
            loading={get(fbData, 'postSaved.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="App Install"
            data={get(fbData, 'appInstall')}
            loading={get(fbData, 'appInstall.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Cost per App Install"
            data={get(fbData, 'costAppInstall')}
            loading={get(fbData, 'costAppInstall.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderPostInstall = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderPostInstallMobile(isUnbind)
      : this.renderPostInstallDesktop(isUnbind);

  render() {
    const { getFbAds, fbData, accounts, location } = this.props;
    const isUnbind = !accounts.FB_ADS_INSIGHT.haveAccount;

    const { isAggregated } = fbData;

    return (
      <DashboardLayout
        title="Campaign Facebook Ads"
        onPeriodChange={getFbAds}
        location={location}
        isSynced={isAggregated}
        isSyncing={!isAggregated && !isUnbind}
      >
        <Row gutter={24}>
          <Col xl={12} md={24} sm={24} xs={24}>
            <CardTable
              title="Performance by Campaign"
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'campaign',
                  key: 'campaign',
                },
                {
                  title: 'Spend',
                  dataIndex: 'spend',
                  key: 'spend',
                  align: 'right',
                },
                {
                  title: 'ROAS',
                  dataIndex: 'roas',
                  key: 'roas',
                  align: 'right',
                  width: 80,
                },
              ]}
              data={get(fbData, 'tableCampaign.data')}
              loading={get(fbData, 'tableCampaign.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          {this.renderTotalAdsReach()}

          {this.renderCpcCtr()}

          <Col xl={8} md={24} sm={24} xs={24}>
            <CardLineChart
              title="Add to Cart (ATC) VS Total Purchase"
              data={get(fbData, 'chartAtc.data')}
              loading={get(fbData, 'chartAtc.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={8} md={24} sm={24} xs={24}>
            <CardLineChart
              title="Cost per ATC VS Cost per Purchase"
              data={get(fbData, 'chartCostAtc.data')}
              loading={get(fbData, 'chartCostAtc.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={8} md={24} sm={24} xs={24}>
            <CardLineChart
              title="ATC Value VS Purchase Value"
              data={get(fbData, 'chartValueAtc.data')}
              loading={get(fbData, 'chartValueAtc.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          {this.renderLpvPost()}

          <Col xl={12} md={24} sm={24} xs={24}>
            <CardLineChart
              title="Outbound Clicks VS # of Clicks"
              data={get(fbData, 'chartOutboundClick.data')}
              loading={get(fbData, 'chartOutboundClick.loading')}
              isUnbind={isUnbind}
              chartConfig={{
                hasLegend: true,
                config: {
                  hideYAxis: false,
                  hideXAxis: false,
                  YLabelRight: true,
                },
              }}
            />
          </Col>

          {this.renderPostInstall()}
        </Row>
      </DashboardLayout>
    );
  }
}

CampaignFB.displayName = config.displayName;
CampaignFB.defaultProps = config.defaultProps;
CampaignFB.propTypes = config.propTypes;

export default CampaignFB;
