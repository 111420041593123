import React from 'react';
import { InstagramFilled } from '@ant-design/icons';

import config from './SideMenuDashboardItem.component.config';
import './SideMenuDashboardItem.component.styles.less';

const SideMenuDashboardItem = ({ item, className, onClick }) => (
  <div id="menu-item" className={className} onClick={onClick}>
    <div className="menu-icon">
      {item.icon === 'instagram' ? (
        <InstagramFilled style={{ fontSize: 20 }} />
      ) : (
        <i className={`icon-${item.icon}`} />
      )}
    </div>

    <p>{item.title}</p>
  </div>
);

SideMenuDashboardItem.displayName = config.displayName;
SideMenuDashboardItem.defaultProps = config.defaultProps;
SideMenuDashboardItem.propTypes = config.propTypes;

export default SideMenuDashboardItem;
