import PropTypes from 'prop-types';

const displayName = 'PerformanceAnalytics';
const propTypes = {
  getGoogAnalytics: PropTypes.func.isRequired,
  accounts: PropTypes.object,
  performanceData: PropTypes.object,
};
const defaultProps = {
  accounts: {},
  performanceData: {},
};

export default { displayName, propTypes, defaultProps };
