import { connect } from 'react-redux';
import PerformanceAnalytics from './PerformanceAnalytics.component';

import { getGoogAnalytics } from '../../redux/actions/dashboard/goog_analytics.actions';

const mapStateToProps = (state) => ({
  accounts: state.binding.accountList,
  performanceData: state.goog_analytics,
});

const mapDispatchToProps = { getGoogAnalytics };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PerformanceAnalytics);
