import React from 'react';

import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';

import config from './DonutChart.component.config';
import './DonutChart.component.styles.less';

NoData(Highcharts);

const DonutChart = ({ chartData, chartSize, title, config: configChart }) => {
  const dataSeries = chartData.map((item) => ({
    name: item.label,
    y: item.data,
    color: item.hexColor,
    dataLabels: {
      alignTo: 'plotEdges',
      enabled: configChart.showPoint,
      format: '<br/>{point.percentage:.1f}%',
      borderWidth: 1,
      borderColor: item.hexColor,
      borderRadius: 4,
      padding: 4,
    },
  }));
  const options = {
    chart: {
      type: 'pie',
      height: chartSize * 1.2,
      width: chartSize * 1.2,
      style: {
        float: 'right',
      },
    },
    title: {
      text: title,
      align: 'center',
      verticalAlign: 'middle',
      y: configChart.titleFontSize,
      style: { fontSize: configChart.titleFontSize, fontWeight: 600 },
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: 'No Data',
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
      },
      pie: {
        size: chartSize,
        innerSize: '100%',
        allowPointSelect: true,
        borderWidth: configChart.thickness,
        borderColor: null,
        cursor: 'pointer',
        dataLabels: {
          alignTo: 'plotEdges',
          enabled: configChart.showPoint,
          format: '<br/>{point.percentage:.1f}%',
          distance: 150,
          connectorWidth: 0,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            minHeight: chartSize * 1.2,
          },
        },
      ],
    },
    series: [
      {
        colorByPoint: true,
        data: dataSeries,
      },
    ],
  };

  return (
    <div id="donut-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

DonutChart.displayName = config.displayName;
DonutChart.defaultProps = config.defaultProps;
DonutChart.propTypes = config.propTypes;

export default DonutChart;
