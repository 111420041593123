import get from 'lodash/get';

import {
  SET_FB_INSTAGRAM_REACH,
  SET_FB_INSTAGRAM_FOLLOWER,
  SET_FB_INSTAGRAM_FOLLOWER_GROWTH,
  SET_FB_INSTAGRAM_REACH_CHART,
  SET_FB_INSTAGRAM_BREAKDOWN_GENDER,
  SET_FB_INSTAGRAM_BREAKDOWN_LOCATION,
} from '../../types';

const initialState = {
  totalReach: {
    value: 0,
    diff: 0,
    loading: false,
  },
  totalImpression: {
    value: 0,
    diff: 0,
    loading: false,
  },
  totalFollowers: {
    value: 0,
    diff: 0,
    loading: false,
  },
  chartFollower: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  chartReach: {
    data: { xLabels: [], dataList: [] },
    loading: false,
  },
  chartBreakdown: {
    gender: {
      data: { xLabels: ['Gender'], dataList: [] },
      loading: false,
    },
    location: {
      data: [],
      loading: false,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FB_INSTAGRAM_REACH:
      return {
        ...state,
        totalReach: {
          ...state.totalReach,
          ...get(action, 'payload.totalReach'),
          loading: action.loading,
        },
        totalImpression: {
          ...state.totalImpression,
          ...get(action, 'payload.totalImpression'),
          loading: action.loading,
        },
      };
    case SET_FB_INSTAGRAM_FOLLOWER:
      return {
        ...state,
        totalFollowers: {
          ...state.totalFollowers,
          ...get(action, 'payload.totalFollowers'),
          loading: action.loading,
        },
      };
    case SET_FB_INSTAGRAM_FOLLOWER_GROWTH:
      return {
        ...state,
        chartFollower: {
          ...state.chartFollower,
          data: {
            ...state.chartFollower.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_INSTAGRAM_REACH_CHART:
      return {
        ...state,
        chartReach: {
          ...state.chartReach,
          data: {
            ...state.chartReach.data,
            ...action.payload,
          },
          loading: action.loading,
        },
      };
    case SET_FB_INSTAGRAM_BREAKDOWN_GENDER:
      return {
        ...state,
        chartBreakdown: {
          ...state.chartBreakdown,
          gender: {
            data: {
              ...state.chartBreakdown.gender.data,
              ...action.payload,
            },
            loading: action.loading,
          },
        },
      };
    case SET_FB_INSTAGRAM_BREAKDOWN_LOCATION:
      return {
        ...state,
        chartBreakdown: {
          ...state.chartBreakdown,
          location: {
            data: action.payload,
            loading: action.loading,
          },
        },
      };
    default:
      return state;
  }
};
