import React from 'react';

import { HorizontalBarChart } from '../Charts';
import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardHorizontalBarChart.component.config';
import './CardHorizontalBarChart.component.styles.less';

const CardHorizontalBarChart = ({
  title,
  loading,
  data,
  isUnbind,
  withLabel,
  customMaxLabel,
}) => {
  if (loading) {
    return (
      <div className="card-horizontal-bar-chart-loading">
        <div className="list-row loading" key="placeholder-1" />
        <div className="list-row loading" key="placeholder-2" />
        <div className="list-row loading" key="placeholder-3" />
        <div className="list-row loading" key="placeholder-4" />
        <div className="list-row loading" key="placeholder-5" />
      </div>
    );
  }

  return (
    <div id="card-horizontal-bar-chart">
      {isUnbind ? (
        <UnbindedData title={title} center />
      ) : (
        <HorizontalBarChart
          chartData={data}
          withLabel={withLabel}
          customMaxLabel={customMaxLabel}
          hasLegend={false}
        />
      )}
    </div>
  );
};

CardHorizontalBarChart.displayName = config.displayName;
CardHorizontalBarChart.defaultProps = config.defaultProps;
CardHorizontalBarChart.propTypes = config.propTypes;

export default CardHorizontalBarChart;
