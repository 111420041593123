import PropTypes from 'prop-types';

const displayName = 'DonutChart';
const propTypes = {
  chartData: PropTypes.array.isRequired,
  title: PropTypes.string,
  showLabel: PropTypes.bool,
};
const defaultProps = {
  chartData: [],
};

export default { displayName, propTypes, defaultProps };
