import PropTypes from 'prop-types';

const displayName = 'CardDonutListChart';
const propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};
const defaultProps = {
  title: '',
  data: {
    short: [],
    all: [],
  },
  loading: false,
};

export default { displayName, propTypes, defaultProps };
