import { connect } from 'react-redux';
import Login from './Login.component';

import {
  notificationError,
  loginFacebook,
  loginGoogle,
  loginGoogleSuccess,
  loginEmail,
} from '../../redux/actions/auth.actions';

const mapDispatchToProps = {
  notificationError,
  loginFacebook,
  loginGoogle,
  loginGoogleSuccess,
  loginEmail,
};

const mapStateToProps = (state) => ({
  ...state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
