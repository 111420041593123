import React, { useState } from 'react';
import get from 'lodash/get';
import { Card, Button, Table, Modal, Typography } from 'antd';

import UnbindedData from '../UnbindedData/UnbindedData.component';
import Loading from '../Loading/Loading.component';

import config from './CardTable.component.config';
import './CardTable.component.styles.less';

const { Title } = Typography;
const CardTable = ({ title, data, columns, loading, isUnbind }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const extraActions = [
    <Button
      type="link"
      onClick={() => setModalVisible(true)}
      disabled={loading}
    >
      View Details
    </Button>,
  ];

  const getRowId = (item) => item.campaign.replace(' ', '-').toLowerCase();

  const all = get(data, 'all', []);
  const short = get(data, 'short', []);

  return (
    <Card
      id="card-table"
      title={title}
      bordered={false}
      actions={all.length > 6 && !isUnbind ? extraActions : null}
    >
      <div className="data-wrapper">
        {isUnbind ? (
          <UnbindedData title={title} center />
        ) : (
          <Table
            id="list-table"
            rowKey={getRowId}
            dataSource={short}
            columns={columns}
            pagination={false}
            rowClassName={(_, index) => (index % 2 === 1 ? 'odd' : 'even')}
          />
        )}
      </div>
      {loading && <Loading />}
      <Modal
        footer={null}
        closeIcon={<i className="icon-close" />}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <div id="modal-table">
          <Title level={4}>{title}</Title>
          <Table
            id="list-table"
            rowKey={getRowId}
            dataSource={all}
            columns={columns}
            pagination={false}
            rowClassName={(_, index) => (index % 2 === 1 ? 'odd' : 'even')}
            scroll={{ y: 300 }}
          />
          <div className="btn-row">
            <Button
              type="link"
              onClick={() => setModalVisible(false)}
              disabled={loading}
            >
              View Less
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

CardTable.displayName = config.displayName;
CardTable.defaultProps = config.defaultProps;
CardTable.propTypes = config.propTypes;

export default CardTable;
