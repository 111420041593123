import React from 'react';
import get from 'lodash/get';
import { Row, Col, Card, Carousel } from 'antd';

import { CardTable, CardData } from '../../components';

import { DashboardLayout } from '../../components/Layout';

import config from './CampaignGoogle.component.config';
import { COLORS } from '../../constants/variables';

class CampaignGoogle extends React.PureComponent {
  componentDidMount() {
    if (this.props.accounts.GOOG_ADWORDS.haveAccount) {
      this.props.getGoogleAds();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.accounts.GOOG_ADWORDS.haveAccount !==
      this.props.accounts.GOOG_ADWORDS.haveAccount
    ) {
      this.props.getGoogleAds();
    }
  }

  // ----------------------------- total ads reach

  renderAdsReachDesktop = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <Row gutter={24}>
        <Col xl={12} md={24} sm={24} xs={24}>
          <CardData
            title="Reach"
            data={get(adwordData, 'reach')}
            config={{
              loadingClass: 'in-card',
            }}
            loading={get(adwordData, 'reach.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={12} md={24} sm={24} xs={24}>
          <CardData
            title="Impression"
            data={get(adwordData, 'impression')}
            config={{
              loadingClass: 'in-card',
            }}
            loading={get(adwordData, 'impression.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={12} md={24} sm={24} xs={24}>
          <CardData
            title="Frequency"
            data={get(adwordData, 'frequency')}
            config={{
              loadingClass: 'in-card',
            }}
            loading={get(adwordData, 'frequency.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </Row>
    );
  };

  renderAdsReachMobile = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <Carousel centerMode infinite={false}>
        <CardData
          title="Reach"
          data={get(adwordData, 'reach')}
          config={{
            loadingClass: 'in-card',
          }}
          loading={get(adwordData, 'reach.loading')}
          isUnbind={isUnbind}
        />

        <CardData
          title="Impression"
          data={get(adwordData, 'impression')}
          config={{
            loadingClass: 'in-card',
          }}
          loading={get(adwordData, 'impression.loading')}
          isUnbind={isUnbind}
        />

        <CardData
          title="Frequency"
          data={get(adwordData, 'frequency')}
          config={{
            loadingClass: 'in-card',
          }}
          loading={get(adwordData, 'frequency.loading')}
          isUnbind={isUnbind}
        />
      </Carousel>
    );
  };

  renderTotalAdsReach = (isUnbind) => (
    <Col xl={12} md={24} sm={24} xs={24}>
      <Card title="Total Ads Reach" className="no-bottom">
        {window.IS_IN_MOBILE
          ? this.renderAdsReachMobile(isUnbind)
          : this.renderAdsReachDesktop(isUnbind)}
      </Card>
    </Col>
  );

  // ----------------------------- cpc ctr cpm linkClick

  renderCpcCtrDesktop = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Link Clicks"
            data={get(adwordData, 'linkClick')}
            loading={get(adwordData, 'linkClick.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Cost per Mille (CPM)"
            data={get(adwordData, 'cpm')}
            loading={get(adwordData, 'cpm.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Clickthrough rate (CTR)"
            data={get(adwordData, 'ctr')}
            loading={get(adwordData, 'ctr.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Cost per Click (CPC)"
            data={get(adwordData, 'cpc')}
            loading={get(adwordData, 'cpc.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </>
    );
  };

  renderCpcCtrMobile = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            title="Link Clicks"
            data={get(adwordData, 'linkClick')}
            loading={get(adwordData, 'linkClick.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Cost per Mille (CPM)"
            data={get(adwordData, 'cpm')}
            loading={get(adwordData, 'cpm.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Clickthrough rate (CTR)"
            data={get(adwordData, 'ctr')}
            loading={get(adwordData, 'ctr.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Cost per Click (CPC)"
            data={get(adwordData, 'cpc')}
            loading={get(adwordData, 'cpc.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderCpcCtr = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderCpcCtrMobile(isUnbind)
      : this.renderCpcCtrDesktop(isUnbind);

  // ----------------------------- conversionRate

  renderConversionRateDesktop = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <Col xl={8} md={12} sm={24} xs={24}>
        <CardData
          title="Conversion Rate"
          data={get(adwordData, 'conversionRate')}
          loading={get(adwordData, 'conversionRate.loading')}
          isUnbind={isUnbind}
        />
      </Col>
    );
  };

  renderConversionRateMobile = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            title="Conversion Rate"
            data={get(adwordData, 'conversionRate')}
            loading={get(adwordData, 'conversionRate.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderConversionRate = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderConversionRateMobile(isUnbind)
      : this.renderConversionRateDesktop(isUnbind);

  // ----------------------------- Interaction

  renderInteractionDesktop = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Interactions"
            data={get(adwordData, 'interactions')}
            loading={get(adwordData, 'interactions.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Cost per Views"
            data={get(adwordData, 'cpv')}
            loading={get(adwordData, 'cpv.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Impression Top"
            data={get(adwordData, 'impressionTop')}
            loading={get(adwordData, 'impressionTop.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} md={12} sm={24} xs={24}>
          <CardData
            title="Impression Absolute Top"
            data={get(adwordData, 'impressionAbsoluteTop')}
            loading={get(adwordData, 'impressionAbsoluteTop.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </>
    );
  };

  renderInteractionMobile = (isUnbind) => {
    const { adwordData } = this.props;

    return (
      <Col xl={12} md={24} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            title="Interactions"
            data={get(adwordData, 'interactions')}
            loading={get(adwordData, 'interactions.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Cost per Views"
            data={get(adwordData, 'cpv')}
            loading={get(adwordData, 'cpv.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Impression Top"
            data={get(adwordData, 'impressionTop')}
            loading={get(adwordData, 'impressionTop.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            title="Impression Absolute Top"
            data={get(adwordData, 'impressionAbsoluteTop')}
            loading={get(adwordData, 'impressionAbsoluteTop.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderInteraction = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderInteractionMobile(isUnbind)
      : this.renderInteractionDesktop(isUnbind);

  render() {
    const { getGoogleAds, adwordData, accounts, location } = this.props;
    const isUnbind = !accounts.GOOG_ADWORDS.haveAccount;

    return (
      <DashboardLayout
        title="Campaign Google Ads"
        onPeriodChange={getGoogleAds}
        location={location}
      >
        <Row gutter={24}>
          <Col xl={12} md={24} sm={24} xs={24}>
            <CardTable
              title="Performance by Campaign"
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'campaign',
                  key: 'campaign',
                },
                {
                  title: 'Spend',
                  dataIndex: 'spend',
                  key: 'spend',
                  align: 'right',
                },
                {
                  title: 'ROAS',
                  dataIndex: 'roas',
                  key: 'roas',
                  align: 'right',
                  width: 80,
                },
              ]}
              data={get(adwordData, 'tableCampaign.data')}
              loading={get(adwordData, 'tableCampaign.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          {this.renderTotalAdsReach(isUnbind)}

          {this.renderCpcCtr(isUnbind)}

          <Col xl={8} md={24} sm={24} xs={24}>
            <CardData
              title="Views"
              icon="youtube"
              color={COLORS.red}
              backgroundColor={COLORS.bgRed}
              data={get(adwordData, 'views')}
              loading={get(adwordData, 'views.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          <Col xl={8} md={24} sm={24} xs={24}>
            <CardData
              title="View Rate"
              icon="youtube"
              color={COLORS.red}
              backgroundColor={COLORS.bgRed}
              data={get(adwordData, 'viewRate')}
              loading={get(adwordData, 'viewRate.loading')}
              isUnbind={isUnbind}
            />
          </Col>

          {/* <Col xl={8} md={24} sm={24} xs={24}>
            <CardData
              title="Watch Time"
              icon="youtube"
              color={COLORS.red}
              backgroundColor={COLORS.bgRed}
              data={get(adwordData, 'watchTime')}
              loading={get(adwordData, 'watchTime.loading')}
              isUnbind
            />
          </Col> */}

          {this.renderConversionRate(isUnbind)}

          {/* <Col xl={8} md={12} sm={24} xs={24}>
            <CardData
              title="App Install"
              data={get(adwordData, 'postShare')}
              loading={get(adwordData, 'postShare.loading')}
              isUnbind
            />
          </Col>

          <Col xl={8} md={12} sm={24} xs={24}>
            <CardData
              title="Cost per App Install"
              data={get(adwordData, 'postComment')}
              loading={get(adwordData, 'postComment.loading')}
              isUnbind
            />
          </Col> */}

          {/* <Col xl={12} md={24} sm={24} xs={24}>
            <CardLineChart
              title="Outbound Clicks VS # of Clicks"
              data={get(adwordData, 'chartOutboundClick.data')}
              loading={get(adwordData, 'chartOutboundClick.loading')}
              isUnbind
              chartConfig={{
                hasLegend: true,
                config: {
                  hideYAxis: false,
                  hideXAxis: false,
                  YLabelRight: true,
                },
              }}
            />
          </Col> */}

          {/* <Col xl={12} md={24} sm={24} xs={24}>
            <Row gutter={24}> */}

          {/* TODO change xl to 12 */}
          {this.renderInteraction(isUnbind)}
          {/* </Row>
          </Col> */}
        </Row>
      </DashboardLayout>
    );
  }
}

CampaignGoogle.displayName = config.displayName;
CampaignGoogle.defaultProps = config.defaultProps;
CampaignGoogle.propTypes = config.propTypes;

export default CampaignGoogle;
