import {
  SET_BINDING_FB_ADS_INSIGHT,
  SET_BINDING_GOOG_ADWORDS,
  SET_BINDING_GOOG_ANALYTICS,
  SET_BINDING_FB_INSTAGRAM,
  SET_BINDING_MODAL_VISIBLE,
  SET_BINDING_SHOPEE,
  SET_BINDING_SHOPIFY,
  SET_BINDING_TOKOPEDIA,
  SET_ACCOUNTS_ALL,
  SET_ACCOUNTS,
} from '../types';

const initialState = {
  assetList: {},
  accountList: {
    FB_ADS_INSIGHT: {
      loading: false,
      data: [],
      haveAccount: false,
    },
    GOOG_ADWORDS: {
      loading: false,
      data: [],
      haveAccount: false,
    },
    GOOG_ANALYTICS: {
      loading: false,
      data: [],
      haveAccount: false,
    },
    FB_INSTAGRAM: {
      loading: false,
      data: [],
      haveAccount: false,
    },
    TOKOPEDIA: {
      loading: false,
      data: [],
      haveAccount: false,
    },
    SHOPEE: {
      loading: false,
      data: [],
      haveAccount: false,
    },
    SHOPIFY: {
      loading: false,
      data: [],
      haveAccount: false,
    },
  },
  bindingFacebook: {
    btnLoading: false,
    data: {},
  },
  bindingGoogleAds: {
    btnLoading: false,
    data: {},
  },
  bindingGoogleAnalytics: {
    btnLoading: false,
    data: {},
  },
  bindingInstagram: {
    btnLoading: false,
    data: {},
  },
  bindingTokopedia: {
    btnLoading: false,
    data: {},
  },
  bindingShopee: {
    btnLoading: false,
    data: {},
  },
  bindingShopify: {
    btnLoading: false,
    data: {},
  },
  selectAccountModalVisible: false,
  currentAccountType: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return {
        ...state,
        assetList: {
          ...state.assetList,
          [action.accountType]: action.payloadAsset,
        },
        accountList: {
          ...state.accountList,
          [action.accountType]: {
            ...state.accountList[action.accountType],
            ...action.payload,
          },
        },
      };
    case SET_ACCOUNTS_ALL:
      return {
        ...state,
        accountList: {
          FB_ADS_INSIGHT: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          GOOG_ADWORDS: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          GOOG_ANALYTICS: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          FB_INSTAGRAM: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          TOKOPEDIA: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          SHOPEE: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          SHOPIFY: {
            loading: true,
            data: [],
            haveAccount: false,
          },
          ...action.payload,
        },
      };
    case SET_BINDING_MODAL_VISIBLE:
      return {
        ...state,
        selectAccountModalVisible: action.isVisible,
        currentAccountType: action.accountType,
      };
    case SET_BINDING_FB_ADS_INSIGHT:
      return {
        ...state,
        bindingFacebook: {
          ...state.bindingFacebook,
          ...action.payload,
        },
      };
    case SET_BINDING_GOOG_ADWORDS:
      return {
        ...state,
        bindingGoogleAds: {
          ...state.bindingGoogleAds,
          ...action.payload,
        },
      };
    case SET_BINDING_GOOG_ANALYTICS:
      return {
        ...state,
        bindingGoogleAnalytics: {
          ...state.bindingGoogleAnalytics,
          ...action.payload,
        },
      };
    case SET_BINDING_FB_INSTAGRAM:
      return {
        ...state,
        bindingInstagram: {
          ...state.bindingInstagram,
          ...action.payload,
        },
      };
    case SET_BINDING_TOKOPEDIA:
      return {
        ...state,
        bindingTokopedia: {
          ...state.bindingTokopedia,
          ...action.payload,
        },
      };
    case SET_BINDING_SHOPEE:
      return {
        ...state,
        bindingShopee: {
          ...state.bindingShopee,
          ...action.payload,
        },
      };
    case SET_BINDING_SHOPIFY:
      return {
        ...state,
        bindingShopify: {
          ...state.bindingShopify,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
