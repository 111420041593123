/* eslint-disable camelcase */
import get from 'lodash/get';

import { notification } from '../../components';
import api from '../../services/api.service';

import {
  SET_LOGIN_FACEBOOK,
  SET_LOGIN_GOOGLE,
  SET_LOGIN_EMAIL,
  SET_REGISTER,
  SET_RESET_PASSWORD,
} from '../types';
const { apiService, setAuthToken } = api.getInstance();

export const notificationError = (type) => (dispatch) => {
  notification.error({
    message: 'Error',
    description: `Failed to log in using ${type} Account`,
  });
  dispatch({ type: SET_LOGIN_GOOGLE, loading: false });
  dispatch({ type: SET_LOGIN_FACEBOOK, loading: false });
};

export const loginFacebook = () => async (dispatch) => {
  let result;
  let isPasswordCreated;
  dispatch({ type: SET_LOGIN_FACEBOOK, payload: { loadingFacebook: true } });
  try {
    const loginStatus = await new Promise(window.FB.getLoginStatus);
    let credential = get(loginStatus, 'authResponse.accessToken');
    if (loginStatus.status !== 'connected') {
      const loginResult = await new Promise(window.FB.login);
      credential = get(loginResult, 'authResponse.accessToken');
    }

    const facebookDataPayload = {
      credential_type: 'FACEBOOK',
      credential_metadata: JSON.stringify({
        token: credential,
      }),
      app: 'BDD_PUBLIC',
    };

    const {
      data: {
        token,
        is_password_created,
        is_company_category_created,
        is_company_name_created,
      },
    } = await apiService.post('auth/third_party', facebookDataPayload);
    const isRegCompleted =
      is_password_created &&
      is_company_category_created &&
      is_company_name_created;
    isPasswordCreated = is_password_created;
    setAuthToken(token, !isRegCompleted);

    result = Promise.resolve(isRegCompleted);
  } catch (error) {
    result = Promise.reject(error);
    notificationError('Facebook')(dispatch);
  } finally {
    dispatch({
      type: SET_LOGIN_FACEBOOK,
      payload: { loadingFacebook: false, isPasswordCreated },
    });
  }

  return result;
};

export const loginGoogle = () => (dispatch) => {
  dispatch({ type: SET_LOGIN_GOOGLE, payload: { loadingGoogle: true } });
};

export const loginGoogleSuccess = (resultGoogle) => async (dispatch) => {
  let result;
  let isPasswordCreated;

  try {
    const googleDataPayload = {
      credential_type: 'GOOGLE',
      credential_metadata: JSON.stringify({
        token: get(resultGoogle, 'tokenId'),
      }),
      app: 'BDD_PUBLIC',
    };

    const {
      data: {
        token,
        is_password_created,
        is_company_category_created,
        is_company_name_created,
      },
    } = await apiService.post('auth/third_party', googleDataPayload);
    const isRegCompleted =
      is_password_created &&
      is_company_category_created &&
      is_company_name_created;
    isPasswordCreated = is_password_created;
    setAuthToken(token, !isRegCompleted);

    result = Promise.resolve(isRegCompleted);
  } catch (error) {
    result = Promise.reject(error);
    notificationError('Google')(dispatch);
  } finally {
    dispatch({
      type: SET_LOGIN_GOOGLE,
      payload: { loadingGoogle: false, isPasswordCreated },
    });
  }

  return result;
};

export const loginEmail = (formValue) => async (dispatch) => {
  setAuthToken(null);
  let result;
  dispatch({ type: SET_LOGIN_EMAIL, loading: true });
  try {
    const {
      data: { token },
    } = await apiService.post('auth', {
      ...formValue,
      email: formValue.email.toLowerCase(),
      app: 'BDD_PUBLIC',
    });
    setAuthToken(token);
    result = Promise.resolve();
  } catch (error) {
    const {
      response: {
        data: { error_code },
      },
    } = error;
    result = Promise.reject(error_code);
    notificationError('Email')(dispatch);
  } finally {
    dispatch({ type: SET_LOGIN_EMAIL, loading: false });
  }

  return result;
};

export const registerEmail = (formValue) => async (dispatch) => {
  let result;
  dispatch({ type: SET_REGISTER, loading: true });
  try {
    const { status } = await apiService.post('users', {
      ...formValue,
      email: formValue.email.toLowerCase(),
    });
    if (status === 201) {
      result = Promise.resolve();
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    const {
      response: {
        data: { error_code },
      },
    } = error;
    result = Promise.reject(error);
    let errorMessage;
    if (error_code === 'LAST_LOGIN_FACEBOOK') {
      errorMessage = {
        message: 'Email already in use',
        description: 'Please Log In using your Facebook Account',
      };
    } else if (error_code === 'LAST_LOGIN_GOOGLE') {
      errorMessage = {
        message: 'Email already in use',
        description: 'Please Log In using your Google Account',
      };
    } else {
      errorMessage = {
        message: 'Error',
        description: `Failed to register using Email Account`,
      };
    }
    notification.error(errorMessage);
  } finally {
    dispatch({ type: SET_REGISTER, loading: false });
  }

  return result;
};

const completeCompany = async (data) => {
  let result;
  try {
    const { status: updateStatus } = await apiService.put('users', data);
    if (updateStatus === 201) {
      result = Promise.resolve();
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    result = Promise.reject(error);
  }

  return result;
};

export const completeRegister = (
  { password, company_name, company_category },
  isPasswordCreated,
) => async (dispatch) => {
  let result;
  dispatch({ type: SET_REGISTER, loading: true });
  try {
    if (!isPasswordCreated) {
      const { status } = await apiService.post('users/password', {
        password,
      });
      if (status === 200) {
        result = completeCompany({
          company_name,
          company_category,
        });
      } else {
        throw new Error('failed');
      }
    } else {
      result = completeCompany({
        company_name,
        company_category,
      });
    }
  } catch (error) {
    result = Promise.reject(error);
    notification.error({
      message: 'Error',
      description: `Failed to complete register`,
    });
  } finally {
    dispatch({ type: SET_REGISTER, loading: false });
  }

  return result;
};

export const resetPassword = ({ email }) => async (dispatch) => {
  let result;
  dispatch({ type: SET_RESET_PASSWORD, loading: true });
  try {
    const { status } = await apiService.post('users/reset_password', {
      email: email.toLowerCase(),
    });
    if (status === 200) {
      result = Promise.resolve();
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    const {
      response: {
        data: { error_code },
      },
    } = error;
    result = Promise.reject(error_code);
    notification.error({
      message: 'Error',
      description: `Failed to reset your password`,
    });
  } finally {
    dispatch({ type: SET_RESET_PASSWORD, loading: false });
  }

  return result;
};
