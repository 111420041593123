import React from 'react';
import get from 'lodash/get';
import { Row, Col, Table, Typography, Carousel } from 'antd';

import {
  CardData,
  CardLineChart,
  CardTab,
  CardHorizontalBarChart,
  Loading,
} from '../../components';

import { DashboardLayout } from '../../components/Layout';
import { COLORS } from '../../constants/variables';

import config from './SocialMediaInstagram.component.config';
import './SocialMediaInstagram.component.styles.less';
import { DonutThicknessChart } from '../../components/Charts';

const { Text } = Typography;
class SocialMediaInstagram extends React.PureComponent {
  componentDidMount() {
    if (this.props.accounts.FB_INSTAGRAM.haveAccount) {
      this.props.getSocialMedia();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.accounts.FB_INSTAGRAM.haveAccount !==
      this.props.accounts.FB_INSTAGRAM.haveAccount
    ) {
      this.props.getSocialMedia();
    }
  }

  renderLegend = (legendData) => (
    <div id="legend-chart">
      {legendData.map((item) => (
        <div className="legend-item" key={item.name}>
          <div className="legend-item-left">
            <div className="pointer" style={{ backgroundColor: item.color }} />
            <Text className="legend-name">{item.name} thn</Text>
          </div>
        </div>
      ))}
    </div>
  );

  renderGenderTab = () => {
    const { socmedData, accounts } = this.props;
    const isUnbind = !accounts.FB_INSTAGRAM.haveAccount;
    const dataGender = get(socmedData, 'chartBreakdown.gender.data');
    const loading = get(socmedData, 'chartBreakdown.gender.loading', false);

    const genderMF = {
      ...dataGender,
      dataList: get(dataGender, 'dataList', []).slice(0, 2),
    };
    const genderUnknow = get(dataGender, 'dataList', []).slice(2, 3);
    const dataGenderUnknow = get(genderUnknow[0], 'data', []);

    return (
      <Row id="gender-age">
        <Col className="normal" md={16} xs={24}>
          <CardHorizontalBarChart
            title="Gender & Age"
            data={genderMF}
            isUnbind={isUnbind}
            withLabel
            customMaxLabel={105}
          />
        </Col>

        <Col className="unknow" md={8} xs={24}>
          <div className="title">
            <Text>Other Gender</Text>
          </div>
          <DonutThicknessChart chartData={dataGenderUnknow} chartSize={220} />
          {dataGenderUnknow.length > 0 && (
            <Row>
              <Col span={12}>
                {this.renderLegend(dataGenderUnknow.slice(0, 4))}
              </Col>
              <Col span={12}>
                {this.renderLegend(dataGenderUnknow.slice(4, 7))}
              </Col>
            </Row>
          )}
        </Col>

        {loading && <Loading />}
      </Row>
    );
  };

  renderLocationTable = () => {
    const { socmedData } = this.props;

    const dataLocation = get(socmedData, 'chartBreakdown.location.data');
    const loading = get(socmedData, 'chartBreakdown.location.loading', false);

    const getRowId = (item) => item.label.replace(' ', '-').toLowerCase();

    return (
      <div id="location-table">
        <Table
          id="list-big-table"
          rowKey={getRowId}
          dataSource={dataLocation}
          columns={[
            {
              title: 'City Locations',
              dataIndex: 'label',
              key: 'label',
            },
            {
              title: 'Followers',
              dataIndex: 'value',
              key: 'value',
              align: 'right',
            },
          ]}
          pagination
          size="small"
          rowClassName={(_, index) => (index % 2 === 1 ? 'odd' : 'even')}
        />
        {loading && <Loading />}
      </div>
    );
  };

  renderListDataDesktop = (isUnbind) => {
    const { socmedData } = this.props;
    return (
      <>
        <Col xl={8} md={12} sm={24} xs={24}>
          <CardData
            icon="reach"
            color={COLORS.green}
            backgroundColor={COLORS.bgGreen}
            title="Total Reach"
            data={get(socmedData, 'totalReach')}
            loading={get(socmedData, 'totalReach.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={8} md={12} sm={24} xs={24}>
          <CardData
            icon="impression"
            color={COLORS.yellow}
            backgroundColor={COLORS.bgYellow}
            title="Total Impression"
            data={get(socmedData, 'totalImpression')}
            loading={get(socmedData, 'totalImpression.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={8} md={12} sm={24} xs={24}>
          <CardData
            icon="followers"
            color={COLORS.blue}
            backgroundColor={COLORS.bgBlue}
            title="Total Followers"
            data={get(socmedData, 'totalFollowers')}
            loading={get(socmedData, 'totalFollowers.loading')}
            isUnbind={isUnbind}
            extraText="Cut off time 14:00 PM • Max data display H-30"
          />
        </Col>
      </>
    );
  };

  renderListDataMobile = (isUnbind) => {
    const { socmedData } = this.props;
    return (
      <Col xl={8} md={12} sm={24} xs={24}>
        <Carousel centerMode infinite={false}>
          <CardData
            icon="reach"
            color={COLORS.green}
            backgroundColor={COLORS.bgGreen}
            title="Total Reach"
            data={get(socmedData, 'totalReach')}
            loading={get(socmedData, 'totalReach.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            icon="impression"
            color={COLORS.yellow}
            backgroundColor={COLORS.bgYellow}
            title="Total Impression"
            data={get(socmedData, 'totalImpression')}
            loading={get(socmedData, 'totalImpression.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            icon="followers"
            color={COLORS.blue}
            backgroundColor={COLORS.bgBlue}
            title="Total Followers"
            data={get(socmedData, 'totalFollowers')}
            loading={get(socmedData, 'totalFollowers.loading')}
            isUnbind={isUnbind}
            extraText="Cut off time 14:00 PM • Max data display H-30"
          />
        </Carousel>
      </Col>
    );
  };

  renderListData = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderListDataMobile(isUnbind)
      : this.renderListDataDesktop(isUnbind);

  render() {
    const { getSocialMedia, socmedData, accounts, location } = this.props;
    const isUnbind = !accounts.FB_INSTAGRAM.haveAccount;

    return (
      <DashboardLayout
        title="Social Media"
        onPeriodChange={getSocialMedia}
        location={location}
      >
        <Row gutter={24}>
          {this.renderListData(isUnbind)}

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardLineChart
              title="Followers Growth"
              data={get(socmedData, 'chartFollower.data')}
              loading={get(socmedData, 'chartFollower.loading')}
              isUnbind={isUnbind}
              extraText="Cut off time 14:00 PM • Max data display H-30"
              chartConfig={{
                config: {
                  hideYAxis: window.IS_IN_MOBILE,
                  hideXAxis: false,
                  YLabelRight: true,
                  height: 240,
                },
              }}
            />
          </Col>

          <Col xl={12} md={12} sm={24} xs={24}>
            <CardLineChart
              title="Reach per Post"
              data={get(socmedData, 'chartReach.data')}
              loading={get(socmedData, 'chartReach.loading')}
              isUnbind={isUnbind}
              extraText="Cut off time 14:00 PM"
              chartConfig={{
                config: {
                  hideYAxis: window.IS_IN_MOBILE,
                  hideXAxis: false,
                  YLabelRight: true,
                  height: 240,
                },
              }}
            />
          </Col>

          <Col xl={24} md={24} sm={24} xs={24}>
            <CardTab
              title="Followers Demography Breakdown"
              tabConfig={[
                {
                  title: 'Gender & Age',
                  key: 'gender',
                  content: this.renderGenderTab(),
                },
                {
                  title: 'Location',
                  key: 'location',
                  content: this.renderLocationTable(),
                },
              ]}
            />
          </Col>
        </Row>
      </DashboardLayout>
    );
  }
}

SocialMediaInstagram.displayName = config.displayName;
SocialMediaInstagram.defaultProps = config.defaultProps;
SocialMediaInstagram.propTypes = config.propTypes;

export default SocialMediaInstagram;
