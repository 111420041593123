import get from 'lodash/get';

import { store } from '../../store';
import clientGql from '../../../services/graphql.service';

import {
  getSummaryTotal,
  getTotalOrder,
  getStatusAggregation,
  getVersusIndustry,
} from '../../../graphql/home.graphql';
import { calculateDiff, getValueFromArray } from '../../../utils/data';
import { formatCurrency, formatNumber } from '../../../utils/string';
import {
  SET_AGGREGATED_HOME,
  SET_HOME_TOP_SALES,
  SET_HOME_TOTAL_AOV,
  SET_HOME_TOTAL_CPA,
  SET_HOME_TOTAL_CPR,
  SET_HOME_TOTAL_SALES,
  SET_HOME_VS_AOV,
  SET_HOME_VS_CPA,
  SET_HOME_VS_CPR,
} from '../../types';
import { notification } from '../../../components';

const getVariables = () => {
  const interval = store.getState().data.period;
  const intervalCompare = `COMPARE_${interval}`;

  return { interval, intervalCompare };
};

const getTotalSales = (variables, context) => async (dispatch) => {
  const category = ['COMPLETED'];

  let responseResult;
  dispatch({
    type: SET_HOME_TOTAL_SALES,
    loading: true,
  });
  try {
    const {
      data: { marketplaceOrders: data },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        ...variables,
        channel: ['TOKOPEDIA', 'SHOPEE', 'SHOPIFY'],
        category,
        context,
      },
    });

    const totalSales = getValueFromArray(
      data,
      'totalAmount',
      'status',
      'COMPLETED',
    );

    dispatch({
      type: SET_HOME_TOTAL_SALES,
      payload: {
        totalSales: { value: formatCurrency(parseInt(totalSales, 10)) },
      },
      loading: true,
    });

    const {
      data: { marketplaceOrders: dataCompare },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        interval: variables.intervalCompare,
        channel: ['TOKOPEDIA', 'SHOPEE', 'SHOPIFY'],
        category,
        context,
      },
    });

    const compareTotalSales = getValueFromArray(
      dataCompare,
      'totalAmount',
      'status',
      'COMPLETED',
    );

    const diffTotalSales = calculateDiff(totalSales, compareTotalSales);

    dispatch({
      type: SET_HOME_TOTAL_SALES,
      payload: {
        totalSales: {
          value: formatCurrency(parseInt(totalSales, 10)),
          diff: diffTotalSales,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_TOTAL_SALES,
      loading: false,
      payload: {
        totalSales: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTotalAov = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_HOME_TOTAL_AOV,
    loading: true,
  });
  try {
    const {
      data: { summaryAnalytics: data },
    } = await clientGql.query({
      query: getSummaryTotal,
      variables: {
        ...variables,
        metric: 'AOV',
        context,
      },
    });

    const totalAov = get(data, 'selfValue', '0');

    dispatch({
      type: SET_HOME_TOTAL_AOV,
      payload: {
        totalAov: { value: formatNumber(parseInt(totalAov, 10)) },
      },
      loading: true,
    });

    const {
      data: { summaryAnalytics: dataCompare },
    } = await clientGql.query({
      query: getSummaryTotal,
      variables: {
        interval: variables.intervalCompare,
        metric: 'AOV',
        context,
      },
    });

    const compareTotalAov = get(dataCompare, 'selfValue', '0');

    const diffTotalSales = calculateDiff(totalAov, compareTotalAov);

    dispatch({
      type: SET_HOME_TOTAL_AOV,
      payload: {
        totalAov: {
          value: formatNumber(parseInt(totalAov, 10)),
          diff: diffTotalSales,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_TOTAL_AOV,
      loading: false,
      payload: {
        totalAov: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTotalCpr = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_HOME_TOTAL_CPR,
    loading: true,
  });
  try {
    const {
      data: { summaryAnalytics: data },
    } = await clientGql.query({
      query: getSummaryTotal,
      variables: {
        ...variables,
        metric: 'CPR',
        context,
      },
    });

    const totalCpr = get(data, 'selfValue', '0');

    dispatch({
      type: SET_HOME_TOTAL_CPR,
      payload: {
        totalCpr: { value: formatCurrency(parseInt(totalCpr, 10)) },
      },
      loading: true,
    });

    const {
      data: { summaryAnalytics: dataCompare },
    } = await clientGql.query({
      query: getSummaryTotal,
      variables: {
        interval: variables.intervalCompare,
        metric: 'CPR',
        context,
      },
    });

    const compareTotalCpr = get(dataCompare, 'selfValue', '0');

    const diffTotalSales = calculateDiff(totalCpr, compareTotalCpr);

    dispatch({
      type: SET_HOME_TOTAL_CPR,
      payload: {
        totalCpr: {
          value: formatCurrency(parseInt(totalCpr, 10)),
          diff: diffTotalSales,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_TOTAL_CPR,
      loading: false,
      payload: {
        totalCpr: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTotalCpa = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_HOME_TOTAL_CPA,
    loading: true,
  });
  try {
    const {
      data: { summaryAnalytics: data },
    } = await clientGql.query({
      query: getSummaryTotal,
      variables: {
        ...variables,
        metric: 'CPA',
        context,
      },
    });

    const totalCpa = get(data, 'selfValue', '0');

    dispatch({
      type: SET_HOME_TOTAL_CPA,
      payload: {
        totalCpa: { value: formatCurrency(parseInt(totalCpa, 10)) },
      },
      loading: true,
    });

    const {
      data: { summaryAnalytics: dataCompare },
    } = await clientGql.query({
      query: getSummaryTotal,
      variables: {
        interval: variables.intervalCompare,
        metric: 'CPA',
        context,
      },
    });

    const compareTotalCpa = get(dataCompare, 'selfValue', '0');

    const diffTotalSales = calculateDiff(totalCpa, compareTotalCpa);

    dispatch({
      type: SET_HOME_TOTAL_CPA,
      payload: {
        totalCpa: {
          value: formatCurrency(parseInt(totalCpa, 10)),
          diff: diffTotalSales,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_TOTAL_CPA,
      loading: false,
      payload: {
        totalCpa: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getTopSales = (variables, context) => async (dispatch) => {
  const category = ['COMPLETED'];

  const dataResult = [];
  let responseResult;
  dispatch({
    type: SET_HOME_TOP_SALES,
    loading: true,
  });
  try {
    const {
      data: { marketplaceOrders: dataTokopedia },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        ...variables,
        channel: ['TOKOPEDIA'],
        category,
        context,
      },
    });

    dataResult.push({
      label: 'Tokopedia',
      value: formatNumber(get(dataTokopedia, '[0].totalNumber', 0)),
    });

    const {
      data: { marketplaceOrders: dataShopee },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        ...variables,
        channel: ['SHOPEE'],
        category,
        context,
      },
    });

    dataResult.push({
      label: 'Shopee',
      value: formatNumber(get(dataShopee, '[0].totalNumber', 0)),
    });

    const {
      data: { marketplaceOrders: dataShopify },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        ...variables,
        channel: ['SHOPIFY'],
        category,
        context,
      },
    });

    dataResult.push({
      label: 'Shopify',
      value: formatNumber(get(dataShopify, '[0].totalNumber', 0)),
    });

    dataResult.sort(
      (a, b) =>
        b.value.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.value.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_HOME_TOP_SALES,
      payload: {
        short: dataResult,
        all: dataResult,
      },
      loading: false,
    });

    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_TOP_SALES,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getVSAov = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_HOME_VS_AOV,
    loading: true,
  });
  try {
    const {
      data: { versusIndustry: data },
    } = await clientGql.query({
      query: getVersusIndustry,
      variables: {
        ...variables,
        metric: 'AOV',
        context,
      },
    });

    const vsAov = get(data, 'percentageValue', '0');

    dispatch({
      type: SET_HOME_VS_AOV,
      payload: {
        vsAov: {
          value: formatNumber(parseFloat(vsAov).toFixed(2)),
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_VS_AOV,
      loading: false,
      payload: {
        vsAov: {
          value: '-',
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getVSCpr = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_HOME_VS_CPR,
    loading: true,
  });
  try {
    const {
      data: { versusIndustry: data },
    } = await clientGql.query({
      query: getVersusIndustry,
      variables: {
        ...variables,
        metric: 'CPR',
        context,
      },
    });

    const vsCpr = get(data, 'percentageValue', '0');

    dispatch({
      type: SET_HOME_VS_CPR,
      payload: {
        vsCpr: {
          value: formatNumber(parseFloat(vsCpr).toFixed(2)),
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_VS_CPR,
      loading: false,
      payload: {
        vsCpr: {
          value: '-',
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getVSCpa = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_HOME_VS_CPA,
    loading: true,
  });
  try {
    const {
      data: { versusIndustry: data },
    } = await clientGql.query({
      query: getVersusIndustry,
      variables: {
        ...variables,
        metric: 'CPA',
        context,
      },
    });

    const vsCpa = get(data, 'percentageValue', '0');

    dispatch({
      type: SET_HOME_VS_CPA,
      payload: {
        vsCpa: {
          value: formatNumber(parseFloat(vsCpa).toFixed(2)),
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_HOME_VS_CPA,
      loading: false,
      payload: {
        vsCpa: {
          value: '-',
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

export const getAggregatedStatus = () => async (dispatch) => {
  try {
    const {
      data: { aggregationStatusCompleted: data },
    } = await clientGql.query({
      query: getStatusAggregation,
    });

    const isAggregated =
      get(data, 'shopee', false) &&
      get(data, 'tokopedia', false) &&
      get(data, 'shopify', false);

    dispatch({
      type: SET_AGGREGATED_HOME,
      payload: !!isAggregated,
    });
  } catch (error) {
    dispatch({
      type: SET_AGGREGATED_HOME,
      payload: false,
    });
  }
};

let querySource;

export const getHome = () => async (dispatch) => {
  const variables = getVariables();
  if (querySource) {
    querySource.abort();
  }

  const source = new window.AbortController();
  querySource = source;

  const context = {
    fetchOptions: { signal: source.signal },
  };

  try {
    await Promise.all([
      getTotalSales(variables, context)(dispatch),
      getTotalAov(variables, context)(dispatch),
      getTotalCpr(variables, context)(dispatch),
      getTotalCpa(variables, context)(dispatch),
      getVSAov(variables, context)(dispatch),
      getVSCpr(variables, context)(dispatch),
      getVSCpa(variables, context)(dispatch),
      getTopSales(variables, context)(dispatch),
    ]);
  } catch (error) {
    notification.error({
      message: 'Terjadi Kesalahan',
      description: `Sebagian data gagal diproses, silakan coba lagi`,
    });
  }
};
