import get from 'lodash/get';

import { store } from '../../store';
import clientGql from '../../../services/graphql.service';

import {
  chartFollower,
  chartReach,
  getTotalFollower,
  getTotalReach,
  chartBreakdown,
} from '../../../graphql/socialmedia.graphql';
import { calculateDiff, mapChartData } from '../../../utils/data';
import { capitalizeFirstString, formatNumber } from '../../../utils/string';
import {
  SET_FB_INSTAGRAM_FOLLOWER,
  SET_FB_INSTAGRAM_FOLLOWER_GROWTH,
  SET_FB_INSTAGRAM_REACH,
  SET_FB_INSTAGRAM_REACH_CHART,
  SET_FB_INSTAGRAM_BREAKDOWN_LOCATION,
  SET_FB_INSTAGRAM_BREAKDOWN_GENDER,
} from '../../types';
import { COLORS } from '../../../constants/variables';
import { notification } from '../../../components';

const getVariables = () => {
  const accounts = store.getState().binding.accountList.FB_INSTAGRAM.data;
  const interval = store.getState().data.period;
  const intervalCompare = `COMPARE_${interval}`;

  const [accountId] = accounts.map((account) => account.account_id);
  return { interval, accountId, intervalCompare };
};

const getReachImpression = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_INSTAGRAM_REACH,
    loading: true,
  });
  try {
    const {
      data: {
        instagramInsights: [data],
      },
    } = await clientGql.query({
      query: getTotalReach,
      variables,
      context,
    });

    const reach = get(data, 'reach', 0);
    const impression = get(data, 'impressions', 0);

    dispatch({
      type: SET_FB_INSTAGRAM_REACH,
      payload: {
        totalReach: { value: formatNumber(parseInt(reach, 10)) },
        totalImpression: {
          value: formatNumber(parseInt(impression, 10)),
        },
      },
      loading: true,
    });

    const {
      data: {
        instagramInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: getTotalReach,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareReach = calculateDiff(reach, get(dataCompare, 'reach', 0));
    const compareImpression = calculateDiff(
      impression,
      get(dataCompare, 'impressions', 0),
    );

    dispatch({
      type: SET_FB_INSTAGRAM_REACH,
      payload: {
        totalReach: {
          value: formatNumber(parseInt(reach, 10)),
          diff: compareReach,
        },
        totalImpression: {
          value: formatNumber(parseInt(impression, 10)),
          diff: compareImpression,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_INSTAGRAM_REACH,
      loading: false,
      payload: {
        totalReach: {
          value: 0,
          diff: 0,
        },
        totalImpression: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getFollower = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_INSTAGRAM_FOLLOWER,
    loading: true,
  });
  try {
    const {
      data: {
        instagramInsights: [data],
      },
    } = await clientGql.query({
      query: getTotalFollower,
      variables,
      context,
    });

    const follower = get(data, 'followerCount', 0);

    dispatch({
      type: SET_FB_INSTAGRAM_FOLLOWER,
      payload: {
        totalFollowers: { value: formatNumber(parseInt(follower, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        instagramInsights: [dataCompare],
      },
    } = await clientGql.query({
      query: getTotalFollower,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareFollower = calculateDiff(
      follower,
      get(dataCompare, 'followerCount', 0),
    );

    dispatch({
      type: SET_FB_INSTAGRAM_FOLLOWER,
      payload: {
        totalFollowers: {
          value: formatNumber(parseInt(follower, 10)),
          diff: compareFollower,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_INSTAGRAM_FOLLOWER,
      loading: false,
      payload: {
        totalFollowers: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartFollower = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_INSTAGRAM_FOLLOWER_GROWTH,
    loading: true,
  });
  try {
    const {
      data: { instagramInsights },
    } = await clientGql.query({
      query: chartFollower,
      variables,
      context,
    });

    const follower = mapChartData(variables.interval)(
      'followerCount',
      instagramInsights,
    );

    dispatch({
      type: SET_FB_INSTAGRAM_FOLLOWER_GROWTH,
      payload: {
        xLabels: get(follower, 'xLabels', []),
        dataList: [
          {
            label: 'Followers Growth',
            hexColor: COLORS.primary,
            data: get(follower, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_INSTAGRAM_FOLLOWER_GROWTH,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartReach = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_INSTAGRAM_REACH_CHART,
    loading: true,
  });
  try {
    const {
      data: { instagramInsights },
    } = await clientGql.query({
      query: chartReach,
      variables,
      context,
    });

    const reach = mapChartData(variables.interval)('reach', instagramInsights);

    dispatch({
      type: SET_FB_INSTAGRAM_REACH_CHART,
      payload: {
        xLabels: get(reach, 'xLabels', []),
        dataList: [
          {
            label: 'Reach per Post',
            hexColor: COLORS.primary,
            data: get(reach, 'data', []),
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_INSTAGRAM_REACH_CHART,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartGender = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_INSTAGRAM_BREAKDOWN_GENDER,
    loading: true,
  });
  try {
    const {
      data: { instagramDemography },
    } = await clientGql.query({
      query: chartBreakdown,
      variables: {
        accountId: variables.accountId,
        category: 'AGE_GENDER',
      },
      context,
    });

    const dataListMale = [];
    const dataListFemale = [];
    const dataListUnknow = [];
    instagramDemography.forEach((data) => {
      const gender = data.name.substr(0, 1);

      if (gender === 'F') {
        dataListFemale.push({
          label: data.name.substr(2),
          value: -data.value,
        });
      } else if (gender === 'M') {
        dataListMale.push({
          label: data.name.substr(2),
          value: data.value,
        });
      } else {
        dataListUnknow.push({
          label: data.name.substr(2),
          value: data.value,
        });
      }
    });

    const sourceDataMale = mapChartData(variables.interval)('', dataListMale);
    const sourceDataFemale = mapChartData(variables.interval)(
      '',
      dataListFemale,
    );

    const sourceListUnknow = dataListUnknow.map((item, index) => ({
      name: item.label,
      value: item.value,
      color: COLORS.list[index],
    }));

    dispatch({
      type: SET_FB_INSTAGRAM_BREAKDOWN_GENDER,
      payload: {
        xLabels: sourceDataMale.xLabels,
        dataList: [
          {
            label: 'Male',
            hexColor: COLORS.primary,
            data: get(sourceDataMale, 'data', []),
          },
          {
            label: 'Female',
            hexColor: COLORS.secondary,
            data: get(sourceDataFemale, 'data', []),
          },
          {
            label: 'Unknow',
            data: sourceListUnknow,
          },
        ],
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_INSTAGRAM_BREAKDOWN_GENDER,
      loading: false,
      payload: { xLabels: [], dataList: [] },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getChartLocation = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_FB_INSTAGRAM_BREAKDOWN_LOCATION,
    loading: true,
  });
  try {
    const {
      data: { instagramDemography },
    } = await clientGql.query({
      query: chartBreakdown,
      variables: {
        accountId: variables.accountId,
        category: 'LOCATION',
      },
      context,
    });

    const sourceList = instagramDemography.map((data) => ({
      label: capitalizeFirstString(data.name),
      value: formatNumber(parseInt(data.value, 10)),
    }));

    sourceList.sort(
      (a, b) =>
        b.value.replace(/[^0-9,-]+/g, '').replace(',', '.') -
        a.value.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    dispatch({
      type: SET_FB_INSTAGRAM_BREAKDOWN_LOCATION,
      payload: sourceList,
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_FB_INSTAGRAM_BREAKDOWN_LOCATION,
      loading: false,
      payload: [],
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

let querySource;

export const getSocialMedia = () => async (dispatch) => {
  const variables = getVariables();
  if (querySource) {
    querySource.abort();
  }

  const source = new window.AbortController();
  querySource = source;

  const context = {
    fetchOptions: { signal: source.signal },
  };

  try {
    await Promise.all([
      getReachImpression(variables, context)(dispatch),
      getFollower(variables, context)(dispatch),
      getChartFollower(variables, context)(dispatch),
      getChartReach(variables, context)(dispatch),
      getChartGender(variables, context)(dispatch),
      getChartLocation(variables, context)(dispatch),
    ]);
  } catch (error) {
    notification.error({
      message: 'Terjadi Kesalahan',
      description: `Sebagian data gagal diproses, silakan coba lagi`,
    });
  }
};
