/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Tabs } from 'antd';

import config from './CardTab.component.config';
import './CardTab.component.styles.less';

const { TabPane } = Tabs;
const CardTab = ({ title, tabConfig }) => (
  <Card id="card-tab" title={title} bordered={false}>
    <Tabs>
      {tabConfig.map((tabItem) => (
        <TabPane tab={tabItem.title} key={tabItem.key}>
          {tabItem.content}
        </TabPane>
      ))}
    </Tabs>
  </Card>
);

CardTab.displayName = config.displayName;
CardTab.defaultProps = config.defaultProps;
CardTab.propTypes = config.propTypes;

export default CardTab;
