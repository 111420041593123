import { COLORS } from '../variables';

export const CHART_LINE_EXAMPLE = {
  xLabels: [
    '18 Aug',
    '19 Aug',
    '20 Aug',
    '21 Aug',
    '22 Aug',
    '23 Aug',
    '24 Aug',
  ],
  dataList: [
    {
      label: 'Impression',
      hexColor: COLORS.primary,
      data: [461745, 83600, 70945, 9414, 22074, 43848, 32995],
    },
  ],
  total: 'Rp10.000.000',
};

export const CHART_BAR_EXAMPLE = {
  xLabels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  dataList: [
    {
      label: 'Click',
      hexColor: COLORS.primary,
      data: [12, 19, 3, 20, 2, 3],
    },
    {
      label: 'Conversions',
      hexColor: COLORS.secondary,
      data: [1, 10, 9, 2, 10, 1],
    },
  ],
};

export const CHART_DONUT_EXAMPLE = {
  short: [
    {
      label: 'your company',
      data: 25,
      hexColor: COLORS.primary,
    },
    {
      label: 'industry',
      data: 75,
      hexColor: COLORS.passive,
    },
  ],
  all: [
    {
      label: 'your company',
      data: 25,
      hexColor: COLORS.primary,
    },
    {
      label: 'industry',
      data: 75,
      hexColor: COLORS.secondary,
    },
  ],
};

export const CHART_DONUT_THICKNESS_EXAMPLE = {
  all: [
    {
      name: 'Instagram',
      value: 4245,
      color: '#2E81FF',
    },
    {
      name: 'Website',
      value: 1245,
      color: COLORS.secondary,
    },
    {
      name: 'Facebook',
      value: 345,
      color: COLORS.primary,
    },
  ],
  short: [
    {
      name: 'Instagram',
      value: 4245,
      color: '#2E81FF',
    },
    {
      name: 'Website',
      value: 1245,
      color: COLORS.secondary,
    },
    {
      name: 'Facebook',
      value: 345,
      color: COLORS.primary,
    },
  ],
};

export const LIST_EXAMPLE = {
  all: [
    {
      label: 'Instagram',
      value: 4245,
    },
    {
      label: 'Website',
      value: 1245,
    },
    {
      label: 'Facebook',
      value: 345,
    },
  ],
  short: [
    {
      label: 'Instagram',
      value: 4245,
    },
    {
      label: 'Website',
      value: 1245,
    },
    {
      label: 'Facebook',
      value: 345,
    },
  ],
};
