import React, { useEffect, useState } from 'react';

import { Typography, Button, Modal, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';

import { SET_BINDING_MODAL_VISIBLE } from '../../redux/types';

import config from './ModalBindingShopify.component.config';
import './ModalBindingShopify.component.styles.less';

const { Title } = Typography;
const ModalBindingShopify = ({ visible, onConfirm, loading }) => {
  const dispatch = useDispatch();
  const [elementHeight, setElementHeight] = useState(0);
  useEffect(() => {
    setElementHeight(
      window.innerHeight -
        document.getElementsByClassName('ant-modal-body')[0].clientHeight,
    );
  }, [visible]);

  return (
    <Modal
      visible={visible}
      footer={null}
      style={window.IS_IN_MOBILE ? { top: elementHeight } : {}}
      className="modal-shopify"
      closeIcon={<i className="icon-close" />}
      onCancel={() => {
        dispatch({
          type: SET_BINDING_MODAL_VISIBLE,
          isVisible: false,
        });
      }}
    >
      <div id="modal-binding-shopify">
        <Title level={4}>Add your Shopify account here</Title>
        <Form
          className="form"
          layout="vertical"
          name="profile"
          onFinish={onConfirm}
        >
          <Form.Item
            label="Website Name"
            name="shop_name"
            rules={[
              {
                required: true,
                message: 'Website Name is required',
              },
            ]}
          >
            <Input
              placeholder="Marketplace Name"
              autoComplete="off"
              className="input-text"
            />
          </Form.Item>

          <Form.Item
            label="Website Link Address"
            name="shop_link"
            rules={[
              {
                required: true,
                message: 'Website Link Address is required',
              },
            ]}
          >
            <Input
              placeholder="Website Link Address"
              autoComplete="off"
              className="input-text"
            />
          </Form.Item>

          <Form.Item
            label="Website API Key"
            name="shop_api_key"
            rules={[
              {
                required: true,
                message: 'Website API Key is required',
              },
            ]}
          >
            <Input
              placeholder="Marketplace API Key"
              autoComplete="off"
              className="input-text"
            />
          </Form.Item>

          <Form.Item
            label="Website API Password"
            name="shop_api_password"
            rules={[
              {
                required: true,
                message: 'Website API Password is required',
              },
            ]}
          >
            <Input
              placeholder="Marketplace API Password"
              autoComplete="off"
              className="input-text"
            />
          </Form.Item>

          <div className="row-btn">
            <Button loading={loading} type="primary" htmlType="submit">
              Binding Shopify
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

ModalBindingShopify.displayName = config.displayName;
ModalBindingShopify.defaultProps = config.defaultProps;
ModalBindingShopify.propTypes = config.propTypes;

export default ModalBindingShopify;
