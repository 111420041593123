import PropTypes from 'prop-types';

const displayName = 'HorizontalBarChart';
const propTypes = {
  chartData: PropTypes.object.isRequired,
};
const defaultProps = {
  config: {
    hideYAxis: false,
    hideXAxis: false,
    YLabelRight: true,
  },
};

export default { displayName, propTypes, defaultProps };
