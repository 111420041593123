/* eslint-disable no-unused-vars */
import get from 'lodash/get';

import { store } from '../../store';
import clientGql from '../../../services/graphql.service';

import {
  tableCampaign,
  reach,
  impression,
  linkCpm,
  ctrCpc,
  conversionRate,
  interactionCpv,
  impressionTop,
  views,
} from '../../../graphql/goog_adwords.graphql';
import { calculateDiff } from '../../../utils/data';
import {
  formatPercentage,
  formatCurrency,
  formatNumber,
} from '../../../utils/string';
import {
  SET_GOOG_ADWORDS_CAMPAIGN,
  SET_GOOG_ADWORDS_REACH,
  SET_GOOG_ADWORDS_IMPRESSIONS,
  SET_GOOG_ADWORDS_CPM,
  SET_GOOG_ADWORDS_CPC,
  SET_GOOG_ADWORDS_CONVERSION,
  SET_GOOG_ADWORDS_INTERACTION,
  SET_GOOG_ADWORDS_IMPRESSION_TOP,
  SET_GOOG_ADWORDS_VIEWS,
} from '../../types';
import { notification } from '../../../components';

const getVariables = () => {
  const accounts = store.getState().binding.accountList.GOOG_ADWORDS.data;
  const interval = store.getState().data.period;
  const intervalCompare = `COMPARE_${interval}`;

  const [accountId] = accounts.map((account) => account.account_id);
  return { interval, accountId, intervalCompare };
};

const getCampaign = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_CAMPAIGN,
    loading: true,
  });
  try {
    const {
      data: { googleAdsCampaignPerformance },
    } = await clientGql.query({
      query: tableCampaign,
      variables,
      context,
    });

    const dataList = googleAdsCampaignPerformance.map((data) => ({
      campaign: get(data, 'CampaignName', '-'),
      spend: formatCurrency(get(data, 'Cost', 0).toFixed(2)),
      roas: parseFloat(get(data, 'Roas', 0)).toFixed(2),
    }));

    dispatch({
      type: SET_GOOG_ADWORDS_CAMPAIGN,
      payload: {
        all: dataList,
        short: dataList.slice(0, 6),
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_CAMPAIGN,
      loading: false,
      payload: {
        short: [],
        all: [],
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getReach = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_REACH,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsCampaignPerformance: [data],
      },
    } = await clientGql.query({
      query: reach,
      variables,
      context,
    });

    const reachData = get(data, 'ImpressionReach', 0);

    dispatch({
      type: SET_GOOG_ADWORDS_REACH,
      payload: {
        reach: { value: formatNumber(parseInt(reachData, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsCampaignPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: reach,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareReach = calculateDiff(
      reachData,
      get(dataCompare, 'ImpressionReach', 0),
    );

    dispatch({
      type: SET_GOOG_ADWORDS_REACH,
      payload: {
        reach: {
          value: formatNumber(parseInt(reachData, 10)),
          diff: compareReach,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_REACH,
      loading: false,
      payload: {
        reach: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getImpression = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_IMPRESSIONS,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsAccountPerformance: [data],
      },
    } = await clientGql.query({
      query: impression,
      variables,
      context,
    });

    const impressionData = get(data, 'Impressions', 0);

    dispatch({
      type: SET_GOOG_ADWORDS_IMPRESSIONS,
      payload: {
        impression: { value: formatNumber(parseInt(impressionData, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsAccountPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: impression,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareImpression = calculateDiff(
      impressionData,
      get(dataCompare, 'Impressions', 0),
    );

    dispatch({
      type: SET_GOOG_ADWORDS_IMPRESSIONS,
      payload: {
        impression: {
          value: formatNumber(parseInt(impressionData, 10)),
          diff: compareImpression,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_IMPRESSIONS,
      loading: false,
      payload: {
        impression: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getCpm = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_CPM,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsAccountPerformance: [data],
      },
    } = await clientGql.query({
      query: linkCpm,
      variables,
      context,
    });

    const linkClick = get(data, 'Clicks', 0);
    const cpm = get(data, 'AverageCpm', 0);

    dispatch({
      type: SET_GOOG_ADWORDS_CPM,
      payload: {
        linkClick: { value: formatNumber(parseInt(linkClick, 10)) },
        cpm: { value: formatCurrency(parseInt(cpm, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsAccountPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: linkCpm,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareLinkClick = calculateDiff(
      linkClick,
      get(dataCompare, 'Clicks', 0),
    );
    const compareCpm = calculateDiff(cpm, get(dataCompare, 'AverageCpm', 0));

    dispatch({
      type: SET_GOOG_ADWORDS_CPM,
      payload: {
        linkClick: {
          value: formatNumber(parseInt(linkClick, 10)),
          diff: compareLinkClick,
        },
        cpm: { value: formatCurrency(parseInt(cpm, 10)), diff: compareCpm },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_CPM,
      loading: false,
      payload: {
        linkClick: {
          value: 0,
          diff: 0,
        },
        cpm: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getCpc = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_CPC,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsAccountPerformance: [data],
      },
    } = await clientGql.query({
      query: ctrCpc,
      variables,
      context,
    });

    const cpc = get(data, 'AverageCpc', 0);
    const ctr = parseFloat(get(data, 'Ctr', 0).slice(0, -1));

    dispatch({
      type: SET_GOOG_ADWORDS_CPC,
      payload: {
        cpc: { value: formatCurrency(parseInt(cpc, 10)) },
        ctr: {
          value: formatPercentage(parseFloat(ctr).toFixed(2)),
        },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsAccountPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: ctrCpc,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareCpc = calculateDiff(cpc, get(dataCompare, 'AverageCpc', 0));
    const compareCtr = calculateDiff(
      ctr,
      parseFloat(get(dataCompare, 'Ctr', 0).slice(0, -1)),
    );

    dispatch({
      type: SET_GOOG_ADWORDS_CPC,
      payload: {
        cpc: { value: formatCurrency(parseInt(cpc, 10)), diff: compareCpc },
        ctr: {
          value: formatPercentage(parseFloat(ctr).toFixed(2)),
          diff: compareCtr,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_CPC,
      loading: false,
      payload: {
        cpc: {
          value: 0,
          diff: 0,
        },
        ctr: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getViews = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_VIEWS,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsCampaignPerformance: [data],
      },
    } = await clientGql.query({
      query: views,
      variables,
      context,
    });

    const viewsData = get(data, 'VideoViews', 0);
    const viewRate = get(data, 'VideoViewRate', 0);

    dispatch({
      type: SET_GOOG_ADWORDS_VIEWS,
      payload: {
        views: { value: formatNumber(parseInt(viewsData, 10)) },
        viewRate: { value: formatNumber(parseInt(viewRate, 10)) },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsCampaignPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: views,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareViews = calculateDiff(
      viewsData,
      get(dataCompare, 'VideoViews', 0),
    );
    const compareViewRate = calculateDiff(
      viewRate,
      get(dataCompare, 'VideoViewRate', 0),
    );

    dispatch({
      type: SET_GOOG_ADWORDS_VIEWS,
      payload: {
        views: {
          value: formatNumber(parseInt(viewsData, 10)),
          diff: compareViews,
        },
        viewRate: {
          value: formatNumber(parseInt(viewRate, 10)),
          diff: compareViewRate,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_VIEWS,
      loading: false,
      payload: {
        cpc: {
          value: 0,
          diff: 0,
        },
        ctr: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getConversionRate = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_CONVERSION,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsAccountPerformance: [data],
      },
    } = await clientGql.query({
      query: conversionRate,
      variables,
      context,
    });

    const conversion = parseFloat(
      get(data, 'AllConversionRate', 0).slice(0, -1),
    );

    dispatch({
      type: SET_GOOG_ADWORDS_CONVERSION,
      payload: {
        conversionRate: { value: formatNumber(conversion.toFixed(2)) },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsAccountPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: conversionRate,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const conversionCompareValue = parseFloat(
      get(dataCompare, 'AllConversionRate', 0).slice(0, -1),
    );
    const compareConversion = calculateDiff(conversion, conversionCompareValue);

    dispatch({
      type: SET_GOOG_ADWORDS_CONVERSION,
      payload: {
        conversionRate: {
          value: formatNumber(conversion.toFixed(2)),
          diff: compareConversion,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_CONVERSION,
      loading: false,
      payload: {
        conversionRate: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getInteractionCpv = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_INTERACTION,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsAccountPerformance: [data],
      },
    } = await clientGql.query({
      query: interactionCpv,
      variables,
      context,
    });

    const interaction = get(data, 'Interactions', 0);
    const cpv = get(data, 'AverageCpv', 0);

    dispatch({
      type: SET_GOOG_ADWORDS_INTERACTION,
      payload: {
        interactions: { value: formatNumber(parseInt(interaction, 10)) },
        cpv: {
          value: formatCurrency(parseInt(cpv, 10)),
        },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsAccountPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: interactionCpv,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareInteraction = calculateDiff(
      interaction,
      get(dataCompare, 'Interactions', 0),
    );
    const compareCpv = calculateDiff(cpv, get(dataCompare, 'AverageCpv', 0));

    dispatch({
      type: SET_GOOG_ADWORDS_INTERACTION,
      payload: {
        interactions: {
          value: formatNumber(parseInt(interaction, 10)),
          diff: compareInteraction,
        },
        cpv: {
          value: formatCurrency(parseInt(cpv, 10)),
          diff: compareCpv,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_INTERACTION,
      loading: false,
      payload: {
        cpc: {
          value: 0,
          diff: 0,
        },
        ctr: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getImpressionTop = (variables, context) => async (dispatch) => {
  let responseResult;
  dispatch({
    type: SET_GOOG_ADWORDS_IMPRESSION_TOP,
    loading: true,
  });
  try {
    const {
      data: {
        googleAdsAccountPerformance: [data],
      },
    } = await clientGql.query({
      query: impressionTop,
      variables,
      context,
    });

    const impTop = get(data, 'TopImpressionPercentage', 0);
    const impressionAbsoluteTop = get(
      data,
      'AbsoluteTopImpressionPercentage',
      0,
    );

    dispatch({
      type: SET_GOOG_ADWORDS_IMPRESSION_TOP,
      payload: {
        impressionTop: { value: formatNumber(parseFloat(impTop).toFixed(2)) },
        impressionAbsoluteTop: {
          value: formatNumber(parseFloat(impressionAbsoluteTop).toFixed(2)),
        },
      },
      loading: true,
    });

    const {
      data: {
        googleAdsAccountPerformance: [dataCompare],
      },
    } = await clientGql.query({
      query: impressionTop,
      variables: {
        accountId: variables.accountId,
        interval: variables.intervalCompare,
      },
      context,
    });

    const compareTop = calculateDiff(
      impTop,
      get(dataCompare, 'TopImpressionPercentage', 0),
    );
    const compareAbs = calculateDiff(
      impressionAbsoluteTop,
      get(dataCompare, 'AbsoluteTopImpressionPercentage', 0),
    );

    dispatch({
      type: SET_GOOG_ADWORDS_IMPRESSION_TOP,
      payload: {
        impressionTop: {
          value: formatNumber(parseFloat(impTop).toFixed(2)),
          diff: compareTop,
        },
        impressionAbsoluteTop: {
          value: formatNumber(parseFloat(impressionAbsoluteTop).toFixed(2)),
          diff: compareAbs,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_GOOG_ADWORDS_IMPRESSION_TOP,
      loading: false,
      payload: {
        impressionTop: {
          value: 0,
          diff: 0,
        },
        impressionAbsoluteTop: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

let querySource;

export const getGoogleAds = () => async (dispatch) => {
  const variables = getVariables();
  if (querySource) {
    querySource.abort();
  }

  const source = new window.AbortController();
  querySource = source;

  const context = {
    fetchOptions: { signal: source.signal },
  };

  try {
    await Promise.all([
      getCampaign(variables, context)(dispatch),
      getReach(variables, context)(dispatch),
      getImpression(variables, context)(dispatch),
      getCpm(variables, context)(dispatch),
      getCpc(variables, context)(dispatch),
      getViews(variables, context)(dispatch),
      getConversionRate(variables, context)(dispatch),
      getInteractionCpv(variables, context)(dispatch),
      getImpressionTop(variables, context)(dispatch),
    ]);
  } catch (error) {
    notification.error({
      message: 'Terjadi Kesalahan',
      description: `Sebagian data gagal diproses, silakan coba lagi`,
    });
  }
};
