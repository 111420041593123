import PropTypes from 'prop-types';

const displayName = 'ModalBinding';
const propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
};
const defaultProps = {
  visible: false,
  data: {
    ads_account: [],
    asset_id: 'asset_id',
  },
  maxAccount: 1,
};

export default { displayName, propTypes, defaultProps };
