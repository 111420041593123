import { connect } from 'react-redux';
import SocialMediaInstagram from './SocialMediaInstagram.component';

import { getSocialMedia } from '../../redux/actions/dashboard/socialmedia.actions';

const mapStateToProps = (state) => ({
  accounts: state.binding.accountList,
  socmedData: state.socialmedia,
});

const mapDispatchToProps = { getSocialMedia };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SocialMediaInstagram);
