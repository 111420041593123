import React from 'react';
import { Button, Card, Typography, Avatar, Row, Col } from 'antd';

import { formatAvatar } from '../../utils/string';

import config from './CardBinding.component.config';
import './CardBinding.component.styles.less';
import Loading from '../Loading/Loading.component';

const { Text } = Typography;
const CardBinding = ({
  icon,
  type,
  onClickBind,
  onClickUnbind,
  loading,
  btnLoading,
  accounts,
  asset,
}) => {
  const renderAccount = (account) => (
    <div className="account-item" key={account.id}>
      <div className="account-item-left">
        <Avatar shape="circle" size="small">
          {formatAvatar(account.account_name)}
        </Avatar>
        <div className="account-name">{account.account_name}</div>
      </div>

      <i className="icon-linked" />
    </div>
  );

  const renderWithAccounts = () => (
    <div className="account-list">
      {accounts.slice(0, 3).map(renderAccount)}
      <Row gutter={8}>
        <Col span={12}>
          <Button
            type="primary"
            block
            danger
            onClick={() => onClickUnbind(asset.credential_type, asset.id)}
            loading={btnLoading}
          >
            Unbind
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            block
            onClick={onClickBind}
            loading={btnLoading}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </div>
  );

  const renderBindingBtn = () => (
    <Button block type="primary" onClick={onClickBind} loading={btnLoading}>
      Binding Account
    </Button>
  );

  const renderContent = () => {
    if (accounts.length > 0) {
      return renderWithAccounts();
    }
    return renderBindingBtn();
  };
  return (
    <Card id="card-binding">
      <div className="data-wrapper">
        <div className={`icon-account ${type.toLowerCase()}`}>
          <img src={icon} alt="icon-account" />
        </div>
        <Text>{type}</Text>
        {renderContent()}
      </div>
      {loading && <Loading />}
    </Card>
  );
};

CardBinding.displayName = config.displayName;
CardBinding.defaultProps = config.defaultProps;
CardBinding.propTypes = config.propTypes;

export default CardBinding;
