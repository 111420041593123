import PropTypes from 'prop-types';

const displayName = 'Landing';
const propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  fromEmpty: PropTypes.bool,
};
const defaultProps = {
  fromEmpty: false,
};

export default { displayName, propTypes, defaultProps };
