import { connect } from 'react-redux';
import Register from './Register.component';
import {
  notificationError,
  loginFacebook,
  loginGoogle,
  loginGoogleSuccess,
  registerEmail,
  completeRegister,
} from '../../redux/actions/auth.actions';

const mapDispatchToProps = {
  notificationError,
  loginFacebook,
  loginGoogle,
  loginGoogleSuccess,
  registerEmail,
  completeRegister,
};

const mapStateToProps = (state) => ({
  ...state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
