import PropTypes from 'prop-types';

const displayName = 'CardComparison';
const propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.string,
  isUnbind: PropTypes.bool,
  bindAccount: PropTypes.func,
  loading: PropTypes.bool,
};
const defaultProps = {
  title: '',
  data: null,
  total: '',
  isUnbind: false,
  loading: false,
};

export default { displayName, propTypes, defaultProps };
