/* eslint-disable no-unused-vars */
import get from 'lodash/get';

import { store } from '../../store';
import clientGql from '../../../services/graphql.service';

import {
  getTotalOrder,
  getChartOrder,
  getListProduct,
  getStatusAggregation,
} from '../../../graphql/marketplace.graphql';
import {
  calculateDiff,
  getValueFromArray,
  mapChartData,
} from '../../../utils/data';
import { formatCurrency, formatNumber } from '../../../utils/string';
import { COLORS } from '../../../constants/variables';
import {
  SET_AGGREGATED_SHOPEE,
  SET_AGGREGATED_SHOPIFY,
  SET_AGGREGATED_TOKOPEDIA,
} from '../../types';
import { notification } from '../../../components';

const getVariables = (type) => {
  const channel = [type];
  const interval = store.getState().data.period;
  const intervalCompare = `COMPARE_${interval}`;

  return { interval, intervalCompare, channel };
};

const getOrdersNumber = (variables, context) => async (dispatch) => {
  const [marketplaceType] = variables.channel;
  const type = `SET_${marketplaceType}_TOTAL_ORDER`;

  const category = ['COMPLETED', 'CANCELLED', 'TOTAL_ORDERS'];

  let responseResult;
  dispatch({
    type,
    loading: true,
  });
  try {
    const {
      data: { marketplaceOrders: data },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        ...variables,
        category,
        context,
      },
    });

    const completedOrder = getValueFromArray(
      data,
      'totalNumber',
      'status',
      'COMPLETED',
    );
    const canceledOrder = getValueFromArray(
      data,
      'totalNumber',
      'status',
      'CANCELLED',
    );
    const totalOrder = getValueFromArray(
      data,
      'totalNumber',
      'status',
      'TOTAL_ORDERS',
    );

    dispatch({
      type,
      payload: {
        completedOrder: { value: formatNumber(parseInt(completedOrder, 10)) },
        canceledOrder: { value: formatNumber(parseInt(canceledOrder, 10)) },
        totalOrder: { value: formatNumber(parseInt(totalOrder, 10)) },
      },
      loading: true,
    });

    const {
      data: { marketplaceOrders: dataCompare },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        interval: variables.intervalCompare,
        channel: variables.channel,
        category,
        context,
      },
    });

    const compareCompletedOrder = getValueFromArray(
      dataCompare,
      'totalNumber',
      'status',
      'COMPLETED',
    );
    const compareCanceledOrder = getValueFromArray(
      dataCompare,
      'totalNumber',
      'status',
      'CANCELLED',
    );
    const compareTotalOrder = getValueFromArray(
      dataCompare,
      'totalNumber',
      'status',
      'TOTAL_ORDERS',
    );

    const diffCompletedOrder = calculateDiff(
      completedOrder,
      compareCompletedOrder,
    );
    const diffCanceledOrder = calculateDiff(
      canceledOrder,
      compareCanceledOrder,
    );
    const diffTotalOrder = calculateDiff(totalOrder, compareTotalOrder);

    dispatch({
      type,
      payload: {
        completedOrder: {
          value: formatNumber(parseInt(completedOrder, 10)),
          diff: diffCompletedOrder,
        },
        canceledOrder: {
          value: formatNumber(parseInt(canceledOrder, 10)),
          diff: diffCanceledOrder,
        },
        totalOrder: {
          value: formatNumber(parseInt(totalOrder, 10)),
          diff: diffTotalOrder,
        },
      },
      loading: false,
    });
    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type,
      loading: false,
      payload: {
        completedOrder: {
          value: 0,
          diff: 0,
        },
        canceledOrder: {
          value: 0,
          diff: 0,
        },
        totalOrder: {
          value: 0,
          diff: 0,
        },
      },
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getAverageOrder = (variables, context) => async (dispatch) => {
  const [marketplaceType] = variables.channel;
  const type = `SET_${marketplaceType}_AVERAGE_ORDER`;
  const category = ['COMPLETED'];

  let responseResult;
  dispatch({
    type,
    loading: true,
  });
  try {
    const {
      data: { marketplaceOrders: dataTotal },
    } = await clientGql.query({
      query: getTotalOrder,
      variables: {
        ...variables,
        category,
        context,
      },
    });

    const total = getValueFromArray(
      dataTotal,
      'totalNumber',
      'status',
      'COMPLETED',
    );

    const amount = getValueFromArray(
      dataTotal,
      'totalAmount',
      'status',
      'COMPLETED',
    );

    const {
      data: { marketplaceOrders: dataChart },
    } = await clientGql.query({
      query: getChartOrder,
      variables: {
        ...variables,
        category,
        context,
      },
    });

    const listData = dataChart.map((item) => ({
      indexTime: item.indexTime,
      value: item.totalAmount / (item.totalNumber || 1),
    }));

    const chartAverage = mapChartData(variables.interval)('value', listData);

    dispatch({
      type,
      payload: {
        xLabels: get(chartAverage, 'xLabels', []),
        dataList: [
          {
            label: 'Average Order Value',
            hexColor: COLORS.primary,
            data: get(chartAverage, 'data', []),
          },
        ],
      },
      loading: false,
    });

    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type,
      payload: {
        xLabels: [],
        dataList: [],
        total: '',
      },
      loading: false,
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

const getListProducts = (variables, typeData, formater, context) => async (
  dispatch,
) => {
  const [marketplaceType] = variables.channel;
  const type = `SET_${marketplaceType}_PRODUCT_${typeData}`;

  let responseResult;
  dispatch({
    type,
    loading: true,
  });
  try {
    const {
      data: { marketplaceProductOrders },
    } = await clientGql.query({
      query: getListProduct,
      variables: {
        ...variables,
        sortBy: typeData,
        context,
      },
    });

    const dataList = marketplaceProductOrders.map((data) => ({
      label: get(data, 'productName', '-'),
      value: formater(get(data, typeData.toLowerCase(), 0)),
    }));

    dispatch({
      type,
      payload: {
        all: dataList.slice(0, 10),
        short: dataList.slice(0, 5),
      },
      loading: false,
    });

    responseResult = Promise.resolve();
  } catch (error) {
    dispatch({
      type,
      payload: {
        all: [],
        short: [],
      },
      loading: false,
    });
    responseResult = Promise.reject();
  }

  return responseResult;
};

export const getAggregatedStatus = () => async (dispatch) => {
  try {
    const {
      data: { aggregationStatusCompleted: data },
    } = await clientGql.query({
      query: getStatusAggregation,
    });

    dispatch({
      type: SET_AGGREGATED_TOKOPEDIA,
      payload: !!get(data, 'tokopedia'),
    });
    dispatch({
      type: SET_AGGREGATED_SHOPEE,
      payload: !!get(data, 'shopee'),
    });
    dispatch({
      type: SET_AGGREGATED_SHOPIFY,
      payload: !!get(data, 'shopify'),
    });
  } catch (error) {
    dispatch({
      type: SET_AGGREGATED_TOKOPEDIA,
      payload: false,
    });
    dispatch({
      type: SET_AGGREGATED_SHOPEE,
      payload: false,
    });
    dispatch({
      type: SET_AGGREGATED_SHOPIFY,
      payload: false,
    });
  }
};

let querySource;

export const getMarketplace = (type) => async (dispatch) => {
  const variables = getVariables(type);
  if (querySource) {
    querySource.abort();
  }

  const source = new window.AbortController();
  querySource = source;

  const context = {
    fetchOptions: { signal: source.signal },
  };

  try {
    await Promise.all([
      getOrdersNumber(variables, context)(dispatch),
      getAverageOrder(variables, context)(dispatch),
      getListProducts(variables, 'AMOUNT', formatCurrency, context)(dispatch),
      getListProducts(variables, 'QUANTITY', formatNumber, context)(dispatch),
    ]);
  } catch (error) {
    notification.error({
      message: 'Terjadi Kesalahan',
      description: `Sebagian data gagal diproses, silakan coba lagi`,
    });
  }
};
