import React from 'react';
import get from 'lodash/get';
import { Row, Col, Carousel } from 'antd';
import introJs from 'intro.js';

import { CardComparison, CardData, CardList } from '../../components';
import { DashboardLayout } from '../../components/Layout';

import config from './Summary.component.config';

class Summary extends React.PureComponent {
  componentDidMount() {
    const { accounts, getHome, getAggregatedStatus } = this.props;
    getAggregatedStatus();
    if (
      get(accounts, 'TOKOPEDIA.haveAccount') ||
      get(accounts, 'SHOPEE.haveAccount') ||
      get(accounts, 'SHOPIFY.haveAccount')
    ) {
      getHome();
    }

    if (this.props.isFirstTime) {
      setTimeout(() => {
        introJs()
          .setOptions({
            tooltipClass: 'tutorial',
            disableInteraction: true,
            steps: [
              {
                element: window.IS_IN_MOBILE
                  ? document.querySelector('.header-menu')
                  : document.querySelector('.ant-layout-sider'),
                title: 'Akses Menu Thorium',
                intro:
                  'Kamu bisa mengakses semua informasi yang kamu butuhkan melalui side menu yang ada di Thorium.',
                position: 'right',
              },
              {
                element: document.querySelector('.ant-card'),
                title: 'Binding Account',
                intro:
                  'Pertama-tama kamu harus melakukan proses “Binding Account” untuk menampilkan semua data yang ingin kamu lihat',
              },
              {
                element: document.querySelector('.ant-select'),
                title: 'Filter Data',
                intro:
                  'Dengan fitur filter ini kamu bisa mengatur range data yang ingin kamu lihat dan tampilkan',
                position: 'bottom',
              },
            ],
          })
          .start();

        this.props.setIntroDone();
      }, 100);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps.accounts, 'TOKOPEDIA.haveAccount') !==
        get(this.props.accounts, 'TOKOPEDIA.haveAccount') ||
      get(prevProps.accounts, 'SHOPEE.haveAccount') !==
        get(this.props.accounts, 'SHOPEE.haveAccount') ||
      get(prevProps.accounts, 'SHOPIFY.haveAccount') !==
        get(this.props.accounts, 'SHOPIFY.haveAccount')
    ) {
      this.props.getHome();
    }
  }

  renderSalesDesktop = (isUnbind) => {
    const { homeData } = this.props;
    return (
      <>
        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardData
            icon="money"
            color="#FA9600"
            backgroundColor="#FFEBCD"
            title="Total Sales"
            data={get(homeData, 'totalSales')}
            loading={get(homeData, 'totalSales.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardData
            icon="basket"
            color="#2188E8"
            backgroundColor="#E4F2FF"
            title="Average Order Value"
            data={get(homeData, 'totalAov')}
            loading={get(homeData, 'totalAov.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardData
            icon="wallet"
            color="#2CBE55"
            backgroundColor="#D8FFE3"
            title="Cost to Aquire Customer"
            data={get(homeData, 'totalCpa')}
            loading={get(homeData, 'totalCpa.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardData
            icon="customers"
            color="#787878"
            backgroundColor="#EDEDED"
            title="Cost per Revenue All"
            data={get(homeData, 'totalCpr')}
            loading={get(homeData, 'totalCpr.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardComparison
            title="AOV vs Industry"
            data={get(homeData, 'vsAov.value')}
            loading={get(homeData, 'vsAov.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardComparison
            title="CPA vs Industry"
            data={get(homeData, 'vsCpa.value')}
            loading={get(homeData, 'vsCpa.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardComparison
            title="CPR vs Industry"
            data={get(homeData, 'vsCpr.value')}
            loading={get(homeData, 'vsCpr.loading')}
            isUnbind={isUnbind}
          />
        </Col>

        <Col xl={6} lg={12} md={18} sm={24} xs={24}>
          <CardList
            title="Top Sales Channel"
            data={get(homeData, 'topSales.data')}
            loading={get(homeData, 'topSales.loading')}
            isUnbind={isUnbind}
          />
        </Col>
      </>
    );
  };

  renderSalesMobile = (isUnbind) => {
    const { homeData } = this.props;
    return (
      <Col xl={6} lg={12} md={18} sm={24} xs={24}>
        <Carousel className="carousel-summary" centerMode infinite={false}>
          <CardData
            icon="money"
            color="#FA9600"
            backgroundColor="#FFEBCD"
            title="Total Sales"
            data={get(homeData, 'totalSales')}
            loading={get(homeData, 'totalSales.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            icon="basket"
            color="#2188E8"
            backgroundColor="#E4F2FF"
            title="Average Order Value"
            data={get(homeData, 'totalAov')}
            loading={get(homeData, 'totalAov.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            icon="wallet"
            color="#2CBE55"
            backgroundColor="#D8FFE3"
            title="Cost to Aquire Customer"
            data={get(homeData, 'totalCpa')}
            loading={get(homeData, 'totalCpa.loading')}
            isUnbind={isUnbind}
          />

          <CardData
            icon="customers"
            color="#787878"
            backgroundColor="#EDEDED"
            title="Cost per Revenue All"
            data={get(homeData, 'totalCpr')}
            loading={get(homeData, 'totalCpr.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>

        <Carousel className="carousel-summary" centerMode infinite={false}>
          <CardComparison
            title="AOV vs Industry"
            data={get(homeData, 'vsAov.value')}
            loading={get(homeData, 'vsAov.loading')}
            isUnbind={isUnbind}
          />

          <CardComparison
            title="CPA vs Industry"
            data={get(homeData, 'vsCpa.value')}
            loading={get(homeData, 'vsCpa.loading')}
            isUnbind={isUnbind}
          />

          <CardComparison
            title="CPR vs Industry"
            data={get(homeData, 'vsCpr.value')}
            loading={get(homeData, 'vsCpr.loading')}
            isUnbind={isUnbind}
          />

          <CardList
            title="Top Sales Channel"
            data={get(homeData, 'topSales.data')}
            loading={get(homeData, 'topSales.loading')}
            isUnbind={isUnbind}
          />
        </Carousel>
      </Col>
    );
  };

  renderSales = (isUnbind) =>
    window.IS_IN_MOBILE
      ? this.renderSalesMobile(isUnbind)
      : this.renderSalesDesktop(isUnbind);

  render() {
    const {
      getHome,
      accounts,
      location,
      homeData: { isAggregated },
    } = this.props;
    const isUnbind =
      !get(accounts, 'TOKOPEDIA.haveAccount') &&
      !get(accounts, 'SHOPEE.haveAccount') &&
      !get(accounts, 'SHOPIFY.haveAccount');

    return (
      <DashboardLayout
        title="Summary Dashboard"
        onPeriodChange={getHome}
        location={location}
        isSynced={isAggregated}
        isSyncing={!isAggregated && !isUnbind}
      >
        <Row gutter={24}>{this.renderSales(isUnbind)}</Row>
      </DashboardLayout>
    );
  }
}

Summary.displayName = config.displayName;
Summary.defaultProps = config.defaultProps;
Summary.propTypes = config.propTypes;

export default Summary;
