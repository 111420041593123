import { gql } from '@apollo/client';

export const getTotalReach = gql`
  query getTotalReach($interval: Interval!, $accountId: String!) {
    instagramInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      impressions
      reach
    }
  }
`;

export const getTotalFollower = gql`
  query getTotalFollower($interval: Interval!, $accountId: String!) {
    instagramInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      followerCount
    }
  }
`;

export const chartFollower = gql`
  query chartFollower($interval: Interval!, $accountId: String!) {
    instagramInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      followerCount
      indexTime
    }
  }
`;

export const chartReach = gql`
  query chartReach($interval: Interval!, $accountId: String!) {
    instagramInsights(paramIn: { interval: $interval, accountId: $accountId }) {
      reach
      indexTime
    }
  }
`;

export const chartBreakdown = gql`
  query chartBreakdown(
    $accountId: String!
    $category: InstagramDemographyMetric!
  ) {
    instagramDemography(
      paramIn: { category: $category, accountId: $accountId }
    ) {
      name
      value
    }
  }
`;
