import React from 'react';
import { Card, Typography } from 'antd';

import { LineChart } from '../Charts';
import UnbindedData from '../UnbindedData/UnbindedData.component';

import config from './CardLineChart.component.config';
import './CardLineChart.component.styles.less';
import Loading from '../Loading/Loading.component';
import { ICON_CHART_NO_DATA } from '../../assets/icons';

const { Text } = Typography;
const CardLineChart = ({
  title,
  loading,
  data,
  isUnbind,
  chartConfig,
  extraText,
}) => {
  const showNoData = data.dataList.length < 1;
  const renderData = showNoData ? (
    <div className="empty">
      <img src={ICON_CHART_NO_DATA} alt="no-data" />
      <Text>
        Maaf data chart ini belum tersedia. Silakan coba beberapa saat lagi
      </Text>
    </div>
  ) : (
    <LineChart chartData={data} {...chartConfig} />
  );

  return (
    <Card id="card-line-chart" title={title} bordered={false}>
      <div className="data-wrapper">
        {extraText && (
          <div className="extra-row">
            <span className="extra">{extraText}</span>
          </div>
        )}
        {isUnbind ? <UnbindedData title={title} center /> : renderData}
      </div>
      {loading && <Loading />}
    </Card>
  );
};

CardLineChart.displayName = config.displayName;
CardLineChart.defaultProps = config.defaultProps;
CardLineChart.propTypes = config.propTypes;

export default CardLineChart;
