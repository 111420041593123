import get from 'lodash/get';
import jwtDecode from 'jwt-decode';

const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';

export const storeToken = (token) => {
  const ttl = 86400000; // time to live 1 day in ms
  const now = new Date();
  if (token) {
    const decodedToken = jwtDecode(token);

    const item = {
      token,
      expiry: now.getTime() + ttl,
      id: decodedToken.id,
    };
    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(item));
  } else {
    localStorage.clear();
  }
};

export const getToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const item = JSON.parse(token, '{}');
  const now = new Date();

  const decodedToken = jwtDecode(token);
  if (now.getTime() > get(item, 'expiry')) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    return null;
  }
  return {
    token: get(item, 'token'),
    id: get(item, 'id'),
    decodedToken,
  };
};

export const destroyToken = () => {
  localStorage.clear();
  window.location.reload();
};
